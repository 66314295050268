import React from 'react';

import IconCaixaCancelado from 'assets/svg/caixa-preta.svg';
import Icon from 'components/Tools/Icon';
import { CustomizedImgCellContainer } from './styles';

interface ICustomizedImgCellProps {
  status: string;
}

const CustomizedImgCell: React.FC<ICustomizedImgCellProps> = ({ status }) => {
  const handleColor = (stats: string) => {
    switch (stats) {
      case 'Em andamento':
        return '#F1BC00';
      case 'Pendente':
        return '#A1A5B7';
      case 'Finalizado':
      case 'Ok':
      case 'Integrado Ok':
        return '#46BE7D';
      case 'Planejado':
        return 'rgba(246, 155, 17, 1)';
      case 'Finalizado divergente':
      case 'Divergente':
      case 'Integrado divergente':
        return '#D9214E';
      default:
        return '#3F4254';
    }
  };

  return status !== '' ? (
    <CustomizedImgCellContainer>
      <Icon icon={IconCaixaCancelado} color={handleColor(status)} />
    </CustomizedImgCellContainer>
  ) : (
    <></>
  );
};

export default CustomizedImgCell;
