/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-sequences */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import axios, { AxiosError, AxiosResponse, AxiosInstance } from 'axios';
import { useHistory } from 'react-router-dom';
import config from '../config';

const login = sessionStorage.getItem('@pdamodules::login')?.replace(/"/g, '');
const refreshToken = sessionStorage.getItem('@pdamodules::refreshtoken');
const access_token = sessionStorage.getItem('@pdamodules::token');

const api: AxiosInstance = axios.create({
  baseURL: config.apiConfig.baseUrl,
});

api.interceptors.response.use(
  async response => Promise.resolve(response),

  async error => {
    if (error.response.status === 401 && access_token) {
      await reAuthentication();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

async function reAuthentication() {
  return new Promise((resolve, reject) => {
    try {
      axios
        .request({
          method: 'POST',
          baseURL: `${config.apiConfig.baseUrl}Autenticacao/Refresh-Token`,
          data: {
            login,
            refreshToken,
          },
        })
        .then(async res => {
          sessionStorage.setItem(
            '@pdamodules::token',
            JSON.stringify(res.data.accessToken)
          );

          return resolve(res);
        })
        // eslint-disable-next-line no-shadow
        .catch(err => {
          window.location.replace(
            `${
              process.env.REACT_APP_ENV === 'dev'
                ? process.env.REACT_APP_LOGOUT_DEV_URL
                : process.env.REACT_APP_LOGOUT_PROD_URL
            }`
          );
          sessionStorage.clear();

          return reject(err);
        });
    } catch (err) {
      return reject(err);
    }
  });
}

export default api;
