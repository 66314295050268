/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';

export interface TagStatusProps {
  /**
   * @property *status* = Texto a ser exibido dentro da tag
   * @example status="teste"
   */
  status: string;

  /**
   * @property *width* = Largura da tag
   * @example width={20}
   */
  width?: string;

  /**
   * @property *fontSize* = Tamanho da fonte utilizada dentro da tag
   * @example fontSize={15}
   */
  fontSize?: number;

  /**
   * @property *customPadding* = Habilita o uso de paddings customizados
   * @example customPadding={true}
   */
  customPadding?: boolean;

  /**
   * @property *paddingX* = Altera o padding no eixo X
   * @example paddingX={10}
   */
  paddingX?: number;

  /**
   * @property *paddingY* = Altera o padding no eixo Y
   * @example paddingY={10}
   */
  paddingY?: number;

  /**
   * @property *color* = Altera a cor do texto dentro da Tag
   * @example color="#000"
   * @example color="rgb(0,0,0)"
   * @example color="rgba(0,0,0,1)"
   */
  color?: string;

  /**
   * @property *background* = Altera a cor de fundo do texto dentro da Tag
   * @example background="#000"
   * @example background="rgb(0,0,0)"
   * @example background="rgba(0,0,0,1)"
   */
  background?: string;

  /**
   * @property *styleFont* = Altera o estilo padrão do texto,
   * modificando-o para primeira letra maiúscula por padrão,
   * texto completo em maiúscula ou minúscula, etc.
   * @example styleFont="capitalize" | "uppercase" | "lowercase" | "unset"
   */
  styleFont?:
    | 'capitalize'
    | 'uppercase'
    | 'lowercase'
    | 'unset'
    | 'none'
    | 'initial'
    | 'inherit';
  height?: string;
}

export const Tag = styled.p<TagStatusProps>`
  ${props =>
    props.width
      ? css`
          width: ${props.width};
        `
      : css`
          width: 100%;
        `}
  ${props =>
    props.fontSize
      ? css`
          font-size: ${props.fontSize}px;
        `
      : css`
          font-size: 10pt;
        `}

  color: ${props => {
    if (props.color) {
      return props.color;
    }

    switch (props.status) {
      case 'Finalizado':
      case 'Finished':
      case 'Ok':
      case 'Ativo':
      case 'Active':
      case 'Activo':
      case 'Embarque Finalizado':
      case 'Sim':
      case 'Aberto':
      case 'Contado':
        return props.theme.colors.tagStatusColors.finalizado_or_ativo.color;
      case 'Finalizado divergente':
      case 'Integrado divergente':
      case 'Completed Divergent':
      case 'Divergente Completado':
      case 'Divergente':
      case 'Divergent':
      case 'NF Divergente':
      case 'Invoice Divergent':
      case 'Factura Divergente':
      case 'Estoque Insuficiente':
      case 'Bloqueado':
      case 'Finalizado c/divergência':
      case 'Não':
      case 'Fechado':
      case 'NOK':
      case 'Sem Contagem':
        return props.theme.colors.tagStatusColors
          .finalizado_divergente_or_bloqueado.color;
      case 'Em andamento':
      case 'Em Andamento':
      case 'In Progress':
      case 'En proceso':
      case 'Inativo':
      case 'Inactive':
      case 'Inactivo':
      case 'Aguardando Integração':
      case 'Zerado':
        return props.theme.colors.tagStatusColors.em_andamento_or_inativo.color;
      case 'Cancelado':
      case 'Canceled':
      case 'Pedido Cancelado':
        return props.theme.colors.tagStatusColors.cancelado.color;
      case 'Pendente':
      case 'Pending':
        return props.theme.colors.tagStatusColors.pendente.color;
      case 'Planejado':
      case 'Aguardando Expedição':
      case 'Aguardando Liberação':
      case 'Triagem Finalizada':
        return props.theme.colors.tagStatusColors
          .aguardando_faturamento_or_integracao_or_expedicao.color;
      case 'Aguardando Conferência':
      case 'Expedição Finalizada':
      case 'Atualizar':
        return props.theme.colors.tagStatusColors
          .expedicao_finalizada_or_aguardando_conferencia.color;
      case 'Andamento':
      case 'Aguardando Triagem':
        return props.theme.colors.tagStatusColors.andamento.color;
      case 'Ressuprimento':
        return props.theme.colors.tagStatusColors.ressuprimento.color;
      case 'Criado':
        return props.theme.colors.tagStatusColors.criado.color;
      case 'Erro':
        return props.theme.colors.tagStatusColors
          .finalizado_divergente_or_bloqueado.color;
      case 'Executando':
        return props.theme.colors.tagStatusColors.finalizado_or_ativo.color;
      case 'Parado':
        return props.theme.colors.tagStatusColors.pendente.color;
      default:
        return props.theme.colors.tagStatusColors.finalizado_or_ativo.color;
    }
  }} !important;

  background: ${props => {
    if (props.background) {
      return props.background;
    }
    switch (props.status) {
      case 'Finalizado':
      case 'Finished':
      case 'Ok':
      case 'Ativo':
      case 'Active':
      case 'Activo':
      case 'Embarque Finalizado':
      case 'Sim':
      case 'Aberto':
      case 'Contado':
        return props.theme.colors.tagStatusColors.finalizado_or_ativo.bg;
      case 'Finalizado divergente':
      case 'Integrado divergente':
      case 'Completed Divergent':
      case 'Divergente Completado':
      case 'Divergente':
      case 'Divergent':
      case 'NF Divergente':
      case 'Invoice Divergent':
      case 'Factura Divergente':
      case 'Estoque Insuficiente':
      case 'Bloqueado':
      case 'Finalizado c/divergência':
      case 'Não':
      case 'Fechado':
      case 'NOK':
      case 'Sem Contagem':
        return props.theme.colors.tagStatusColors
          .finalizado_divergente_or_bloqueado.bg;
      case 'Em andamento':
      case 'Em Andamento':
      case 'In Progress':
      case 'En proceso':
      case 'Inactivo':
      case 'Aguardando Integração':
      case 'Zerado':
        return props.theme.colors.tagStatusColors.em_andamento_or_inativo.bg;
      case 'Cancelado':
      case 'Pedido Cancelado':
      case 'Canceled':
        return props.theme.colors.tagStatusColors.cancelado.bg;
      case 'Pendente':
      case 'Pending':
        return props.theme.colors.tagStatusColors.pendente.bg;
      case 'Inativo':
      case 'Inactive':
      case 'Planejado':
      case 'Aguardando Expedição':
      case 'Aguardando Liberação':
      case 'Triagem Finalizada':
        return props.theme.colors.tagStatusColors
          .aguardando_faturamento_or_integracao_or_expedicao.bg;
      case 'Aguardando Conferência':
      case 'Expedição Finalizada':
      case 'Atualizar':
        return props.theme.colors.tagStatusColors
          .expedicao_finalizada_or_aguardando_conferencia.bg;
      case 'Andamento':
      case 'Aguardando Triagem':
        return props.theme.colors.tagStatusColors.andamento.bg;
      case 'Ressuprimento':
        return props.theme.colors.tagStatusColors.ressuprimento.bg;
      case 'Criado':
        return props.theme.colors.tagStatusColors.criado.bg;
      case 'Erro':
        return props.theme.colors.tagStatusColors
          .finalizado_divergente_or_bloqueado.bg;
      case 'Executando':
        return props.theme.colors.tagStatusColors.finalizado_or_ativo.bg;
      case 'Parado':
        return props.theme.colors.tagStatusColors.pendente.bg;
      default:
        return props.theme.colors.tagStatusColors.finalizado_or_ativo.bg;
    }
  }} !important;

  border-radius: 14px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => (props.height ? props.height : '26px')};
  text-align: center;

  ${props =>
    props.customPadding && props.paddingX
      ? css`
          padding-left: ${props.paddingX}px;
          padding-right: ${props.paddingX}px;
        `
      : css`
          padding-left: 10px;
          padding-right: 10px;
        `}
  ${props =>
    props.customPadding && props.paddingY
      ? css`
          padding-top: ${props.paddingY}pt;
          padding-bottom: ${props.paddingY}pt;
        `
      : css`
          padding-top: 1px;
          padding-bottom: 1px;
        `}

        ${props =>
    props.customPadding && props.paddingY && props.paddingX
      ? css`
          padding-top: ${props.paddingY}pt;
          padding-bottom: ${props.paddingY}pt;
          padding-left: ${props.paddingX}pt;
          padding-right: ${props.paddingX}pt;
        `
      : null}

  padding: ${props => props.paddingY}px ${props => props.paddingX}px;

  ${props =>
    props.styleFont
      ? css`
          text-transform: ${props.styleFont};
        `
      : css`
          text-transform: capitalize;
        `}
`;
