/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Container } from './styles';

const Copy: React.FC = () => {
  const [date, setDate] = React.useState(String);
  const data = new Date();

  React.useEffect(() => {
    setDate(`${data.getFullYear()}`);
  }, [data]);

  return (
    <Container>
      <div className="content">
        <p>© PDA Soluções 2012 - {date} </p>
      </div>
    </Container>
  );
};

export default Copy;
