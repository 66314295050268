import styled from 'styled-components';

interface ContainerProps {
  selected: boolean;
}

export const Container = styled.button<ContainerProps>`
  width: 18px;
  height: 18px;
  border: 1.5px solid;
  border-color: ${props =>
    props.theme.title === 'dark'
      ? props.selected
        ? '#0095E8'
        : '#4C4C66'
      : props.selected
      ? '#0095E8'
      : '#B5B5C3'};
  background: transparent;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;
