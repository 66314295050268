/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useLayoutEffect, useState } from 'react';
import i18n from 'i18next';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import PageMode from 'components/Tools/PageMode';
import { DetailInventoryData } from 'utils/interfaces/Log';
import InventoryDetailsTable from './InventoryDetailsTable';
import { detailsRequest } from '../../api/inventory.api';
import { IInventoryDetailsModalProps } from '../../types';

const InventoryDetailsModal: React.FC<IInventoryDetailsModalProps> = ({
  isOpen,
  closeModal,
  data,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const translationMessage = (
    firstMessage: string,
    lastMessage?: string
  ): string => {
    const translation = i18n.t;
    return lastMessage
      ? `${firstMessage} ${translation(lastMessage)}`
      : translation(firstMessage);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [inventoryDetail, setInventoryDetail] = useState<DetailInventoryData[]>(
    []
  );

  const getDetails = useCallback(async () => {
    setIsLoading(true);

    await detailsRequest(data)
      .then(res => {
        if (!res.data.length) {
          enqueueSnackbar(
            translationMessage('screens.commom.resultsNotFound'),
            {
              variant: 'warning',
            }
          );

          closeModal();

          return;
        }

        enqueueSnackbar(
          translationMessage(
            String(res.data.length),
            'screens.commom.successFilter'
          ),
          {
            variant: 'success',
          }
        );
        setInventoryDetail(res.data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [data, enqueueSnackbar, closeModal]);

  useLayoutEffect(() => {
    getDetails();
  }, [getDetails]);

  return (
    <PageMode
      isModal
      disableSubmit
      open={isOpen}
      closeWhenClickOutside
      handleClose={closeModal}
      title={String(t('screens.commom.detail'))}
      modalLoading={isLoading}
    >
      <InventoryDetailsTable
        rows={inventoryDetail}
        setShowStockColumns={function (value: boolean): void {
          throw new Error('Function not implemented.');
        }}
      />
    </PageMode>
  );
};

export default InventoryDetailsModal;
