import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from './styles';

export interface ProgressBarProps {
  value: number;
  breakPoints?: number[];
  breakPointColors?: string[];
  style?: CSSProperties;
  showTitle?: boolean;
  customTitle?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  breakPointColors,
  breakPoints,
  style,
  showTitle,
  customTitle,
}) => {
  const { t } = useTranslation();
  return (
    <Container
      breakPoints={breakPoints}
      breakPointColors={breakPointColors}
      progress={value}
      style={style}
    >
      <div className="progress-value" style={{ justifyContent: 'flex-start' }}>
        <span>{`${Number(value).toFixed(0)}%`}</span>
        {showTitle && (
          <span>{customTitle ?? `${t('screens.commom.progress')}`}</span>
        )}
      </div>
      <div className="progress-bar" style={{ justifyContent: 'flex-start' }}>
        <div className="progress-bar-value" />
      </div>
    </Container>
  );
};

export default ProgressBar;
