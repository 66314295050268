import styled from 'styled-components';
import { IconButton } from '@material-ui/core';

export const NotificationContainer = styled(IconButton)`
  position: relative;
  background: transparent;
  cursor: pointer;
  display: grid;
  place-items: center;

  .round-display {
    position: absolute;
    top: 8px;
    right: 5px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #d9214e;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    font-size: 10px;
    font-weight: 500;
  }
`;

export const BoxOptions = styled.div`
  display: block;
  width: 160px;
  height: auto;
  background: rgb(255, 255, 255);
  position: absolute;
  left: 50%;
  transform: translateX(-65%);
  top: calc(100% + 8px);
  border-radius: 4px;
  filter: drop-shadow(rgba(70, 78, 95, 0.32) 0px 0px 8px);
  padding: 12px 0px;
  z-index: 120;
  &::after {
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent rgb(255, 255, 255);
    border-image: initial;
  }
`;

interface INotificationInnerButtonContainer {
  type: 'success' | 'warning' | 'error';
}

export const NotificationInnerButtonContainer = styled.div<INotificationInnerButtonContainer>`
  width: 100%;
  button {
    font-size: 16px;
    color: ${({ theme, type }) => {
      switch (type) {
        case 'success':
          return theme.colors.tagStatusColors.finalizado_or_ativo.color;
        case 'warning':
          return theme.colors.tagStatusColors.em_andamento_or_inativo.color;
        default:
          return theme.colors.tagStatusColors.cancelado.color;
      }
    }};
    padding: 6px 16px !important;
    width: 100%;
    &:hover {
      background-color: ${({ theme, type }) => {
        switch (type) {
          case 'success':
            return `${theme.colors.tagStatusColors.finalizado_or_ativo.bg}bb`;
          case 'warning':
            return `${theme.colors.tagStatusColors.em_andamento_or_inativo.bg}bb`;
          default:
            return `${theme.colors.tagStatusColors.cancelado.bg}bb`;
        }
      }};
    }
    span {
      font-weight: 400 !important;
      display: flex;
      flex-direction: initial;
      align-items: center;
      justify-content: space-between;
      .arrow {
        display: flex;
        align-items: center;
      }
    }
  }
`;
