import React, { useCallback, useState, useRef } from 'react';
import * as Yup from 'yup';
import i18n from 'i18next';

import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { FormHandles } from '@unform/core';

import FixedHeader from 'components/Tools/Breadcrumb';
import SearchBox from 'components/Tools/Search';
import Loader from 'components/Tools/Loader';
import DetailsItem from 'components/Tools/DetailsItem';

import { useTranslation } from 'react-i18next';

import {
  Container,
  Search,
  TableFilterCardContainer,
} from 'app/WMS/styles/styles';

import getValidationErrors from 'utils/getValidationErrors';
import StockAgeTable from './components/StockAgeTable';
import { getSearchData } from './apis/followAge.apis';
import { StockAgeData } from './types';

const FollowProductAge: React.FC = () => {
  const [animate] = useState<boolean>(true);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [stockAgeData, setStockAgeData] = useState<StockAgeData[]>([]);

  const searchRef = useRef<FormHandles>(null);

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const translatorMsg = useCallback((msg: string, response?: any): string => {
    const translator = i18n.t;
    return response
      ? `${String(response)} ${translator(msg)}`
      : `${translator(msg)}`;
  }, []);

  const handleSearch = useCallback(
    async data => {
      try {
        setShowTable(false);
        setIsLoading(true);

        const schema = Yup.object()
          .shape({
            tipoPedido: Yup.string(),
            pedido: Yup.string(),
            notaFiscal: Yup.string(),
            status: Yup.string(),
            serie: Yup.string(),
            produto: Yup.string(),
            fornecedor: Yup.string(),
          })
          .nullable();

        await schema.validate(data, {
          abortEarly: false,
        });

        /* const dataFilter: SearchDataProps = {
          diasEstoque: data.diasEstoque || null
          codigoPedido: data.pedido || null,
          notaFiscal: data.notaFiscal || null,
          fornecedor: data.fornecedor || null,
          produto: data.produto || null,
        }; */
        // setCardsSubmit(dataFilter);
        getSearchData() // dataFilter
          .then(response => {
            if (!response.data.length) {
              enqueueSnackbar(
                translatorMsg('screens.receivement.resultsNotFound'),
                {
                  variant: 'warning',
                }
              );
            } else {
              console.log(response.data, response.data.length);
              enqueueSnackbar(
                translatorMsg(
                  'screens.receivement.successFilter',
                  response.data.length
                ),
                {
                  variant: 'success',
                }
              );
              setIsLoading(false);
              setShowTable(true);
              setStockAgeData(response.data);
            }
          })
          .finally(() => {
            const timer = setTimeout(() => {
              setIsLoading(false);
              return () => {
                clearTimeout(timer);
              };
            }, 1000);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErrors(err);
          searchRef.current?.setErrors(erros);
          console.error(err);
          setIsLoading(false);
          enqueueSnackbar(translatorMsg('screens.receivement.alertSeach'), {
            variant: 'warning',
          });
        } else {
          console.error(err);
        }
      }
    },
    [enqueueSnackbar, translatorMsg]
  );

  const pendingFilter = (receive: StockAgeData[], codigoStatus?: number) => {
    switch (codigoStatus) {
      case 1: {
        const resultsReceive = receive.filter(item => {
          return Number(item.dias) >= 0 && Number(item.dias) < 10;
        });
        return resultsReceive.length;
      }
      case 2: {
        const resultsReceive = receive.filter(item => {
          return Number(item.dias) >= 10 && Number(item.dias) < 20;
        });
        return resultsReceive.length;
      }
      case 3: {
        const resultsReceive = receive.filter(item => {
          return Number(item.dias) >= 20;
        });
        return resultsReceive.length;
      }
      default:
        return receive.length;
    }
  };

  return (
    <>
      <FixedHeader title={t('screens.receivement.follow_up')} />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              handleSubmit={handleSearch}
              searchBoxRef={searchRef}
              inputs={[
                {
                  name: 'pedido',
                  type: 'text',
                  label: String(t('screens.receivement.order')),
                  placeholder: String(t('screens.receivement.order')),
                },
                {
                  name: 'notaFiscal',
                  type: 'text',
                  label: String(t('screens.receivement.invoice')),
                  placeholder: String(t('screens.receivement.invoice')),
                  isApiSearch: true,
                },
                {
                  name: 'diasEstoque',
                  type: 'text',
                  label: String(t('screens.followAge.daysInStock')),
                  placeholder: String(t('screens.followAge.daysInStock')),
                },
                {
                  name: 'produto',
                  type: 'text',
                  label: String(t('screens.receivement.product')),
                  placeholder: String(t('screens.receivement.product')),
                },
              ]}
              hiddenInputs={[
                {
                  name: 'fornecedor',
                  type: 'text',
                  label: String(t('screens.receivement.supplier')),
                  placeholder: String(t('screens.receivement.supplier')),
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
        </Fade>
        {isLoading && <Loader />}
        {showTable && (
          <>
            <TableFilterCardContainer>
              <div>
                {pendingFilter(stockAgeData, 3).toString() !== '0' && (
                  <button type="button" onClick={() => console.log('5')}>
                    <DetailsItem
                      card
                      title={String(t('screens.followAge.thirtyDays'))}
                      value={pendingFilter(stockAgeData, 3).toString()}
                      id="divergent"
                    />
                  </button>
                )}
                {pendingFilter(stockAgeData, 2).toString() !== '0' && (
                  <button type="button" onClick={() => console.log('4')}>
                    <DetailsItem
                      card
                      title={String(t('screens.followAge.twentyDays'))}
                      value={pendingFilter(stockAgeData, 2).toString()}
                    />
                  </button>
                )}
                {pendingFilter(stockAgeData, 1).toString() !== '0' && (
                  <button
                    type="button"
                    onClick={() => searchRef.current?.submitForm()}
                  >
                    <DetailsItem
                      card
                      title={String(t('screens.followAge.tenDays'))}
                      value={pendingFilter(stockAgeData, 1).toString()}
                    />
                  </button>
                )}
              </div>
            </TableFilterCardContainer>
            <StockAgeTable
              stockAge={stockAgeData}
              handleSearch={handleSearch}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default FollowProductAge;
