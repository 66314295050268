import styled from 'styled-components';

export const Svg = styled.svg``;

export const Container = styled.header`
  width: 100%;
  height: 6em;
  background: ${props => props.theme.colors.background};
  color: ${props =>
    props.theme.title === 'light' ? '#7a7a7a' : '#b5b5c3'} !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  z-index: 15;
  position: relative;

  .content {
    max-width: 1260px;
    margin: 0 auto;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .client {
      width: 350px;
      display: flex;
      align-items: center;
      @media (max-width: 640px) {
        width: 70px;
      }
    }
  }

  .infos {
    display: flex;
    align-items: center;
  }

  .signOut {
    width: 90px;
    height: 35px;
    margin-left: 1rem;
    padding: 5px;

    background-color: ${props => props.theme.colors.backgroundLogoutButton};
    border-radius: 3px;

    @media (max-width: 640px) {
      width: 80px;
      height: 25px;
      margin: 10px 0 0 25px;
    }

    p {
      font-size: 0.9rem;
      text-align: center;
      margin-left: 10%;
      color: ${props =>
        props.theme.title === 'light' ? '#7a7a7a' : '#b5b5c3'} !important;
      @media (max-width: 640px) {
        font-size: 1rem;
      }

      img {
        width: 100px;
        @media (max-width: 640px) {
          width: 80px;
        }
      }
    }
  }
`;

export const BesniImg = styled.svg`
  width: 150px;
  fill: red;
  margin: 20px;
  border-left: 1px solid ${props => props.theme.colors.line};
`;
