import React from 'react';
import { DetailsItemProps } from 'features/Receivement/types/index';

const DetailsItem: React.FC<DetailsItemProps> = ({
  title,
  value,
  id,
  card,
  children,
  displayCardZero,
}) => {
  return (
    <>
      {(value !== '0' || displayCardZero) && (
        <div className="detailsItem tableFilterCard" id={id}>
          {card ? (
            <>
              <p>{value}</p>
              <p>{title}</p>
              {children}
            </>
          ) : (
            <>
              <p>{title}</p>
              <p>{value}</p>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default DetailsItem;
