/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SelectItem {
  codigo?: number | string | boolean;
  code?: number | string | boolean;
  station?: number | string | boolean;
  status?: number | string | boolean;
  description?: string;
  descricao?: string;
  label?: string;
  value?: number | string | boolean;
  uf?: string;
  nome?: string;
  channel?: string;
  codigoUsuario?: number | string | boolean;
  codigoTipo?: number | string;
  periodo?: number | string;
  codigoFilial?: number | string;
  codigoFilialERP?: number | string;
  tipoPedido?: string;
}

export const transformToSelect = (array: any) => {
  return array.map((item: SelectItem) => ({
    code:
      item.codigo ||
      item.code ||
      item.uf ||
      item.codigoUsuario ||
      item.codigoTipo ||
      item.codigoFilial ||
      item.periodo,
    description:
      item.descricao ||
      item.nome ||
      item.periodo ||
      item.codigoFilialERP ||
      item.channel,
    label:
      item.descricao ||
      item.nome ||
      item.periodo ||
      item.description ||
      item.codigoFilialERP ||
      item.code,
    value:
      item.codigo ||
      item.uf ||
      item.codigoUsuario ||
      item.codigoTipo ||
      item.codigoFilial ||
      item.periodo ||
      item.code ||
      item.tipoPedido,
  }));
};

export const newTransformToSelect = <T extends SelectItem>(array: Array<T>) => {
  return array.map(item => ({
    code:
      item.codigo ||
      item.uf ||
      item.codigoUsuario ||
      item.codigoTipo ||
      item.codigoFilial ||
      item.periodo,
    description:
      item.descricao || item.nome || item.periodo || item.codigoFilialERP,
    label: item.descricao || item.nome || item.periodo || item.codigoFilialERP,
    value:
      item.codigo ||
      item.uf ||
      item.codigoUsuario ||
      item.codigoTipo ||
      item.codigoFilial ||
      item.periodo ||
      item.tipoPedido,
  }));
};
