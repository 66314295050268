/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { createContext, useContext, useCallback, useState } from 'react';
import Loader from 'components/Tools/Loader';
import { Fade } from '@material-ui/core';

interface iLoadingContextProps {
  isGlobalLoadingActive: boolean;
  setGlobalLoading: (value: boolean) => void;
}

const LoadingContext = createContext<iLoadingContextProps | undefined>(
  undefined
);

export const GlobalLoadingProvider: React.FC = ({ children }) => {
  const [isGlobalLoadingActive, setIsGlobalLoadingActive] = useState(false);

  const setGlobalLoading = useCallback((value: boolean) => {
    setIsGlobalLoadingActive(value);
  }, []);

  return (
    //  @ts-ignore
    <LoadingContext.Provider
      value={{ isGlobalLoadingActive, setGlobalLoading }}
    >
      <Fade in={isGlobalLoadingActive} timeout={500} unmountOnExit>
        <div
          style={{
            display: 'grid',
            placeItems: 'center',
            zIndex: 1999,
            width: '100vw',
            height: '100vh',
            position: 'absolute',
            top: 0,
            left: 0,
            background: 'rgba(14, 14, 14, 0.3)',
          }}
        >
          <Loader />
        </div>
      </Fade>
      {children}
    </LoadingContext.Provider>
  );
};

export function useGlobalLoading(): iLoadingContextProps {
  const context = useContext(LoadingContext);

  if (!context)
    throw new Error(
      'Use o hook dentro de um componente, que está dentro do provider'
    );

  return context;
}
