import React, { SVGAttributes } from 'react';
import { Svg } from './styles';

interface SvgProps extends SVGAttributes<HTMLOrSVGElement> {
  as?: string;
}

const Address: React.FC<SvgProps> = ({ as, ...rest }) => {
  return (
    <Svg {...rest} id={as} key={as}>
      <path
        d="M3 10.5C1.4 10.5 0 14 0 14H6L7 21L8 14H14C14 13.1667 13.5 11.3 11.5 10.5H11V2.5C11.5 2.33333 12.5 1.8 12.5 1C12.5 0.666667 12.2 0 11 0H3C2.66667 0 1.9 0.2 1.5 1C1.5 1.5 1.8 2.5 3 2.5V10.5Z"
        fill="#FFFFFF"
      />
    </Svg>
  );
};

export default Address;
