/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { SnackbarProvider, useSnackbar, SnackbarKey } from 'notistack';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';

import { MobileProvider } from './Mobile';
import { AuthProvider } from './Auth';
import { ProviderTheme } from './Theme';
import { NotificationProvider } from './Notification';
import { SidebarContextProvider } from './Sidebar';
import { GlobalLoadingProvider } from './LoadingContext';
import { SocketContextProvider } from './Socket';

const Hooks: React.FC = ({ children }) => {
  const SnackbarCloseButton: React.FC<{ id: SnackbarKey }> = ({ id }) => {
    const { closeSnackbar } = useSnackbar();

    return (
      <IconButton onClick={() => closeSnackbar(id)}>
        <CloseIcon style={{ fill: 'white' }} />
      </IconButton>
    );
  };

  const useStyles = makeStyles(() => ({
    success: {
      backgroundColor: '#50CD89 !important',
      boxShadow: 'none !important',
    },
    warning: {
      backgroundColor: '#F1BC00 !important',
      boxShadow: 'none !important',
    },
    error: {
      backgroundColor: '#F1416C !important',
      boxShadow: 'none !important',
    },
  }));

  const classes = useStyles();

  return (
    <ProviderTheme>
      {/* @ts-ignore */}
      <SnackbarProvider
        maxSnack={3}
        hideIconVariant
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transitionDuration={{ enter: 300, exit: 400 }}
        autoHideDuration={3000}
        action={key => <SnackbarCloseButton id={key} />}
        classes={{ variantSuccess: classes.success }}
      >
        <SidebarContextProvider>
          <GlobalLoadingProvider>
            <AuthProvider>
              <MobileProvider>
                <SocketContextProvider>
                  <NotificationProvider>{children}</NotificationProvider>
                </SocketContextProvider>
              </MobileProvider>
            </AuthProvider>
          </GlobalLoadingProvider>
        </SidebarContextProvider>
      </SnackbarProvider>
    </ProviderTheme>
  );
};

export default Hooks;
