import React, { SVGAttributes } from 'react';
import { Svg } from './styles';

interface SvgProps extends SVGAttributes<HTMLOrSVGElement> {
  as?: string;
}

const IconPlan: React.FC<SvgProps> = ({ as, ...rest }) => {
  return (
    <Svg {...rest} id={as} key={as}>
      <path
        d="M21.7143 10.57L6.92857 12.4986L5 2.21289H21.7143C22.7429 2.21289 23 3.07003 23 3.4986V9.28432C23 10.3129 22.1429 10.57 21.7143 10.57Z"
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeLinecap="round"
      />
      <path
        d="M0.5 0.927734H4.35714L6.92857 12.4992V13.7849L6.28571 15.0706C6.28571 15.2849 6.41429 15.7134 6.92857 15.7134C7.44286 15.7134 16.1429 15.7134 20.4286 15.7134"
        stroke="#FFFFFF"
        strokeLinecap="round"
      />
      <circle cx="7.89285" cy="17.9626" r="1.60714" fill="#FFFFFF" />
      <circle cx="19.4643" cy="17.9626" r="1.60714" fill="#FFFFFF" />
    </Svg>
  );
};

export default IconPlan;
