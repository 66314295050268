import React, { useState } from 'react';
import { Fade } from '@material-ui/core';

import { TableContainer } from 'app/WMS/styles/styles';

import { Table } from 'components/Tools/Table';
import {
  StockAgeData,
  StockAgeTableProps,
} from 'features/FollowProductAge/types';
import { useTranslation } from 'react-i18next';

const StockAgeTable: React.FC<StockAgeTableProps> = ({ stockAge }) => {
  const [animate] = useState(true);

  const { t } = useTranslation();
  return (
    <>
      <Fade in={animate} timeout={1000}>
        <TableContainer>
          <Table<StockAgeData>
            exportList
            rows={stockAge}
            columns={[
              {
                title: String(t('screens.receivement.document')),
                props: ['documento'],
                type: 'stringCenter',
                orderable: true,
              },
              {
                title: String(t('screens.address.adresses')),
                props: ['endereco'],
                type: 'stringCenter',
                orderable: true,
              },
              {
                title: String(t('screens.commom.product')),
                props: ['produto'],
                type: 'stringCenter',
                orderable: true,
              },
              {
                title: String(t('screens.reportsStock.descriptionProduct')),
                props: ['descricaoProduto'],
                type: 'stringCenter',
                orderable: true,
                display: 'notEmpty',
              },
              {
                title: String(t('screens.commom.quantity')),
                props: ['quantidade'],
                type: 'stringCenter',
                orderable: true,
              },
              {
                title: String(t('screens.commom.user')),
                props: ['usuario'],
                type: 'stringCenter',
                orderable: true,
              },
              {
                title: String(t('screens.manualOrder.date')),
                props: ['data'],
                type: 'datetime',
                orderable: true,
              },
              {
                title: String(t('screens.followAge.daysInStock')),
                props: ['dias'],
                type: 'number',
                orderable: true,
              },
            ]}
            defaultNumberOfRows={10}
          />
        </TableContainer>
      </Fade>
    </>
  );
};

export default StockAgeTable;
