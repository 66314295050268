/* eslint-disable no-restricted-globals */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useEffect, useRef } from 'react';

import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Fade,
  IconButton,
  Popper,
  PopperPlacementType,
} from '@material-ui/core';

import { useTranslation } from 'react-i18next';

import Notification from 'components/Tools/Notification';
import { useMobile } from 'hooks/Mobile';
import { getCookie } from 'utils/cookies';
import { useTema } from 'hooks/Theme';
import { useSidebar } from 'hooks/Sidebar';
import { ChangeLogMenu } from 'components/Tools/ChageLogMenu';
import { useLocation } from 'react-router-dom';
import {
  BoxOptions,
  Container,
  Main,
  HeaderContainer,
  CompanyName,
  UserInfo,
} from './styles';
import Sidebar from '../Sidebar';
import { ThemeToggle } from '../../Tools/ThemeToggle';
import { useAuth } from '../../../hooks/Auth';
import { ArrowLogout, UserIcon } from './icons';

interface ISignedLayoutProps {
  loged?: boolean;
  children?: React.ReactNode;
}

const SignedLayout: React.FC<ISignedLayoutProps> = ({ children }) => {
  const { isFixed } = useSidebar();
  const { pathname } = useLocation();
  const { isMobileDevice } = useMobile();
  const [closed, setClosed] = useState(true);
  const client = getCookie('@pdamodules::descricaoCliente');

  const [animate, setAnimate] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [placement, setPlacement] = useState<PopperPlacementType>();

  const { toggleTheme } = useTema();
  const { signOut, user } = useAuth();

  const helpUrl = String(process.env.REACT_APP_HELP_PROD_URL) + pathname;

  const { t } = useTranslation();

  const environment_type = String(process.env.REACT_APP_ENVIRONMENTTYPE);

  const Logout = useCallback(() => {
    signOut();
  }, [signOut]);

  const handleRedirect = (type: 'about' | 'help' | 'support') => {
    switch (type) {
      case 'support':
        location.href = 'mailto:suporte@pdasolucoes.com.br';
        break;
      case 'about':
        window.open('http://pdaforms.com.br/a-pda/', '_blank');
        break;
      case 'help':
        window.open(`${helpUrl}`, '_blank');
        break;
      default:
        location.href = 'mailto:suporte@pdasolucoes.com.br';
    }
  };

  const handleClick =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setOpen(prev => placement !== newPlacement || !prev);
      setAnimate(true);
      setPlacement(newPlacement);
    };

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setAnimate(false);
    setOpen(false);
  };

  const environment =
    environment_type === 'homologação' ? 'Homologação' : environment_type;

  useEffect(() => {
    if (isMobileDevice) {
      setClosed(false);
    }
  }, [isMobileDevice]);

  return (
    <>
      <Container closed={closed} fixed={isFixed}>
        <HeaderContainer>
          {!isMobileDevice && (
            <>
              <div className="invisible-block" />
              <CompanyName>{client}</CompanyName>
            </>
          )}
          <div className="userInteractions">
            {isMobileDevice ? (
              <Notification />
            ) : (
              <></>
              /* <ThemeToggle toggleTheme={toggleTheme} /> */
            )}
            <ChangeLogMenu />
            <UserInfo environment_type={environment_type}>
              {isMobileDevice ? (
                /* <ThemeToggle toggleTheme={toggleTheme} /> */
                <></>
              ) : (
                <Notification />
              )}
              <div className="greetings">
                <p>
                  {t('components.user.greetings')},
                  <span className="userName"> {user?.data.nome}</span>
                </p>

                <span className="environment-type">
                  {environment_type ? environment : 'não informado'}
                </span>
              </div>
              <div className="user">
                <IconButton id="btn" onClick={handleClick('top')}>
                  <UserIcon />
                </IconButton>
                {isMobileDevice && (
                  <CompanyName isMobile={isMobileDevice}>{client}</CompanyName>
                )}
                {/* @ts-ignore */}
                <Box className="popper-box">
                  <Popper
                    open={open}
                    anchorEl={anchorEl}
                    placement={placement}
                    transition
                    className="popper"
                    style={{ zIndex: 999999 }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <Fade in={animate}>
                        <BoxOptions>
                          <div className="popperProfileWrapper">
                            <p>{t('components.userPopper.profile')}</p>
                            <ThemeToggle toggleTheme={toggleTheme} />
                          </div>
                          <Divider className="divider" />
                          <div className="popperLowerWrapper">
                            <div>
                              <p>{t('components.userPopper.config')}</p>
                            </div>
                            <Button
                              className="helpButtons"
                              onClick={() => handleRedirect('support')}
                            >
                              {t('header.aboutMenu.support')}
                            </Button>
                            <Button
                              className="helpButtons"
                              onClick={() => handleRedirect('about')}
                            >
                              {t('header.aboutMenu.about')}
                            </Button>
                            <Button
                              className="helpButtons"
                              onClick={() => handleRedirect('help')}
                            >
                              {t('header.aboutMenu.help')}
                            </Button>
                            <Button
                              className="logoutBtn"
                              ref={anchorRef}
                              onClick={Logout}
                            >
                              <p>Logout</p>
                              <ArrowLogout />
                            </Button>
                          </div>
                        </BoxOptions>
                      </Fade>
                    </ClickAwayListener>
                  </Popper>
                </Box>
              </div>
              {/* <About /> */}
            </UserInfo>
          </div>
        </HeaderContainer>
        <Main onClick={() => isMobileDevice && setClosed(false)} id="main">
          <>
            {children}
            <div className="shape shapeOne" />
          </>
        </Main>
      </Container>
      {/* <Footer /> */}
      <Sidebar />
    </>
  );
};

export default SignedLayout;
