import React, { SVGAttributes } from 'react';
import { Svg } from './styles';

interface SvgProps extends SVGAttributes<HTMLOrSVGElement> {
  as?: string;
}

const IconFlag: React.FC<SvgProps> = ({ as, ...rest }) => {
  return (
    <Svg {...rest} id={as} key={as}>
      <path
        d="M9.70727 0.896853L7.70782 0.849609L17.4806 10.5832C18.2615 11.4018 18.1156 11.9319 17.9451 12.0946L11.805 17.9512C12.5859 18.7698 13.7966 18.3316 14.3043 18.0102L19.421 13.1297C21.0489 11.9679 20.1544 10.3129 19.5037 9.6307C19.5037 9.6307 12.3361 2.07061 12.1829 1.95563C12.0298 1.84065 10.5364 1.08316 9.70727 0.896853Z"
        fill="#FFFFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2V7C0 7.83333 1 9.5 1.5 10L9 17.5C9.33333 17.6667 10.1 17.9 10.5 17.5L16 12C16.1667 11.6667 16.4 10.9 16 10.5L7.5 2C7.16667 1.83333 6.3 1.5 5.5 1.5H0.5C0.333333 1.5 0 1.6 0 2ZM4 7C4.82843 7 5.5 6.32843 5.5 5.5C5.5 4.67157 4.82843 4 4 4C3.17157 4 2.5 4.67157 2.5 5.5C2.5 6.32843 3.17157 7 4 7Z"
        fill="#FFFFFF"
      />
    </Svg>
  );
};

export default IconFlag;
