/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import i18n from 'i18next';

import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { FormHandles } from '@unform/core';

import FixedHeader from 'components/Tools/Breadcrumb';
import SearchBox from 'components/Tools/Search';
import Loader from 'components/Tools/Loader';
import DetailsItem from 'components/Tools/DetailsItem';

import { useTranslation } from 'react-i18next';

import { AxiosResponse } from 'axios';
import {
  Container,
  Search,
  TableFilterCardContainer,
} from 'app/WMS/styles/styles';

import getValidationErrors from 'utils/getValidationErrors';
import { SelectItem, transformToSelect } from 'utils/toSelect';
import { handleParamiters } from 'features/Order/Reverse/api/reverse.api';
import { getCookie } from 'utils/cookies';
import { FollowWaveData } from './types';
import FollowWaveTable from './components/followWaveTable';
import { getSearchData } from './apis/followWave.api';

const FollowWave: React.FC = () => {
  const [animate] = useState<boolean>(true);
  const [showTable, setShowTable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [cardProgress, setCardProgress] = useState<number>(0);
  const [status, setStatus] = useState({} as SelectItem[]);

  const idCliente = getCookie('@pdamodules::codigoCliente');

  const [waveData, setWaveData] = useState<FollowWaveData[]>([]);

  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  const date = new Date(Date.now() - tzoffset).toISOString().split('T')[0];

  const searchRef = useRef<FormHandles>(null);

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const translatorMsg = (
    caseUser: number,
    msgDefault: string,
    response?: string,
    msgFinal?: string
  ): string => {
    const translator = i18n.t;
    switch (caseUser) {
      case 1:
        return translator(msgDefault);
      case 2:
        return `${translator(msgDefault)} ${String(response)} ${translator(
          String(msgFinal)
        )}`;
      case 3:
        return `${translator(msgDefault)} ${String(response)}`;
      default:
        return 'err';
    }
  };

  const daysBetween = () => {
    const startDate = searchRef.current?.getFieldValue('dataInicial');
    const endDate = searchRef.current?.getFieldValue('dataFinal');
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    console.log(
      (Date.parse(endDate) - Date.parse(startDate)) / millisecondsPerDay,
      startDate,
      endDate
    );
    return Math.floor(
      (Date.parse(endDate) - Date.parse(startDate)) / millisecondsPerDay
    );
  };

  const checkFields = useCallback(() => {
    if (
      searchRef.current?.getFieldValue('usuario') !== '' ||
      searchRef.current?.getFieldValue('onda') !== ''
    ) {
      return false;
    }
    return true;
  }, []);

  const handleProgressCard = (progress: number) => {
    setCardProgress(progress);
  };

  const handleSearch = useCallback(
    async data => {
      try {
        setShowTable(false);
        setIsLoading(true);
        checkFields();

        const isRequired = checkFields();
        const checkDate = daysBetween();

        if ((isRequired && checkDate > 30) || (isRequired && checkDate < -30)) {
          throw new Yup.ValidationError([], 'ValidationError', 'dataInicial');
        }

        const schema = Yup.object()
          .shape({
            status: Yup.string(),
            pedido: Yup.string(),
            onda: Yup.string(),
            dataInicial:
              isRequired === true ? Yup.string().required() : Yup.string(),
            dataFinal:
              isRequired === true ? Yup.string().required() : Yup.string(),
          })
          .nullable();

        await schema.validate(data, {
          abortEarly: false,
        });

        /* const dataFilter: SearchDataProps = {
          diasEstoque: data.diasEstoque || null
          codigoPedido: data.pedido || null,
          notaFiscal: data.notaFiscal || null,
          fornecedor: data.fornecedor || null,
          produto: data.produto || null,
        }; */
        // setCardsSubmit(dataFilter);
        getSearchData() // dataFilter
          .then(response => {
            if (!response.data.length) {
              enqueueSnackbar(
                translatorMsg(1, 'screens.reverseOrder.noRecordesFound'),
                {
                  variant: 'warning',
                }
              );
            } else {
              enqueueSnackbar(
                translatorMsg(
                  2,
                  'screens.commom.recordsFound',
                  response.data.length,
                  'screens.commom.records'
                ),
                {
                  variant: 'success',
                }
              );
              setIsLoading(false);
              setShowTable(true);
              handleProgressCard(20); // response.data.porcentagemTotal);
              setWaveData(response.data);
            }
          })
          .finally(() => {
            const timer = setTimeout(() => {
              setIsLoading(false);
              return () => {
                clearTimeout(timer);
              };
            }, 1000);
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const erros = getValidationErrors(err);
          searchRef.current?.setErrors(erros);
          console.error(err);
          setIsLoading(false);
          enqueueSnackbar(
            translatorMsg(1, 'screens.reverseOrder.noRecordesFound'),
            {
              variant: 'warning',
            }
          );
        } else {
          console.error(err);
        }
      }
    },
    [checkFields, enqueueSnackbar]
  );

  const pendingFilter = (wave: FollowWaveData[], codigoStatus?: number) => {
    switch (codigoStatus) {
      case 1: {
        const resultsWave = wave.filter(item => {
          return item.status === 'Finalizado';
        });
        return resultsWave.length;
      }
      case 2: {
        const resultsWave = wave.filter(item => {
          return item.status === 'Finalizado divergente';
        });
        return resultsWave.length;
      }
      case 3: {
        const resultsWave = wave.filter(item => {
          return item.status === 'Em andamento';
        });
        return resultsWave.length;
      }
      case 4: {
        const resultsWave = wave.filter(item => {
          return item.status === 'Divergente';
        });
        return resultsWave.length;
      }
      case 5: {
        const resultsWave = wave.filter(item => {
          return item.status === 'Pendente';
        });
        return resultsWave.length;
      }
      case 6: {
        const resultsWave = wave.filter(item => {
          return item.status === 'Planejado';
        });
        return resultsWave.length;
      }
      default:
        return wave.length;
    }
  };

  const getParam = useCallback(async () => {
    setIsLoading(true);
    try {
      await handleParamiters(Number(idCliente)).then(
        (statusPickingData: AxiosResponse) => {
          const listStatus = transformToSelect(statusPickingData.data);
          setStatus([...listStatus]);
        }
      );
    } finally {
      setIsLoading(false);
    }
  }, [idCliente]);

  useEffect(() => {
    getParam();
  }, [getParam]);

  return (
    <>
      <FixedHeader
        subTitle={String(t('screens.followWave.followWave'))}
        title={t('screens.createWave.picking')}
      />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              handleSubmit={handleSearch}
              searchBoxRef={searchRef}
              inputs={[
                {
                  name: 'onda',
                  label: String(t('screens.reverseOrder.wave')),
                  placeholder: String(t('screens.reverseOrder.wave')),
                  type: 'text',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 12,
                },
                {
                  name: 'usuario',
                  placeholder: String(t('screens.commom.user')),
                  type: 'text',
                  label: String(t('screens.commom.user')),
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 12,
                },
                {
                  name: 'status',
                  label: String(t('screens.commom.status')),
                  placeholder: String(t('screens.commom.status')),
                  type: 'select',
                  options: status,
                  isLoading,
                  isDisabled: isLoading,
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 12,
                },
              ]}
              hiddenInputs={[
                {
                  name: 'dataInicial',
                  label: String(t('screens.commom.initialDate')),
                  type: 'date',
                  defaultValue: date,
                  isRequired: checkFields(),
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'dataFinal',
                  label: String(t('screens.commom.finalDate')),
                  type: 'date',
                  defaultValue: date,
                  isRequired: checkFields(),
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
        </Fade>
        {isLoading && <Loader />}
        {showTable && (
          <>
            <TableFilterCardContainer cardProgressValue={cardProgress}>
              <div>
                {pendingFilter(waveData).toString() !== '0' && (
                  <button
                    type="button"
                    onClick={() => searchRef.current?.submitForm()}
                  >
                    <DetailsItem
                      card
                      title={String(t('screens.cardsFilters.results'))}
                      value={pendingFilter(waveData).toString()}
                    />
                  </button>
                )}
                {pendingFilter(waveData, 1).toString() !== '0' && (
                  <button type="button" onClick={() => console.log()}>
                    <DetailsItem
                      card
                      title={String(t('screens.cardsFilters.finished'))}
                      value={pendingFilter(waveData, 1).toString()}
                      id="done"
                    />
                  </button>
                )}
                {pendingFilter(waveData, 2).toString() !== '0' && (
                  <button type="button" onClick={() => console.log()}>
                    <DetailsItem
                      card
                      title={String(
                        t('screens.cardsFilters.completedDivergent')
                      )}
                      value={pendingFilter(waveData, 2).toString()}
                      id="divergent"
                    />
                  </button>
                )}
                {pendingFilter(waveData, 3).toString() !== '0' && (
                  <button type="button" onClick={() => console.log()}>
                    <DetailsItem
                      card
                      title={String(t('screens.cardsFilters.inProgress'))}
                      value={pendingFilter(waveData, 3).toString()}
                      id="ongoing"
                    />
                  </button>
                )}
                {pendingFilter(waveData, 4).toString() !== '0' && (
                  <button type="button" onClick={() => console.log()}>
                    <DetailsItem
                      card
                      title={String(t('screens.cardsFilters.divergent'))}
                      value={pendingFilter(waveData, 4).toString()}
                      id="divergent"
                    />
                  </button>
                )}
                {pendingFilter(waveData, 5).toString() !== '0' && (
                  <button type="button" onClick={() => console.log()}>
                    <DetailsItem
                      card
                      title={String(t('screens.cardsFilters.pending'))}
                      value={pendingFilter(waveData, 5).toString()}
                      id="pending"
                    />
                  </button>
                )}
                {pendingFilter(waveData, 6).toString() !== '0' && (
                  <button type="button" onClick={() => console.log()}>
                    <DetailsItem
                      card
                      title={String(t('screens.cardsFilters.planned'))}
                      value={pendingFilter(waveData, 6).toString()}
                      id="waiting"
                    />
                  </button>
                )}
              </div>
              <div>
                <DetailsItem
                  card
                  title={String(t('screens.cardsFilters.totalProgress'))}
                  value={`${cardProgress.toFixed(0).toString()}%`}
                  id="totalProgress"
                >
                  {' '}
                  <span />
                </DetailsItem>
              </div>
            </TableFilterCardContainer>
            <FollowWaveTable
              followWave={waveData}
              handleSearch={handleSearch}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default FollowWave;
