import api from 'services/api';
import { DetailInventoryData } from 'utils/interfaces/Log';

export const searchBoxRequest = async (
  data: any,
  clientId: number,
  userId: number
) => {
  const formatedData = {
    CodigoCliente: clientId,
    User: userId,
    endereco: data.endereco || null,
    codigoDeposito: data.deposito || null,
    produto: data.produto || null,
    codigoPedido: data.pedido || null,
    codigoMarca: data.marca || null,
    descricaoPalete: data.palete || null,
    descricaoCaixa: data.caixa || null,
    CodigoCodigoNivel1: data.selectLevel || null,
    CodigoCodigoNivel2: data.selectLevel2 || null,
    CodigoCodigoNivel3: data.selectLevel3 || null,
    CodigoCodigoNivel4: data.selectLevel4 || null,
    CodigoCodigoNivel5: data.selectLevel5 || null,
  };

  // if (!formatedData.produto)
  return api.patch('Relatorio/ArmazenagemEstoque', {
    ...formatedData,
  });

  // const detailData = {
  //   CodigoCliente: clientId,
  //   User: userId,
  //   produto: data.produto,
  // };

  // return api.patch('Relatorio/ArmazenagemEstoqueDetalhe', { ...detailData });
};

export const getAllOptions = async (clientId: number) => {
  const [deposits, brands, level1, level2, level3, level4, level5] =
    await Promise.all([
      api.get(`Common/Deposito?CodigoCliente=${clientId}`),
      api.get(`Common/Marca?CodigoCliente=${clientId}`),
      api.get(
        `Common/EstruturaMercadologica?CodigoCliente=${clientId}&Nivel=1`
      ),
      api.get(
        `Common/EstruturaMercadologica?CodigoCliente=${clientId}&Nivel=2`
      ),
      api.get(
        `Common/EstruturaMercadologica?CodigoCliente=${clientId}&Nivel=3`
      ),
      api.get(
        `Common/EstruturaMercadologica?CodigoCliente=${clientId}&Nivel=4`
      ),
      api.get(
        `Common/EstruturaMercadologica?CodigoCliente=${clientId}&Nivel=4`
      ),
    ]);

  return [deposits, brands, level1, level2, level3, level4, level5];
};

export const detailsRequest = async (data: any) =>
  api.patch<DetailInventoryData[]>('Relatorio/ArmazenagemEstoqueDetalhe', {
    ...data,
  });
