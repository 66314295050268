import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1005;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
