import React from 'react';

const PickingDivergente: React.FC = () => {
  return (
    <svg
      width="3"
      height="22"
      viewBox="0 0 3 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 2V14"
        stroke="#FFFFFF"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <circle cx="1.5" cy="20" r="1.5" fill="#FFFFFF" />
    </svg>
  );
};

export default PickingDivergente;
