/* eslint-disable @typescript-eslint/ban-ts-comment */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IAgentPrinter {
  log: boolean;
  agent: string | undefined;
}

const agentSlice = createSlice({
  initialState: {
    log: false,
    agent: undefined,
  } as IAgentPrinter,

  name: 'agentPrinter',
  reducers: {
    disconect: state => {
      state.log = false;
      state.agent = undefined;
    },
    setIsLogAgent: (state, action: PayloadAction<IAgentPrinter>) => {
      state.log = action.payload.log;
      state.agent = action.payload.agent;
    },
  },
});

export default agentSlice.reducer;
export const { disconect, setIsLogAgent } = agentSlice.actions;
