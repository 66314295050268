import styled from 'styled-components';

interface ContainerProps {
  progress: number;
  breakPointColors?: string[];
  breakPoints?: number[];
}

export const Container = styled.div<ContainerProps>`
  .progress-value {
    font-size: 10px;
    font-weight: 400;
    line-height: 19px;
    color: ${props =>
      props.theme.title === 'light' ? '#7a7a7a' : '#b5b5c3'} !important;
  }

  .progress-bar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 5px;
    overflow: hidden;
    line-height: 0;
    font-size: 0.75rem;
    border-radius: 0.42rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: ${(props: ContainerProps) => {
      const breaks = props.breakPoints || [0, 1, 5, 50, 100];
      const bg = props.breakPointColors || [
        'rgba(216, 216, 216, 0.2)',
        'rgba(217, 33, 78, 0.2)',
        'rgba(246, 155, 17, 0.2)',
        'rgba(255, 199, 0, 0.2)',
        'rgba(70, 190, 125, 0.2)',
      ];
      let colorIndex = 0;
      if (breaks.length > bg.length) {
        throw new Error(
          'breakPoints array should not be greater than breakPointColors array'
        );
      }
      breaks.forEach((item, index) => {
        if (props.progress >= item) colorIndex = index;
      });
      return bg[colorIndex];
    }};
  }

  .progress-bar-value {
    background-color: ${(props: ContainerProps) => {
      const breaks = props.breakPoints || [0, 1, 5, 50, 100];
      const colors = props.breakPointColors || [
        'rgba(216, 216, 216, 1)',
        'rgba(217, 33, 78, 1)',
        'rgba(246, 155, 17, 1)',
        'rgba(255, 199, 0, 1)',
        'rgba(70, 190, 125, 1)',
      ];
      let colorIndex = 0;
      if (breaks.length > colors.length) {
        throw new Error(
          'breakPoints array should not be greater than breakPointColors array'
        );
      }
      breaks.forEach((item, index) => {
        if (props.progress >= item) colorIndex = index;
      });
      return colors[colorIndex];
    }};
    width: ${props => `${props.progress}%`};
  }
`;
