/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import api from 'services/api';
import { AxiosResponse } from 'axios';
import { useLocation } from 'react-router-dom';

import { setCookie, getCookie } from 'utils/cookies';

export interface iInventoryRequest {
  codigoInventario: number;
  codigoAutorizacao: number;
  filial: string;
  status: string;
  tipo: string;
  dataInventario: string;
  dataCriacao: string;
  primeiraColeta?: string;
  ultimaColeta?: string;
  ajustado?: boolean;
  direcionar?: boolean;
  progresso?: number;
  enderecosDuplicados?: number;
}

interface InventoryContextValue {
  isInventoryActive?: iInventoryRequest;
  setInventorySelectec: (value?: iInventoryRequest) => void;
  updateInventory: (inventoryCode: number) => Promise<void>;
}

const getInventoryAPI = (inventoryCode: Number) => {
  return api.get<iInventoryRequest[]>(
    `inventario/Iniciar?codigoInventario=${inventoryCode}`
  );
};

const inventory = getCookie('@pdamodules::inventorySelected');

const InventoryContext = createContext<InventoryContextValue | undefined>(
  undefined
);

export const InventorySelectedProvider: React.FC = ({ children }) => {
  const { pathname } = useLocation();

  const [isInventoryActive, setIsInventoryActive] = useState<
    iInventoryRequest | undefined
  >(inventory);

  const setInventorySelectec = useCallback((value?: iInventoryRequest) => {
    setCookie('@pdamodules::inventorySelected', value);
    setIsInventoryActive(value);
  }, []);

  const updateInventory = async (inventoryCode: number) => {
    await getInventoryAPI(inventoryCode).then((res: AxiosResponse) => {
      const newInventory: iInventoryRequest = res.data[0];
      setInventorySelectec(newInventory);
    });
  };

  useEffect(() => {
    if (isInventoryActive && !pathname.startsWith('/wms/Inventory')) {
      setCookie('@pdamodules::inventorySelected', undefined);
      setInventorySelectec(undefined);
    }
  }, [isInventoryActive, pathname, setInventorySelectec]);

  useEffect(() => {
    if (isInventoryActive && isInventoryActive.codigoInventario) {
      updateInventory(isInventoryActive.codigoInventario);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    //  @ts-ignore
    <InventoryContext.Provider
      value={{ isInventoryActive, setInventorySelectec, updateInventory }}
    >
      {children}
    </InventoryContext.Provider>
  );
};

export function useInventorySelected(): InventoryContextValue {
  const context = useContext(InventoryContext);

  if (!context)
    throw new Error(
      'Use o hook dentro de um componente, que está dentro do provider'
    );

  return context;
}
