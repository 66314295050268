/* eslint-disable @typescript-eslint/ban-ts-comment */
import * as React from 'react';

import {
  Paper,
  Popper,
  MenuList,
  Button,
  Grow,
  ClickAwayListener,
} from '@material-ui/core';

import TagStatus from 'components/Tools/TagStatus';
import ProgressBar from 'components/Tools/ProgressBar';

import { formatDate } from 'utils/formatDate';

import { InventoryModalContainer } from '../styles';

export interface iInventoryRequest {
  codigoInventario: number;
  codigoAutorizacao: number;
  filial: string;
  status: string;
  tipo: string;
  dataInventario: string;
  dataCriacao: string;
  primeiraColeta?: string;
  ultimaColeta?: string;
  ajustado?: boolean;
  direcionar?: boolean;
  progresso?: number;
  enderecosDuplicados?: number;
}

interface IOptionsLanguageProps {
  isInventoryActive: iInventoryRequest;
}

export const DetailInventory: React.FC<IOptionsLanguageProps> = ({
  isInventoryActive,
}) => {
  const [open, setOpen] = React.useState(false);

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);

  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <div className="containerTitle" style={{ margin: '10px' }}>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <InventoryModalContainer>
            <p className="titleMenuInventory">DETALHES DO INVENTÁRIO</p>
          </InventoryModalContainer>
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          className="containerPopper"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                {/* @ts-ignore */}
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <InventoryModalContainer>
                      <div className="inventoryModal">
                        <p>
                          <span className="red-text">
                            ENDEREÇOS DUPLICADOS:
                          </span>{' '}
                          <span className="red-text">
                            {isInventoryActive.enderecosDuplicados || 0}
                          </span>
                        </p>
                        <p>
                          <span className="blue-text">Id:</span>{' '}
                          {isInventoryActive.codigoInventario}
                        </p>
                        <p>
                          <span className="blue-text">Autorização:</span>{' '}
                          {isInventoryActive.codigoAutorizacao}
                        </p>
                        <p>
                          <span>Tipo:</span> {isInventoryActive.tipo}
                        </p>
                        <p>
                          <span className="blue-text">Filial:</span>{' '}
                          {isInventoryActive.filial}
                        </p>
                        <p
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                          }}
                        >
                          <span className="blue-text">Status: </span>{' '}
                          <TagStatus
                            status={isInventoryActive.status}
                            width="fit-content"
                          />
                        </p>
                        <p>
                          <span>Data:</span>{' '}
                          {formatDate(isInventoryActive.dataInventario)}
                        </p>
                        <p
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                          }}
                        >
                          <span className="progreess blue-text">
                            Progresso:
                          </span>
                          <div
                            style={{
                              width: '100% !important',
                              marginRight: '50px',
                            }}
                          >
                            <ProgressBar
                              style={{
                                width: '300%',
                                display: 'inline-block',
                              }}
                              value={isInventoryActive.progresso || 0}
                            />
                          </div>
                        </p>
                      </div>
                    </InventoryModalContainer>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
};
export default DetailInventory;
