/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Badge,
  Button,
  Divider,
  Fade,
  Grid,
  ClickAwayListener,
} from '@material-ui/core';
import { FormHandles } from '@unform/core';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import React, { useCallback, ReactElement, useEffect } from 'react';
import backArrow from 'assets/svg/backArrow.svg';
import { useMobile } from 'hooks/Mobile';
import {
  Checkbox,
  DatePicker,
  Input,
  Select,
  SwitchButton,
  Password,
  InputFile,
  Textarea,
} from 'components/Tools/Form';
import { InputProps } from 'components/Tools/Search';
import { Container, ModalContainer, ModalContentContainer } from './styles';
import Loader from '../Loader';

type newButtonActionsProps = {
  onClick: (
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void | undefined;
  renderItem: () => string | ReactElement;
};

interface PageModeProps {
  isModal?: boolean;
  open?: boolean;
  onPage?: boolean;
  newPage?: boolean;
  pageActionsFooter?: boolean;
  actionPrintButton?: (data?: any) => void;
  buttonPrint?: boolean;
  children?: any;
  handleCloseTitle?: string;
  handleCloseBackground?: string;
  title?: string;
  submitButtonTitle?: string;
  submitButtonColor?: string;
  submitButtonHover?: string;
  submitButtonTitleHover?: string;
  inLineChildren?: boolean;
  submitButtonIcon?: boolean;
  inputs?: InputProps[];
  modalRefObject?: React.RefObject<FormHandles>;
  modalLoading?: boolean;
  handleClose?: () => void;
  disableSubmit?: boolean;
  handleSubmit?(data: object): void;
  newButton?: boolean;
  newButtonActions?: Array<newButtonActionsProps>;
  closeWhenClickOutside?: boolean;
  disableBackButton?: boolean;
}

const PageMode: React.FC<PageModeProps> = ({
  isModal,
  onPage,
  newPage,
  pageActionsFooter,
  buttonPrint,
  actionPrintButton,
  open,
  handleClose = () => {},
  children,
  inLineChildren,
  submitButtonTitle,
  submitButtonColor,
  submitButtonIcon,
  handleSubmit = () => {},
  disableSubmit,
  modalLoading,
  title,
  handleCloseTitle,
  inputs,
  modalRefObject,
  handleCloseBackground,
  newButton,
  newButtonActions,
  closeWhenClickOutside,
  disableBackButton,
}) => {
  const { colors } = useTheme();
  const { isMobileDevice } = useMobile();

  const { t } = useTranslation();

  const ownRef = React.useRef<FormHandles>(null);
  const formRef = modalRefObject || ownRef;

  const switchType = (input: InputProps) => {
    switch (input.type) {
      case 'text':
        return (
          <Input
            id={input.name}
            name={input.name}
            key={input.name.toString()}
            type="text"
            isDisabled={input.isDisabled}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            required={input.isRequired}
            onChange={(e: any) => input.onChangeEvent && input.onChangeEvent(e)}
            // iconError={IconError}
            // iconSuccess={IconSuccess}
          />
        );
      case 'textArea':
        return (
          <Textarea
            id={input.name}
            name={input.name}
            key={input.name.toString()}
            isDisabled={input.isDisabled}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            defaultValue={input.defaultValue}
            required={input.isRequired}
            minLength={input.minLenghtCaracter}
            maxLength={input.maxLenghtCaracter}
          />
        );
      case 'number':
        return (
          <Input
            id={input.name}
            name={input.name}
            key={input.name.toString()}
            type="number"
            isDisabled={input.isDisabled}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            required={input.isRequired}
            onChange={(e: any) => input.onChangeEvent && input.onChangeEvent(e)}
            isInteger={input.isInteger}
          />
        );
      case 'file':
        return (
          <InputFile
            id={input.name}
            name={input.name}
            type="file"
            key={input.name.toString()}
            isDisabled={input.isDisabled}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            defaultValue={input.defaultValue}
          />
        );
      case 'select':
        return (
          <Select
            name={input.name}
            key={input.name.toString()}
            options={input.options}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            isLoading={input.isLoading}
            isDisabled={input.isDisabled}
            styles={input.styles}
            onChangeEvent={input.onChangeEvent}
            isApiSearch={input.isApiSearch}
            required={input.isRequired}
            handleInputChange={input.handleInputChange}
            multiSelect={input.isMulti}
          />
        );
      case 'date':
        return (
          <DatePicker
            id={input.name}
            name={input.name}
            type="date"
            key={input.name.toString()}
            isDisabled={input.isDisabled}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            required={input.isRequired}
            dateMin={input.minDate}
          />
        );
      case 'switch':
        return (
          <SwitchButton
            id={input.name}
            name={input.name}
            key={input.name.toString()}
            type="checkbox"
            placeholder={input.placeholder}
            required={input.isRequired}
          />
        );
      case 'checkbox':
        return (
          <Checkbox
            id={input.name}
            name={input.name}
            type="checkbox"
            key={input.name.toString()}
            label={input.labelCheckbox}
            placeholder={input.placeholder}
            onClickEvent={input.onClickEvent}
            required={input.isRequired}
          />
        );
      case 'password':
        return (
          <Password
            id={input.name}
            name={input.name}
            key={input.name.toString()}
            type="password"
            isDisabled={input.isDisabled}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            required={input.isRequired}
            onChange={(e: any) => input.onChangeEvent && input.onChangeEvent(e)}
          />
        );
      default:
        return <input type="hidden" />;
    }
  };

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    if (open) {
      window.addEventListener('keyup', handleKeyPress, true);
      return () => window.removeEventListener('keyup', handleKeyPress, true);
    }
    return () => window.removeEventListener('keyup', handleKeyPress, true);
  }, [handleKeyPress, open]);

  const handleInputError = useCallback(
    ({ inputName, message }) => {
      if (formRef) {
        formRef.current?.setFieldError(inputName, message);
      }
    },
    [formRef]
  );
  return (
    <>
      {isModal && (
        <>
          <Fade
            unmountOnExit
            mountOnEnter
            timeout={400}
            in={open}
            style={{ height: isMobileDevice ? 'fit-content' : '' }}
          >
            <ModalContainer
              ref={formRef}
              onSubmit={(data: any) => handleSubmit(data)}
              style={{ height: isMobileDevice ? 'fit-content' : '' }}
              id="pageMode"
            >
              {modalLoading ? (
                <Loader />
              ) : (
                <ClickAwayListener
                  onClickAway={() => {
                    if (closeWhenClickOutside) {
                      handleClose();
                    }
                  }}
                >
                  <ModalContentContainer
                    buttonBackground={submitButtonColor}
                    style={{ height: isMobileDevice ? 'fit-content' : '' }}
                  >
                    <>
                      <div className="modalHeader">
                        <p>{title}</p>
                        <Button
                          onClick={handleClose}
                          style={{ color: colors.container_title }}
                          className="modalCloseButton"
                        >
                          <CloseOutlinedIcon color="inherit" />
                        </Button>
                      </div>
                      <Divider color="#EBEDF2" />
                      <Grid
                        container
                        spacing={3}
                        style={{
                          padding: '1.5rem 1rem',
                          alignItems: 'flex-start',
                        }}
                      >
                        {inputs?.map(
                          ({
                            name,
                            label,
                            type,
                            options,
                            placeholder,
                            width = 3,
                            sm = 12,
                            xl = width,
                            lg = width,
                            xs = 12,
                            md = 12,
                            isRequired,
                            isLoading,
                            isDisabled,
                            styles,
                            onClickEvent,
                            labelCheckbox,
                            onChangeEvent,
                            display,
                            isMulti,
                            messageInputs,
                            ...rest
                          }) =>
                            display ?? false ? null : (
                              <Grid
                                item
                                xl={xl}
                                lg={lg}
                                xs={xs}
                                md={md}
                                sm={sm}
                                key={name.toString()}
                              >
                                {!!label && isRequired ? (
                                  <Badge badgeContent="*">
                                    <p className="labelInput">{label}</p>
                                  </Badge>
                                ) : (
                                  <p className="labelInput">{label}</p>
                                )}
                                {switchType({
                                  name,
                                  label,
                                  type,
                                  options,
                                  placeholder,
                                  width,
                                  sm,
                                  xl,
                                  lg,
                                  xs,
                                  md,
                                  isRequired,
                                  isLoading,
                                  isDisabled,
                                  styles,
                                  onClickEvent,
                                  labelCheckbox,
                                  onChangeEvent,
                                  isMulti,
                                  ...rest,
                                })}
                                <div>
                                  {messageInputs && (
                                    <p className="inputDesc">{messageInputs}</p>
                                  )}
                                </div>
                              </Grid>
                            )
                        )}
                        {inLineChildren && children}
                      </Grid>
                      {!inLineChildren && children}
                      <Divider color="#EBEDF2" />
                      <div className="modalFooter">
                        {disableSubmit !== true && (
                          <Button type="submit" className="submitModal">
                            {submitButtonIcon && (
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.55333 2H9.78C10.2667 2 10.6667 2.4 10.6667 2.88667V4.44667C10.6667 4.93333 10.2667 5.33333 9.78 5.33333H9.55333C9.06667 5.33333 8.66667 4.93333 8.66667 4.44667V2.88667C8.66667 2.4 9.06667 2 9.55333 2ZM16 3.03333V14.0867C16 15.1467 15.14 16 14.0867 16H1.91333C0.86 16 0 15.14 0 14.0867V2.18667C0 0.98 0.98 0 2.18667 0H12.9667C13.2 0 13.4267 0.0933333 13.5933 0.26L15.74 2.40667C15.9067 2.57333 16 2.8 16 3.03333ZM5.55333 6H10.4467C10.9333 6 11.3333 5.6 11.3333 5.11333V2.22C11.3333 1.73333 10.9333 1.33333 10.4467 1.33333H5.55333C5.06667 1.33333 4.66667 1.73333 4.66667 2.22V5.11333C4.66667 5.6 5.06667 6 5.55333 6ZM14.6667 3.58667C14.6667 3.35333 14.5733 3.12667 14.4067 2.96L12.78 1.33333H12.6667V6.44667C12.6667 6.93333 12.2667 7.33333 11.78 7.33333H4.22C3.73333 7.33333 3.33333 6.93333 3.33333 6.44667V2.22C3.33333 1.73333 2.93333 1.33333 2.44667 1.33333H2.22C1.73333 1.33333 1.33333 1.73333 1.33333 2.22V13.78C1.33333 14.2667 1.73333 14.6667 2.22 14.6667H13.78C14.2667 14.6667 14.6667 14.2667 14.6667 13.78V3.58667Z"
                                  fill="#E8FFF3"
                                />
                              </svg>
                            )}
                            <p>{submitButtonTitle}</p>
                          </Button>
                        )}
                        {newButton &&
                          newButtonActions?.map(
                            (action: newButtonActionsProps) => (
                              <Button
                                type="button"
                                onClick={action.onClick}
                                className="newButton"
                              >
                                {/* @ts-ignore */}
                                {action.renderItem()}
                              </Button>
                            )
                          )}
                        {buttonPrint && (
                          <Button
                            className="printerButton"
                            onClick={actionPrintButton}
                          >
                            {t('components.pageMode.printOut')}
                          </Button>
                        )}
                        {!disableBackButton ||
                          (disableBackButton !== true && (
                            <Button
                              onClick={handleClose}
                              className="closeModal"
                              style={{
                                backgroundColor: handleCloseBackground,
                                height: isMobileDevice ? 'fit-content' : '',
                              }}
                            >
                              {handleCloseTitle ||
                                t('components.pageMode.close')}
                            </Button>
                          ))}
                      </div>
                    </>
                  </ModalContentContainer>
                </ClickAwayListener>
              )}
            </ModalContainer>
          </Fade>
        </>
      )}
      {onPage && (
        <>
          <Grid
            container
            spacing={3}
            style={{
              padding: '1.5rem 1rem',
              alignItems: 'self-end',
            }}
          >
            {inputs?.map(
              ({
                name,
                label,
                type = 'text',
                options,
                placeholder,
                width = 3,
                sm = 12,
                xl = width,
                lg = width,
                xs = 12,
                md = 12,
                isRequired,
                isLoading,
                isDisabled,
                styles,
                onClickEvent,
                labelCheckbox,
                onChangeEvent,
                isMulti,
                ...rest
              }) => (
                <Grid
                  item
                  xl={xl}
                  lg={lg}
                  xs={xs}
                  md={md}
                  sm={sm}
                  key={name.toString()}
                >
                  {!!label && isRequired ? (
                    <Badge badgeContent="*">
                      <p className="labelInput">{label}</p>
                    </Badge>
                  ) : (
                    <p className="labelInput">{label}</p>
                  )}
                  {switchType({
                    name,
                    label,
                    type,
                    options,
                    placeholder,
                    width,
                    sm,
                    xl,
                    lg,
                    xs,
                    md,
                    isRequired,
                    isLoading,
                    isDisabled,
                    styles,
                    onClickEvent,
                    labelCheckbox,
                    onChangeEvent,
                    isMulti,
                    ...rest,
                  })}
                </Grid>
              )
            )}
          </Grid>
          {children}
        </>
      )}
      {newPage && (
        <Container>
          <div className="header">
            <p>{title}</p>
            {!pageActionsFooter && (
              <div className="footerButtons">
                <Button className="backButton" onClick={handleClose}>
                  <img alt="Voltar" src={backArrow} />
                  {t('components.pageMode.back')}
                </Button>
                {disableSubmit !== true && (
                  <Button
                    type="button"
                    className="submitButton"
                    onClick={handleSubmit}
                  >
                    {submitButtonIcon && (
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M9.55333 2H9.78C10.2667 2 10.6667 2.4 10.6667 2.88667V4.44667C10.6667 4.93333 10.2667 5.33333 9.78 5.33333H9.55333C9.06667 5.33333 8.66667 4.93333 8.66667 4.44667V2.88667C8.66667 2.4 9.06667 2 9.55333 2ZM16 3.03333V14.0867C16 15.1467 15.14 16 14.0867 16H1.91333C0.86 16 0 15.14 0 14.0867V2.18667C0 0.98 0.98 0 2.18667 0H12.9667C13.2 0 13.4267 0.0933333 13.5933 0.26L15.74 2.40667C15.9067 2.57333 16 2.8 16 3.03333ZM5.55333 6H10.4467C10.9333 6 11.3333 5.6 11.3333 5.11333V2.22C11.3333 1.73333 10.9333 1.33333 10.4467 1.33333H5.55333C5.06667 1.33333 4.66667 1.73333 4.66667 2.22V5.11333C4.66667 5.6 5.06667 6 5.55333 6ZM14.6667 3.58667C14.6667 3.35333 14.5733 3.12667 14.4067 2.96L12.78 1.33333H12.6667V6.44667C12.6667 6.93333 12.2667 7.33333 11.78 7.33333H4.22C3.73333 7.33333 3.33333 6.93333 3.33333 6.44667V2.22C3.33333 1.73333 2.93333 1.33333 2.44667 1.33333H2.22C1.73333 1.33333 1.33333 1.73333 1.33333 2.22V13.78C1.33333 14.2667 1.73333 14.6667 2.22 14.6667H13.78C14.2667 14.6667 14.6667 14.2667 14.6667 13.78V3.58667Z"
                          fill="#E8FFF3"
                        />
                      </svg>
                    )}
                    <p>{submitButtonTitle}</p>
                  </Button>
                )}
              </div>
            )}
          </div>
          <Divider color="#EBEDF2" />
          {children}
          {pageActionsFooter && (
            <div className="footerButtons">
              <Button className="backButton" onClick={handleClose}>
                <img alt="Voltar" src={backArrow} />
                Voltar
              </Button>
              {disableSubmit !== true && (
                <Button
                  type="button"
                  disableRipple
                  className="submitButton"
                  onClick={handleSubmit}
                >
                  {submitButtonIcon && (
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.55333 2H9.78C10.2667 2 10.6667 2.4 10.6667 2.88667V4.44667C10.6667 4.93333 10.2667 5.33333 9.78 5.33333H9.55333C9.06667 5.33333 8.66667 4.93333 8.66667 4.44667V2.88667C8.66667 2.4 9.06667 2 9.55333 2ZM16 3.03333V14.0867C16 15.1467 15.14 16 14.0867 16H1.91333C0.86 16 0 15.14 0 14.0867V2.18667C0 0.98 0.98 0 2.18667 0H12.9667C13.2 0 13.4267 0.0933333 13.5933 0.26L15.74 2.40667C15.9067 2.57333 16 2.8 16 3.03333ZM5.55333 6H10.4467C10.9333 6 11.3333 5.6 11.3333 5.11333V2.22C11.3333 1.73333 10.9333 1.33333 10.4467 1.33333H5.55333C5.06667 1.33333 4.66667 1.73333 4.66667 2.22V5.11333C4.66667 5.6 5.06667 6 5.55333 6ZM14.6667 3.58667C14.6667 3.35333 14.5733 3.12667 14.4067 2.96L12.78 1.33333H12.6667V6.44667C12.6667 6.93333 12.2667 7.33333 11.78 7.33333H4.22C3.73333 7.33333 3.33333 6.93333 3.33333 6.44667V2.22C3.33333 1.73333 2.93333 1.33333 2.44667 1.33333H2.22C1.73333 1.33333 1.33333 1.73333 1.33333 2.22V13.78C1.33333 14.2667 1.73333 14.6667 2.22 14.6667H13.78C14.2667 14.6667 14.6667 14.2667 14.6667 13.78V3.58667Z"
                        fill="#E8FFF3"
                      />
                    </svg>
                  )}
                  <p>{submitButtonTitle}</p>
                </Button>
              )}
            </div>
          )}
        </Container>
      )}
    </>
  );
};

export default PageMode;
