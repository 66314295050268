/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { createContext, useContext, useCallback } from 'react';
import { ThemeProvider } from 'styled-components';

import { Themes } from 'styles/styled';
import light from 'styles/themes/light';
import dark from 'styles/themes/dark';
import usePersistedState from 'utils/usePersistedState';

interface ITheme {
  toggleTheme: () => void;
  setTheme: (themeTitle: Themes) => void;
}

const ThemeContext = createContext<ITheme>({} as ITheme);

export const ProviderTheme: React.FC = ({ children }) => {
  const [currentTheme, setCurrentTheme] = usePersistedState<Themes>(
    'theme',
    'light'
  );

  const toggleTheme = useCallback(() => {
    setCurrentTheme(prevState => (prevState === 'light' ? 'dark' : 'light'));
  }, [setCurrentTheme]);

  const setTheme = useCallback(
    (themeTitle: Themes) => setCurrentTheme(themeTitle),
    [setCurrentTheme]
  );

  return (
    <ThemeContext.Provider value={{ toggleTheme, setTheme }}>
      <ThemeProvider theme={currentTheme === 'light' ? light : dark}>
        {/* @ts-ignore */}
        {children}
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};

export function useTema(): ITheme {
  const context = useContext(ThemeContext);

  if (!context)
    throw new Error('useTema deve ser usado dentro do providerTheme');

  return context;
}
