import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

interface IMobileContextProps {
  isMobileDevice: boolean;
  currentWidth: number;
}

const MobileContext = createContext<IMobileContextProps>(
  {} as IMobileContextProps
);

export const MobileProvider: React.FC = ({ children }) => {
  const [isMobileDevice, setIsMobileDevice] = useState<boolean>(false);
  const [currentWidth, setCurrentWidth] = useState<number>(NaN);

  const handleWindowResize = useCallback(() => {
    setCurrentWidth(window.innerWidth);
    return setIsMobileDevice(window.innerWidth <= 980);
  }, []);

  useEffect(() => {
    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, [handleWindowResize]);

  return (
    <MobileContext.Provider value={{ isMobileDevice, currentWidth }}>
      {children}
    </MobileContext.Provider>
  );
};

export function useMobile(): IMobileContextProps {
  const context = useContext(MobileContext);

  if (!context)
    throw new Error('useMobile deve ser usado dentro do MobileProvider');

  return context;
}
