import { format } from 'date-fns';

const isValidDate = (d: any) => {
  return d instanceof Date && !Number.isNaN(d);
};

const formatDateTime = (data: string): string => {
  const dateObj = new Date(data);
  const dateFormat = format(dateObj, 'dd/MM/yyyy HH:mm:ss');
  return dateFormat;
};

const formatDate = (value: Date | string) => {
  console.log(isValidDate(value), value);

  if (value) {
    const formatedDate = new Date(value)
      .toISOString()
      .split('T')[0]
      .split('-')
      .slice(0, 3)
      .reverse()
      .join('/');

    return formatedDate;
  }

  return '';
};

const formatDateHours = (date: Date) => {
  const formatedHours = format(new Date(date), 'hh:mm a');

  return formatedHours;
};
type FormatDateTypes = {
  currentDate: any;
  hasMinutesAndHoursAndSecouds?: boolean;
  formatHours?: true;
};

const formatToDate = ({
  currentDate,
  hasMinutesAndHoursAndSecouds,
  formatHours,
}: FormatDateTypes): string => {
  const dateFormatter = new Intl.DateTimeFormat('pt-br', {
    year: 'numeric',
    day: '2-digit',
    month: '2-digit',
    minute: hasMinutesAndHoursAndSecouds ? '2-digit' : undefined,
    hour: hasMinutesAndHoursAndSecouds ? '2-digit' : undefined,
    second: hasMinutesAndHoursAndSecouds ? '2-digit' : undefined,
  });

  const formatedHours = new Date(currentDate).toLocaleTimeString();

  return formatHours
    ? formatedHours
    : dateFormatter.format(new Date(currentDate));
};

export { formatDateTime, formatDate, formatDateHours, formatToDate };
