/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/jsx-one-expression-per-line */
import {
  Divider,
  Fade,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import React from 'react';
import Jspdf from 'jspdf';
import { Skeleton } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { AxiosError, AxiosResponse } from 'axios';
import KeyboardBackspaceRoundedIcon from '@material-ui/icons/KeyboardBackspaceRounded';
import { useTheme } from 'styled-components';
import { Table, SearchBox, HyperLink, TagStatus } from '@pdasolucoes/web';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { getCookie } from 'utils/cookies';
import FixedHeader from '../../../../components/Tools/Breadcrumb';
import {
  Actions,
  Container,
  Search,
  TableContainer,
} from '../../../WMS/styles/styles';
import { SelectItem, transformToSelect } from '../../../../utils/toSelect';
import { Request } from '../../../../utils/interfaces/index';
import { useAuth } from '../../../../hooks/Auth';
import api from '../../../../services/api';
import { PerfilData } from '../../../../utils/interfaces/PerfilAcesso';
import Modal from '../../../../components/Tools/_Modal';
import { iTagColor } from '../../../../styles/styled';
import Detail from './Detail';
import {
  ReceivementData,
  ReceivementDataDetail,
} from '../../../../utils/interfaces/StoreReceivement';

const StoreReceivement: React.FC = () => {
  const [receivement, setReceivement] = React.useState([] as ReceivementData[]);
  const [detailPedido, setDetailPedido] = React.useState(
    [] as ReceivementDataDetail[]
  );

  const [details, setDetails] = React.useState({
    nf: '',
    serie: '',
    totalPecas: NaN,
    totalRecebido: NaN,
    recebimentoInicial: '',
    recebimentoFim: '',
    status: '',
    quantidadeSKU: NaN,
    quantidadeSkuRecebido: NaN,
    totalTempo: '',
  });

  const { colors } = useTheme();
  const [animate, setAnimate] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [statusList, setStatusList] = React.useState([] as SelectItem[]);
  const [destinoList, setDestinoList] = React.useState([] as SelectItem[]);
  const [isLoading, setIsLoading] = React.useState(Boolean);
  const [exportPerfil, setExport] = React.useState(Boolean);
  const [description, setDescription] = React.useState('');
  const [status, setStatus] = React.useState(true);
  const [table, setTable] = React.useState(true);
  const [detail, setDetail] = React.useState(NaN);
  const [confirmModal, setConfirmModal] = React.useState(Boolean);
  const [codeOrder, setCodeOrder] = React.useState('');
  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  const date = new Date(Date.now() - tzoffset).toISOString().split('T')[0];

  const { enqueueSnackbar } = useSnackbar();
  const { token, user } = useAuth();
  const idPerfil = user?.data.codigoPerfil;

  const idCliente = getCookie('@pdamodules::codigoCliente');
  const idUser = getCookie('@pdamodules::id');

  const tokenNoBar = token?.replaceAll('\\', '');

  const tokenData = tokenNoBar?.replace(/"/g, '');

  React.useEffect(() => {
    setAnimate(true);
  }, []);

  const exportPDF = React.useCallback(async () => {
    const doc = new Jspdf('p', 'pt', 'a4');
    doc.html(document.querySelector('#screenModel') as HTMLElement, {
      callback(pdf) {
        pdf.save('document.pdf');
      },
    });
  }, []);

  const getPerfil = React.useCallback(async () => {
    const data = {
      codigoCliente: Number(idCliente),
      codigoSistema: 2,
      codigoPerfil: Number(idPerfil),
    };
    try {
      await api
        .patch<PerfilData>(
          `PerfilAcesso`,
          { ...data },
          {
            headers: { Authorization: `Bearer ${tokenData}` },
          }
        )
        .then((perfilData: sponse) => {
          const perfilValue = perfilData.data.filter(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (item: any) => item.ordemMenu === 3
          )[0].exportar;
          setExport(perfilValue);
        })
        .catch((perfilDataError: AxiosError<any>) => {
          // eslint-disable-next-line no-console
          if (perfilDataError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          } else {
            perfilDataError.response!.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
            });
          }
        });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, [tokenData, idCliente, idPerfil, enqueueSnackbar]);

  React.useEffect(() => {
    getPerfil();
  }, [getPerfil]);

  const getProduct = React.useCallback(
    async (
      code: number,
      notaFiscal: string,
      statusData: string,
      tempoTotal: string,
      quantidade: number,
      quantidaderecebimento: number,
      QuantidadeDivergencia: number,
      serie: string,
      quantidadeSku: number,
      quantidadeSkuRecebidoData: number,
      inicioRecebimento: string,
      fimRecebimento: string
    ) => {
      const dataTotalInicio = new Date(inicioRecebimento).toLocaleString();
      const dataTotalFim = new Date(fimRecebimento).toLocaleString();
      setDetails({
        nf: notaFiscal,
        serie,
        quantidadeSKU: quantidadeSku,
        quantidadeSkuRecebido: quantidadeSkuRecebidoData,
        recebimentoFim: dataTotalFim,
        recebimentoInicial: dataTotalInicio,
        status: statusData,
        totalPecas: quantidade,
        totalTempo: tempoTotal,
        totalRecebido: quantidaderecebimento,
      });

      const dataDetail = {
        codigoCliente: Number(idCliente),
        User: Number(idUser),
        codigoPedido: String(code),
      };
      try {
        await api
          .patch(
            `RecebimentoItem`,
            { ...dataDetail },
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          )
          .then((itemResponse: AxiosResponse) => {
            const itemValue = itemResponse.data;

            if (itemResponse.data.length === 0) {
              setTable(true);
              setDetail(NaN);
              enqueueSnackbar('Não foi encontrado nenhum registo', {
                variant: 'error',
              });
            } else {
              setDescription(String(code));
              setTable(false);
              setDetail(code);
              setDetailPedido(itemValue);
            }

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          })
          .catch((itemResponse: AxiosError<any>) => {
            // eslint-disable-next-line no-console
            if (itemResponse.response!.status === 401 && tokenData) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              itemResponse.response!.data.erros.map((item: any) => {
                enqueueSnackbar(item.mensagem, { variant: 'error' });
              });
            }
          });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    [idCliente, idUser, tokenData, enqueueSnackbar]
  );

  const getParam = React.useCallback(async () => {
    setIsLoading(true);
    try {
      await api
        .get<SelectItem>(`StatusRecebimento`, {
          headers: { Authorization: `Bearer ${tokenData}` },
        })
        .then((statusResponse: AxiosResponse) => {
          const listStatus = transformToSelect(statusResponse.data);
          setStatusList([...listStatus]);
        })
        .catch((statusError: AxiosError<any>) => {
          if (statusError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          } else {
            statusError.response!.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
            });
          }
        });
      await api
        .get(`Usuario/Autenticado`, {
          headers: { Authorization: `Bearer ${tokenData}` },
        })
        .then((statusResponse: AxiosResponse) => {
          const listDestino = transformToSelect(statusResponse.data.filiais);
          setDestinoList([...listDestino]);
        })
        .catch((statusError: AxiosError<any>) => {
          if (statusError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          } else {
            statusError.response!.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
            });
          }
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [tokenData, enqueueSnackbar]);

  React.useEffect(() => {
    getParam();
  }, [getParam]);

  const handleTraceBack = React.useCallback(() => {
    setDetail(NaN);
    setTable(true);
  }, []);

  const handleSubmit = React.useCallback(
    async (data: any) => {
      try {
        setLoading(false);
        setVisible(true);
        const dataFilter = {
          CodigoCliente: Number(idCliente),
          User: Number(idUser),
          codigoPedido: data.pedido || null,
          notaFiscal: data.notaFiscal || null,
          fornecedor: data.fornecedor || null,
          codigoStatus: data.status || null,
          produto: data.produto || null,
          serie: data.serie || null,
          dataInicial: data.dataInicio
            ? new Date(data.dataInicio).toISOString()
            : null,
          dataFinal: data.dataFinal
            ? new Date(data.dataFim).toISOString()
            : null,
        };

        const response: Request<ReceivementData> = await api.patch(
          `Recebimento`,
          {
            ...dataFilter,
          },
          {
            headers: { Authorization: `Bearer ${tokenData}` },
          }
        );
        if (response.data.length <= 0) {
          enqueueSnackbar(`Não existe registro!`, { variant: 'error' });

          setReceivement([]);
        } else {
          enqueueSnackbar(
            `Foi encontrado ${response.data.length} registro(s)!`,
            {
              variant: 'success',
            }
          );
          setTable(true);
          setReceivement([...response.data]);
          if (
            response.data.findIndex(item => item.status === 'Divergente') < 0
          ) {
            setStatus(true);
          } else {
            setStatus(false);
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      } finally {
        const timer = setTimeout(() => {
          setLoading(true);
          setVisible(false);
          return () => {
            clearTimeout(timer);
          };
        }, 1000);
      }
    },
    [tokenData, enqueueSnackbar, idCliente, idUser]
  );

  const receivementHas = React.useCallback(async (codeOrderData: number) => {
    if (codeOrderData) {
      setDescription(String(codeOrderData));
      setConfirmModal(true);
      setCodeOrder(String(codeOrderData));
    }
  }, []);

  const handleReceivement = React.useCallback(async () => {
    try {
      const dataReceivement = {
        codigoCliente: Number(idCliente),
        codigoPedido: String(codeOrder),
      };

      await api
        .put(
          `Recebimento/FinalizarDivergente`,
          { ...dataReceivement },
          {
            headers: { Authorization: `Bearer ${tokenData}` },
          }
        )
        .then((receivementData: AxiosResponse) => {
          enqueueSnackbar(`Pedido ${codeOrder} finalizado!`, {
            variant: 'success',
          });
          setConfirmModal(false);
          setDescription('');
          setCodeOrder('');
          handleSubmit((data: any) => data);
        })
        .catch((errorQuery: AxiosError<any>) => {
          if (errorQuery.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          } else {
            errorQuery.response!.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
            });
          }
        });
    } catch (err) {
      console.error(err);
    }
  }, [enqueueSnackbar, idCliente, tokenData, codeOrder, handleSubmit]);

  const handleCancelConfirm = React.useCallback(async () => {
    setConfirmModal(false);
    setDescription('');
    setCodeOrder('');
  }, []);

  return (
    <>
      <Modal
        type="confirm"
        title={`Deseja finalizar o pedido ${description}?`}
        open={confirmModal}
        handleCancel={handleCancelConfirm}
        handleSubmit={handleReceivement}
      >
        Você tem certeza que deseja finalizar o pedido {description}?
      </Modal>
      <FixedHeader subTitle="Loja" title="Recebimento" />
      <Container>
        {detail ? (
          ''
        ) : (
          <Fade in={animate} timeout={1000}>
            <Search>
              <SearchBox
                handleSubmit={handleSubmit}
                advancedSearch
                inputs={[
                  {
                    xl: 3,
                    lg: 3,
                    xs: 12,
                    md: 12,
                    sm: 12,
                    name: 'pedido',
                    type: 'text',
                    label: 'Pedido',
                    placeholder: 'Pedido',
                  },
                  {
                    xl: 3,
                    lg: 3,
                    xs: 12,
                    md: 12,
                    sm: 12,
                    name: 'notaFiscal',
                    type: 'text',
                    label: 'Nota Fiscal',
                    placeholder: 'Nota Fiscal',
                  },
                  {
                    xl: 3,
                    lg: 3,
                    xs: 12,
                    md: 12,
                    sm: 12,
                    options: destinoList,
                    name: 'loja',
                    type: 'select',
                    label: 'Destino',
                    placeholder: 'Destino',
                  },
                  {
                    xl: 3,
                    lg: 3,
                    xs: 12,
                    md: 12,
                    sm: 12,
                    name: 'status',
                    type: 'select',
                    // styles: colourStyles,
                    options: statusList,
                    isDisabled: isLoading,
                    isLoading,
                    label: 'Status',
                    placeholder: 'Status',
                  },
                ]}
                hiddenInputs={[
                  {
                    xl: 6,
                    lg: 6,
                    xs: 12,
                    md: 12,
                    sm: 12,
                    name: 'produto',
                    type: 'text',
                    label: 'Produto',
                    placeholder: 'Produto',
                  },
                  {
                    xl: 6,
                    lg: 6,
                    xs: 12,
                    md: 12,
                    sm: 12,
                    name: 'serie',
                    type: 'text',
                    label: 'Serie',
                    placeholder: 'Serie',
                  },
                  {
                    name: 'dataInicio',
                    label: 'Data Inicial',
                    type: 'date',
                    xl: 6,
                    lg: 6,
                    xs: 12,
                    md: 6,
                    sm: 6,
                  },
                  {
                    name: 'dataFinal',
                    label: 'Data Final',
                    type: 'date',
                    xl: 6,
                    lg: 6,
                    xs: 12,
                    md: 6,
                    sm: 6,
                  },
                ]}
              />
            </Search>
          </Fade>
        )}

        {visible && (
          <>
            {!loading && (
              <Fade in={animate} unmountOnExit timeout={1000}>
                <div className="wrapper" style={{ display: 'flex' }}>
                  <div className="wrap" style={{ height: 410, flexGrow: 1 }}>
                    <Skeleton
                      style={{ borderRadius: '5px' }}
                      variant="rect"
                      height={400}
                    />
                  </div>
                </div>
              </Fade>
            )}
          </>
        )}
        {table && (
          <>
            {loading && (
              <Fade in={animate} timeout={1000}>
                <TableContainer>
                  <Table<ReceivementData>
                    exportList={!!exportPerfil}
                    columns={[
                      {
                        title: 'Pedido',
                        type: 'string',
                        orderable: true,
                        props: ['codigoPedido'],
                        cssTitle: {
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        },
                        cssProps: {
                          width: '15%',
                          paddingRight: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                        renderItem: link => {
                          return (
                            // eslint-disable-next-line react/jsx-curly-newline
                            <HyperLink
                              type="detail"
                              style={{
                                paddingLeft: '16px',
                                fontSize: '13px',
                              }}
                              title={String(link.codigoPedido)}
                              onClick={
                                () =>
                                  getProduct(
                                    link.codigoPedido,
                                    link.notaFiscal,
                                    link.status,
                                    link.tempoTotal,
                                    link.quantidade,
                                    link.quantidadeRecebimento,
                                    link.quantidadeDivergencia,
                                    link.serie,
                                    link.quantidadeSku,
                                    link.quantidadeSkuRecebido,
                                    link.inicioRecebimento,
                                    link.fimRecebimento
                                  )
                                // eslint-disable-next-line prettier/prettier
                              }
                            />
                          );
                        },
                      },
                      {
                        title: 'Status',
                        type: 'string',
                        orderable: true,
                        props: ['status'],
                        cssTitle: {
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        },
                        cssProps: {
                          width: '10%',
                          paddingRight: '8px',
                          paddingLeft: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                        renderItem: row => {
                          return (
                            <TagStatus
                              status={row.status}
                              color={
                                colors.tagTextColors.filter(
                                  tag => tag.name === row.status
                                ).length > 0
                                  ? colors.tagTextColors.filter(
                                      tag => tag.name === row.status
                                    )[0].value
                                  : undefined
                              }
                              background={
                                colors.tagBackgroundColors.filter(
                                  tag => tag.name === row.status
                                ).length > 0
                                  ? colors.tagBackgroundColors.filter(
                                      tag => tag.name === row.status
                                    )[0].value
                                  : undefined
                              }
                            />
                          );
                        },
                      },
                      {
                        title: 'Loja Origen',
                        type: 'string',
                        display: 'notEmpty',
                        orderable: true,
                        props: ['store'],
                        cssTitle: {
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        },
                        cssProps: {
                          width: '15%',
                          paddingRight: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                      },
                      {
                        title: 'Loja Destino',
                        display: 'notEmpty',
                        type: 'string',
                        orderable: true,
                        props: ['store'],
                        cssTitle: {
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        },
                        cssProps: {
                          width: '15%',
                          paddingRight: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                      },
                      {
                        title: 'Nota Fiscal',
                        type: 'string',
                        orderable: true,
                        props: ['notaFiscal'],
                        cssTitle: {
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        },
                        cssProps: {
                          width: '15%',
                          paddingRight: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                      },
                      {
                        title: 'Serie',
                        type: 'string',
                        orderable: true,
                        props: ['serie'],
                        cssTitle: {
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        },
                        cssProps: {
                          width: '15%',
                          paddingRight: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                      },
                      {
                        title: 'Data Emissão',
                        type: 'string',
                        orderable: true,
                        props: ['dataIntegracao'],
                        cssTitle: {
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        },
                        cssProps: {
                          width: '15%',
                          paddingRight: '8px',
                          paddingLeft: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                        renderItem: row => {
                          return (
                            <>
                              {row.dataIntegracao ? (
                                <p className="render-string">
                                  {new Date(
                                    row.dataIntegracao
                                  ).toLocaleString()}
                                </p>
                              ) : (
                                <p className="render-string">-</p>
                              )}
                            </>
                          );
                        },
                      },
                      {
                        title: 'Qtd Volumes',
                        type: 'number',
                        display: 'notEmpty',
                        orderable: true,
                        props: ['quantidadeVolumes'],
                        cssTitle: {
                          justifyContent: 'flex-start',
                        },
                        cssProps: {
                          width: '5%',
                          paddingRight: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                      },
                      {
                        title: 'Qtd SKU',
                        type: 'string',
                        orderable: true,
                        props: ['quantidadeSku'],
                        cssTitle: {
                          justifyContent: 'flex-start',
                        },
                        cssProps: {
                          width: '5%',
                          paddingRight: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                      },
                      {
                        title: 'Qtd Produtos',
                        type: 'number',
                        orderable: true,
                        props: ['quantidade'],
                        cssTitle: {
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        },
                        cssProps: {
                          width: '5%',
                          paddingRight: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                      },
                      {
                        title: 'Data Rec.',
                        type: 'datetime',
                        orderable: true,
                        props: ['dataRecebimento'],
                        cssTitle: {
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        },
                        cssProps: {
                          width: '10%',
                          paddingRight: '8px',
                          paddingLeft: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                        renderItem: row => {
                          return (
                            <>
                              {row.dataRecebimento ? (
                                <p className="render-string">
                                  {new Date(
                                    row.dataRecebimento
                                  ).toLocaleString()}
                                </p>
                              ) : (
                                <p className="render-string">-</p>
                              )}
                            </>
                          );
                        },
                      },
                      {
                        title: 'Qtde SKU Rec.',
                        type: 'number',
                        orderable: true,
                        props: ['quantidadeSkuRecebido'],
                        cssTitle: {
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        },
                        cssProps: {
                          width: '2%',
                          paddingRight: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                      },
                      {
                        title: 'Qtde Produtos Rec.',
                        type: 'number',
                        orderable: true,
                        props: ['quantidadeRecebimento'],
                        cssTitle: {
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        },
                        cssProps: {
                          width: '2%',
                          paddingRight: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                      },

                      {
                        title: 'Ações',
                        type: undefined,
                        props: [''],
                        cssTitle: {
                          justifyContent: 'flex-start',
                          textAlign: 'left',
                        },
                        cssProps: {
                          width: '1%',
                          paddingRight: '8px',
                          paddingLeft: '8px',
                          fontFamily: 'sans-serif',
                        },
                        cssText: {
                          paddingLeft: '8px',
                        },
                        renderItem: actions => {
                          return (
                            <>
                              {actions.status === 'Divergente' ? (
                                <Tooltip title="Finalizar Recebimento">
                                  <Actions>
                                    <IconButton
                                      onClick={() => {
                                        receivementHas(actions.codigoPedido);
                                      }}
                                      aria-label="Editar"
                                    >
                                      <CheckRoundedIcon color="disabled" />
                                    </IconButton>
                                  </Actions>
                                </Tooltip>
                              ) : null}
                            </>
                          );
                        },
                      },
                    ]}
                    rows={[
                      ...receivement.map(item => ({
                        ...item,
                      })),
                    ]}
                    paginationOptions={[
                      {
                        label: 5,
                        value: 5,
                      },
                      {
                        label: 10,
                        value: 10,
                      },
                      {
                        label: 25,
                        value: 25,
                      },
                      {
                        label: 50,
                        value: 50,
                      },
                      {
                        label: 100,
                        value: 100,
                      },
                    ]}
                  />
                </TableContainer>
              </Fade>
            )}
          </>
        )}
        {!!detail && (
          <Detail
            datatable={detailPedido}
            dataDetail={details}
            onClickBack={handleTraceBack}
          />
        )}
      </Container>
    </>
  );
};

export default StoreReceivement;
