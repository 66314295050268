import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  box-shadow: none !important;
  /* SearchBox colors */

  & > * {
    max-width: 100%;
  }

  @media screen and (max-width: 500px) {
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .changeLogWrapper {
    height: 100%;
    background: ${props => props.theme.colors.dashboard_card_bg};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;

    font-family: 'Poppins';

    .changeLogHeader {
      display: flex;
      gap: 5px;
      align-items: center;

      padding: 24px 0 13px 28px;
      color: ${props => props.theme.colors.container_title};

      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
    }

    .changeLogContent {
      margin: 25px 0;

      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;

      a {
        margin-left: 5px;
        color: #0095e8;

        font-style: normal;
        font-weight: 500;
        font-size: 14px;

        &:hover {
          opacity: 0.7;
          transition: 0.5s;
        }
      }

      p {
        color: #6c7293;
      }

      .expanded {
        span:last-of-type {
          color: #0095e8;
        }
      }

      span:first-of-type {
        margin-left: 15px;

        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;

        color: ${props => props.theme.colors.container_title};
      }
    }

    .MuiDivider-root {
      height: 1px;
      background-color: ${props => props.theme.colors.line};
    }

    .MuiAccordion-root {
      margin: 10px 0;
      background-color: transparent;
      box-shadow: none;

      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    .MuiAccordionSummary-root {
      display: flex;
      min-height: 0;
      padding: 0 23px;
      align-items: center;
    }

    .MuiAccordionSummary-content {
      margin: 0;
    }

    .MuiAccordion-root:before {
      background-color: transparent;
    }

    .MuiAccordionDetails-root {
      display: flex;
      flex-direction: column;
      gap: 30px;
      padding: 16px 16px 16px 65px;

      .MuiListSubheader-root {
        font-family: 'Poppins';
        font-style: normal;
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;

        color: #6c7293;
      }

      .MuiListSubheader-gutters {
        padding: 0;
      }

      .MuiList-root {
        padding: 0;
      }

      .MuiListItem-root {
        padding: 0;
        font-family: 'Poppins';
        font-style: normal;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px;

        color: #6c7293;
      }

      .MuiListItem-gutters {
        padding: 0;
      }
    }
  }
`;
