/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import ChangeLogIcon from 'assets/svg/changelog.svg';
import { Link } from 'react-router-dom';
import Icon from '../Icon';
import { Container } from './styles';

export const ChangeLogMenu = () => {
  const { title } = useTheme();
  const [notify] = useState<Boolean>(false);

  // const handleStoreVersion = () => {
  //   localStorage.setItem('@pda::version', version);
  // };

  // useEffect(() => {
  //   const localStorageVersion = localStorage.getItem('@pda::version');

  //   getLogs()
  //     .then(({ data }) => {
  //       const slicedData = data.slice(0, 12);
  //       if (slicedData.length > 0) {
  //         setInitialLogVersion(slicedData[0].version);
  //       }
  //     })
  //     .finally(() => {
  //       if (localStorageVersion !== initialLogVersion) {
  //         setNotify(true);
  //       }
  //     });
  // }, [handleStoreVersion, initialLogVersion]);

  return (
    <Container notify={notify}>
      <Link to="/wms/changelog/">
        <Icon
          icon={ChangeLogIcon}
          color={title === 'light' ? '#6C7293' : '#FFFFFF'}
        />
        <div className="logNotification" />
      </Link>
    </Container>
  );
};
