/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  useEffect,
  useRef,
  InputHTMLAttributes,
  useCallback,
  useState,
} from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface inputErrorProps {
  inputName: string;
  message: string;
}

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: string;
  label?: string;
  iconError?: string;
  width?: number | string;
  handleInputError?(data: inputErrorProps): void;
  messageErrorOnBlur?: string;
  isDisabled?: boolean;
  isHidden?: boolean;
  fullWidth?: boolean;
  isInteger?: boolean;
}

const InputFile: React.FC<InputProps> = ({
  name,
  handleInputError,
  icon,
  label,
  iconError,
  messageErrorOnBlur,
  isDisabled,
  isHidden,
  fullWidth,
  ...rest
}) => {
  const inputRef = useRef(null);

  const [success, setSuccess] = useState(false);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'files[0]', // Path to access the file object
      clearValue: (ref: HTMLInputElement) => {
        ref.value = ''; // Clear file input value after form reset
      },
      setValue: (ref, value) => {
        ref.value = value;
        setSuccess(false);
        clearError();
      },
    });
  }, [fieldName, registerField, clearError]);

  const handleChange = useCallback(event => {
    const file = event.target.files[0]; // Get the first file
    // Do something with the file, like upload it or process it
    console.log(file);
  }, []);

  const handleBlur = useCallback(
    event => {
      if (!event.target.value) {
        if (!!handleInputError && !!messageErrorOnBlur) {
          handleInputError({
            inputName: name,
            message: messageErrorOnBlur,
          });
        }
      } else setSuccess(true);
    },
    [handleInputError, messageErrorOnBlur, name]
  );

  return (
    <Container error={!!error} success={success} fullWidth={fullWidth}>
      {isHidden ? (
        <input type="hidden" />
      ) : (
        <>
          {label ? (
            <div className="label">
              <label>{label}</label>
            </div>
          ) : null}
          <div className="input-wrapper">
            {rest.required && <span className="requiredIcon">*</span>}
            {/* @ts-ignore */}
            <input
              name={name}
              ref={inputRef}
              defaultValue={defaultValue}
              onBlur={handleBlur}
              disabled={isDisabled || rest.disabled || false}
              onFocus={() => {
                clearError();
                setSuccess(false);
              }}
              onKeyPress={() => {
                clearError();
              }}
              type="file"
              onChange={handleChange} // Handle file change
              {...rest}
            />
            {!!icon && (
              <div className="icon">
                <img src={error ? iconError || icon : icon} alt={name} />
              </div>
            )}
          </div>
          {!!error && messageErrorOnBlur && (
            <div className="error">
              <p>{error}</p>
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default InputFile;
