import React, { useState, createContext, useContext } from 'react';

interface SidebarContextProps {
  isFixed: boolean;
  fixSidebar: () => void;
  setIsFixedState: (value: boolean) => void;
}

export const SidebarContext = createContext({} as SidebarContextProps);

export const SidebarContextProvider: React.FC = ({ children }) => {
  const [isFixed, setIsFixed] = useState<boolean>(false);

  const fixSidebar = () => setIsFixed(prevState => !prevState);
  const setIsFixedState = (value: boolean) => setIsFixed(value);

  return (
    <SidebarContext.Provider
      value={{
        isFixed,
        fixSidebar,
        setIsFixedState,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export function useSidebar(): SidebarContextProps {
  const context = useContext(SidebarContext);

  if (!context)
    throw new Error('useSidebar deve ser usado dentro do SidebarProvider');

  return context;
}
