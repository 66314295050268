import styled from 'styled-components';

interface ChangeLogContainerProps {
  notify: Boolean;
}

export const Container = styled.div<ChangeLogContainerProps>`
  display: ${props => (props.notify ? 'flex' : 'none')};
  position: relative;
  align-items: center;
  justify-content: center;

  /* .logNotification {
    display: ${props => (props.notify ? 'initial' : 'none')};
    position: absolute;
    top: 0;
    right: 0px;

    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #d9214e;
  } */
`;
