/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Badge,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  Grow,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { FilterButtons, FilterContainer } from 'app/WMS/styles/styles';
import React, { useCallback, useRef, useState } from 'react';
import searchIcon from 'assets/svg/searchIcon.svg';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  DatePicker,
  Input,
  InputFile,
  Select,
  SwitchButton,
} from '../../Form';
import { InputProps } from '../index';

interface PopUpFilterProps {
  hiddenInputs?: InputProps[];
  inputs: InputProps[];
  handleSubmit?: (data?: any) => void;
  searchDisabled?: boolean;
  clearButtonTitle?: string;
  submitButtonTitle?: string;
}

const PopUpFilter: React.FC<PopUpFilterProps> = ({
  hiddenInputs,
  inputs,
  handleSubmit,
  searchDisabled,
}) => {
  const [visible, setVisible] = useState(false);
  const filterRef = useRef<HTMLDivElement>(null);
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const { t } = useTranslation();

  const switchType = (input: InputProps) => {
    switch (input.type) {
      case 'text':
        return (
          <Input
            id={input.name}
            name={input.name}
            form="formFilterDetails"
            key={input.name.toString()}
            type={input.type || 'text'}
            isDisabled={input.isDisabled}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            onChange={(e: any) => input.onChangeEvent && input.onChangeEvent(e)}
            required={input.isRequired}
            // iconError={IconError}
            // iconSuccess={IconSuccess}
          />
        );
      case 'file':
        return (
          <InputFile
            id={input.name}
            name={input.name}
            type="file"
            key={input.name.toString()}
            isDisabled={input.isDisabled}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            defaultValue={input.defaultValue}
          />
        );
      case 'select':
        return (
          <Select
            name={input.name}
            key={input.name.toString()}
            form="formFilterDetails"
            options={input.options}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            isLoading={input.isLoading}
            isDisabled={input.isDisabled}
            styles={input.styles}
            onChangeEvent={input.onChangeEvent}
            isApiSearch={input.isApiSearch}
            required={input.isRequired}
            multiSelect={input.isMulti}
          />
        );
      case 'date':
        return (
          <DatePicker
            id={input.name}
            form="formFilterDetails"
            name={input.name}
            type="date"
            key={input.name.toString()}
            isDisabled={input.isDisabled}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            required={input.isRequired}
            dateMin={input.minDate}
            onChange={(e: any) => input.onChangeEvent && input.onChangeEvent(e)}
          />
        );
      case 'switch':
        return (
          <SwitchButton
            id={input.name}
            form="formFilterDetails"
            name={input.name}
            key={input.name.toString()}
            type="checkbox"
            placeholder={input.placeholder}
            required={input.isRequired}
          />
        );
      case 'checkbox':
        return (
          <Checkbox
            id={input.name}
            name={input.name}
            form="formFilterDetails"
            type="checkbox"
            key={input.name.toString()}
            label={input.labelCheckbox}
            placeholder={input.placeholder}
            onClickEvent={input.onClickEvent}
            required={input.isRequired}
          />
        );
      default:
        return (
          <Input
            id={input.name}
            form="formFilterDetails"
            name={input.name}
            key={input.name.toString()}
            type={input.type || 'text'}
            isDisabled={input.isDisabled}
            placeholder={input.placeholder}
            handleInputError={handleInputError}
            required={input.isRequired}
            onChange={input.onChange}
            // iconError={IconError}
            // iconSuccess={IconSuccess}
          />
        );
    }
  };

  const handleClickOutside = (e: any) => {
    if (filterRef?.current?.contains(e.target)) {
      return;
    }
    setVisible(false);
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleFormSubmit = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    const data = formRef.current?.getData();
    if (handleSubmit) {
      handleSubmit(data);
    }
  };

  const handleInputError = useCallback(
    ({ inputName, message }) => {
      if (formRef) {
        formRef.current?.setFieldError(inputName, message);
      }
    },
    [formRef]
  );

  const handleShowAdvancedSearch = () => {
    setAdvancedSearch(!advancedSearch);
  };

  const clearFields = useCallback(() => {
    inputs.map(input => formRef.current?.setFieldValue(input.name, ''));
    hiddenInputs?.map(input => formRef.current?.setFieldValue(input.name, ''));
  }, [inputs, formRef, hiddenInputs]);

  return (
    <>
      {/* @ts-ignore */}
      <Form onSubmit={handleSubmit} ref={formRef} id="formFilterDetails">
        <div className="filter-row" ref={filterRef}>
          <div className="advancedContent">
            <Tooltip title="Busca avançada" className="filter-Btn-Create">
              <IconButton
                className="primaryButton filter filter-id filterButton"
                style={{
                  padding: '10px',
                  borderRadius: '6px',
                }}
                onClick={() => setVisible(prevState => !prevState)}
                aria-label="Filtro"
              >
                <svg
                  style={{ marginRight: '7px' }}
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.986 1.33333L8 9.69533V13.6447L7.33333 13.2447V9.69533L2.34733 1.33333H12.986ZM15.3333 0H0L6 10.0627V14L9.33333 16V10.0627L15.3333 0Z"
                    fill="#46BE7D"
                  />
                </svg>

                <p>{t('components.search.filter')}</p>
              </IconButton>
            </Tooltip>
          </div>

          <Grow in={visible}>
            <div id="filterBox" className="filter-box filter-search">
              <div className="filter-header">
                <h1>{t('screens.commom.details')}</h1>
                <CloseOutlinedIcon
                  style={{ height: '21px', cursor: 'pointer' }}
                  onClick={() => setVisible(false)}
                />
              </div>
              <Grid
                style={{
                  padding: '1.5rem 1rem',
                  alignItems: 'self-end',
                }}
                container
                spacing={3}
                className="filter-content"
              >
                {inputs?.map(
                  (
                    {
                      name,
                      label,
                      type = 'text',
                      options,
                      placeholder,
                      width = 6,
                      sm = 12,
                      xl = width,
                      lg = width,
                      xs = 12,
                      md = 12,
                      isRequired,
                      isLoading,
                      isDisabled,
                      styles,
                      onClickEvent,
                      labelCheckbox,
                      onChangeEvent,
                      isMulti,
                      ...rest
                    },
                    index
                  ) => (
                    <Grid
                      item
                      xl={xl}
                      lg={lg}
                      xs={xs}
                      md={md}
                      sm={sm}
                      key={index}
                    >
                      {!(label == null) && (isRequired ?? false) ? (
                        <Badge badgeContent="*" overlap="rectangular">
                          <p className="labelInput">{label}</p>
                        </Badge>
                      ) : (
                        <p className="labelInput">{label}</p>
                      )}
                      {switchType({
                        name,
                        label,
                        type,
                        options,
                        placeholder,
                        width,
                        sm,
                        xl,
                        lg,
                        xs,
                        md,
                        isRequired,
                        isLoading,
                        isDisabled,
                        styles,
                        onClickEvent,
                        labelCheckbox,
                        onChangeEvent,
                        isMulti,
                        ...rest,
                      })}
                    </Grid>
                  )
                )}
              </Grid>
              <div
                style={{
                  width: '100%',
                }}
              >
                <Collapse in={advancedSearch} timeout={800}>
                  <Grid
                    style={{
                      padding: '0 0 1.5rem 1.5rem',
                      alignItems: 'self-end',
                    }}
                    container
                    spacing={3}
                    className="filter-content"
                  >
                    {hiddenInputs?.map(
                      (
                        {
                          name,
                          label,
                          type = 'text',
                          options,
                          placeholder,
                          width = 6,
                          sm = 12,
                          xl = width,
                          lg = width,
                          xs = 12,
                          md = 12,
                          isRequired,
                          isLoading,
                          isDisabled,
                          styles,
                          onClickEvent,
                          labelCheckbox,
                          onChangeEvent,
                          isMulti,
                          ...rest
                        },
                        index
                      ) => (
                        <Grid
                          item
                          xl={xl}
                          lg={lg}
                          xs={xs}
                          md={md}
                          sm={sm}
                          key={index}
                        >
                          {!(label == null) && (isRequired ?? false) ? (
                            <Badge badgeContent="*" overlap="rectangular">
                              <p className="labelInput">{label}</p>
                            </Badge>
                          ) : (
                            <p className="labelInput">{label}</p>
                          )}
                          {switchType({
                            name,
                            label,
                            type,
                            options,
                            placeholder,
                            width,
                            sm,
                            xl,
                            lg,
                            xs,
                            md,
                            isRequired,
                            isLoading,
                            isDisabled,
                            styles,
                            onClickEvent,
                            labelCheckbox,
                            onChangeEvent,
                            isMulti,
                            ...rest,
                          })}
                        </Grid>
                      )
                    )}
                  </Grid>
                </Collapse>
              </div>
              <FilterContainer>
                <FilterButtons>
                  <Button
                    className="clearButton"
                    onClick={clearFields}
                    style={{ padding: '8px 10x', maxWidth: '120px' }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.77467 15.3334L0.195333 11.7567C0.0653334 11.6267 0 11.4567 0 11.2854C0 11.1147 0.0653334 10.944 0.195333 10.814L10.148 0.86202C10.278 0.732687 10.4487 0.666687 10.6193 0.666687C10.7893 0.666687 10.9607 0.732687 11.0907 0.86202L15.8047 5.57735C15.9353 5.70735 16 5.87802 16 6.04935C16 6.22002 15.9353 6.39002 15.8047 6.52069L8.32733 14H12.0033V15.3334H3.77467ZM6.21267 14L2.55533 10.3427L1.60933 11.2854L4.32667 14H6.21267ZM10.616 2.27935L3.498 9.39935L7.27 13.172L14.3873 6.05069L10.616 2.27935Z"
                        fill="#D9214E"
                      />
                    </svg>
                    <p>{t('components.search.clear')}</p>
                  </Button>
                  {hiddenInputs !== undefined &&
                    (advancedSearch === true ? (
                      <Button
                        className="closeFilterButton"
                        onClick={() => handleShowAdvancedSearch()}
                      >
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.986 1.33333L8 9.69533V13.6447L7.33333 13.2447V9.69533L2.34733 1.33333H12.986ZM15.3333 0H0L6 10.0627V14L9.33333 16V10.0627L15.3333 0Z"
                            fill="#F69B11"
                          />
                        </svg>
                        <p>{t('components.search.closeAdvancedFilters')}</p>
                      </Button>
                    ) : (
                      <Button
                        className="advancedFilterButton"
                        onClick={() => handleShowAdvancedSearch()}
                      >
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.986 1.33333L8 9.69533V13.6447L7.33333 13.2447V9.69533L2.34733 1.33333H12.986ZM15.3333 0H0L6 10.0627V14L9.33333 16V10.0627L15.3333 0Z"
                            fill="#46BE7D"
                          />
                        </svg>
                        <p>{t('components.search.advancedFilters')}</p>
                      </Button>
                    ))}
                  <Button
                    className="searchButton"
                    variant="contained"
                    disabled={searchDisabled}
                    onClick={e => {
                      handleFormSubmit(e);
                      setVisible(false);
                    }}
                    style={{ maxWidth: '120px', color: '#FFFFF' }}
                  >
                    {searchDisabled ? (
                      <CircularProgress
                        style={{
                          width: '16px',
                          height: '16px',
                          color: '#0095E8',
                        }}
                      />
                    ) : (
                      <img src={searchIcon} alt="searchIcon" />
                    )}
                    <p>
                      {searchDisabled
                        ? t('components.search.loading')
                        : t('components.search.title')}
                    </p>
                  </Button>
                </FilterButtons>
              </FilterContainer>
            </div>
          </Grow>
        </div>
      </Form>
    </>
  );
};

export default PopUpFilter;
