import React from 'react';

import { IconContainer } from './styles';

interface IIconProps {
  color?: string;
  hoverColor?: string;
  rotate?: string;
  icon: string;
}

const Icon: React.FC<IIconProps> = ({ color, hoverColor, icon, rotate }) => (
  <IconContainer
    color={color}
    icon={icon}
    hoverColor={hoverColor}
    rotate={rotate}
  />
);
export default Icon;
