import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
		border: 0;
    box-sizing: border-box;
  }

  .MuiTabs-indicator {
    background-color: ${props => props.theme.colors.primary} !important;
  }

  .MuiTab-textColorPrimary.Mui-selected {
    color: ${props => props.theme.colors.primary} !important;
  }

  :root {
    @media (max-width: 1080px){
    html {
        font-size: 93.75%; //15px --> 93,75%=15px/16px; 1rem = 16px, 
    }


}

@media (max-width: 720px){
    html {
        font-size: 87.5%; //14px
    }
}
  }

  body {
    -webkit-font-smoothing: antialiased;
    background: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
  }

  body, input, button {
    font: 16px 'Poppins', sans-serif;
  }

  button {
    cursor: pointer;
  }

	a {
		text-decoration: none;
	}

	ul {
		list-style: none;
	}

  .name {
    display: flex;
    align-items: center;

    .avatar {
      background: #e6e6e6 !important;
      color: #606060 !important;
    }

    p {
      margin-left: 12px;
      font-size: 12px;
    }
  }

  #page-report {
    display: none;
  }

  #content-report-geral{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 595px;
    padding: 1rem;

    .content-report {
      width: 100%;
      height: 841px;
      font-size: 9px;
      margin: 0 auto;
      position: relative;

      .headReport {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .titleReport {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          gap: 5px;

          h1 {
          font-size: 12px;
          color: #262626;
          font-weight: 500;
          
          text-transform: uppercase;
          }
        }

        .printDate {
          color: #262626;
          text-align: right;
          p:last-child {
            text-align: right;
          }
        }
      }

      .volume {
        text-align: center;
        width: 100%;
        color: #262626;
        margin-top: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;

        p:first-child {
          margin-right: 0.5rem;
        }
      }

      .table-box {
        width: 100%;
        margin: 0 auto;
        margin-top: 1.5rem;

        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;

        thead{
          tr {
            th {
              padding: 6px 4px;
              text-align: left;
              background-color: #606060;
              color: #ffff;
            }
          }
        }

        tbody {
          tr {
            td
              {
              padding: 4px;
              color: #262626;
              border-bottom: 1px dotted #808080; 
            }
          }
        }
      }
      
      .content-report-footer {
        display: flex;
        margin-top: 100px;
        align-items: flex-start;
        gap: 2rem;

        .content-report-footer-item {
          border-top: 1.5px solid #262626;
          height: 54px;
          width: 100%;
          p{
            text-align:center;
            color: #1e1e2d;
            font-weight: 600;
            margin-top: 0.7rem;
          }
        }
      }
    }
  }


    

  .logoutButton {
    margin-left: 16px !important;    
    color: ${props => props.theme.colors.textLogoutButton} !important;
    box-shadow: none !important;
    font-weight: normal !important;
    font-size: 12px !important;
    text-transform: none !important;

    svg {
      fill: ${props => props.theme.colors.iconLogoutButton} !important;
      color: ${props => props.theme.colors.iconLogoutButton} !important;
      stroke: ${props => props.theme.colors.iconLogoutButton} !important;
    }
  }

  .containerInputInTable {
    width: 100%;
    height: 35px;
    padding: 0;
    display: flex;
    border-radius: 5px;
    background: ${props => props.theme.colors.input};
    border: 0px solid ${props => props.theme.colors.borderInput};

    .inputInTable {
      border: 1px solid ${props => props.theme.colors.borderInput};
      flex: 1;
      height: 100%;
      line-height: 64px;
      border-radius: 5px;
      background: ${props => props.theme.colors.input};
      padding-left: 10px;
      padding-right: 10px;
      font-size: 13px;
      text-transform: uppercase;
      overflow: hidden;
      color: ${props => props.theme.colors.placeholderSelected};
    }
  }

  .container {
    max-width: 1260px;
    height: 100%;
    margin: 0 auto;
  }

  ::-webkit-scrollbar {
    width: 0.4vw;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${props =>
      props.theme.title === 'light' ? '#A1A5B7' : '#6D6D80'};
    border-radius: 10px;
  }
  /* ::-webkit-scrollbar-thumb:hover {
    background: #EEF0F8;
  } */
  ::-webkit-scrollbar-track {
    background: ${props =>
      props.theme.title === 'light' ? '#E4E5EF' : '#323248'};
    border-radius: 5px;
    /* box-shadow: inset 7px 10px 12px 0px #f0f0f0; */
  }
`;
