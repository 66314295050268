import React, { useState } from 'react';
import { Table } from 'components/Tools/Table';
import { Fade } from '@material-ui/core';
import { TableContainer } from 'app/WMS/styles/styles';
import TagStatus from 'components/Tools/TagStatus';
import {
  IntegrationDataProps,
  IntegrationTableProps,
} from 'features/Integration/types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const IntegrationTable = ({
  integrationList,
}: IntegrationTableProps) => {
  const [animate] = useState(true);

  const { t } = useTranslation();

  const formatStatus = (status: string) => {
    switch (status) {
      case 'ERROR':
        return 'Erro';
      case 'STOPPED':
        return 'Parado';
      case 'RUNNING':
        return 'Executando';
      default:
        return '';
    }
  };

  return (
    <Fade in={animate} timeout={1000}>
      <TableContainer className="customTableContainer">
        <Table<IntegrationDataProps>
          rows={integrationList}
          columns={[
            {
              title: String(t('screens.integration.process')),
              orderable: true,
              type: 'string',
              props: ['name'],
              cssProps: {
                width: '5%',
              },
              cssTitle: {
                width: '1%',
              },
            },
            {
              title: String(t('screens.receivement.status')),
              type: 'string',
              orderable: true,
              props: ['status'],
              renderItem: row => (
                <div className="statusContainer">
                  <TagStatus
                    status={formatStatus(row.status)}
                    width="fit-content"
                  />
                </div>
              ),
            },
            {
              title: String(t('screens.integration.last_sync_at')),
              type: 'datetime',
              orderable: true,
              props: ['last_sync_at'],
              cssProps: {
                width: '5%',
              },
              renderItem: row => (
                <div className="integrationDateTime">
                  <p>
                    {row.last_sync_at !== null
                      ? moment(row.last_sync_at.slice(0, -1)).format(
                          'DD/MM/YYYY HH:mm:ss'
                        )
                      : '-'}
                  </p>
                </div>
              ),
            },
            {
              title: String(t('screens.integration.last_sync_status')),
              type: 'datetime',
              orderable: true,
              props: ['last_sync_status'],
              cssProps: {
                width: '5%',
              },
              renderItem: row => (
                <div className="integrationDateTime">
                  <p>
                    {row.last_sync_status !== null
                      ? moment(row.last_sync_status.slice(0, -1)).format(
                          'DD/MM/YYYY HH:mm:ss'
                        )
                      : '-'}
                  </p>
                </div>
              ),
            },
            {
              title: String(t('screens.integration.next_sync_at')),
              type: 'datetime',
              orderable: true,
              props: ['next_sync_at'],
              cssProps: {
                width: '5%',
              },
              renderItem: row => (
                <div className="integrationDateTime">
                  <p>
                    {row.next_sync_at !== null
                      ? moment(row.next_sync_at.slice(0, -1)).format(
                          'DD/MM/YYYY HH:mm:ss'
                        )
                      : '-'}
                  </p>
                </div>
              ),
            },
          ]}
          defaultNumberOfRows={10}
        />
      </TableContainer>
    </Fade>
  );
};
