import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-top: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
  box-shadow: none !important;
  /* SearchBox colors */

  & > * {
    max-width: 100%;
  }

  .integrationWrapper {
    height: 100%;
    background: ${props => props.theme.colors.dashboard_card_bg};
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;

    font-family: 'Poppins';

    .integrationHeader {
      display: flex;
      gap: 5px;
      align-items: center;

      padding: 24px 0 13px 28px;
      color: ${props => props.theme.colors.container_title};

      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
    }

    .integrationContent {
      margin: 1.5rem;

      .integrationDateTime {
        display: flex;
        justify-content: center;
        align-items: center;

        p {
          margin-left: 10px;
        }
      }

      .customFilterCardContainer {
        padding: 0 !important;
      }

      .customTableContainer {
        padding: 1rem 0 !important;
      }
    }

    .integrationSubHeader {
      margin-bottom: 0.6rem;

      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;

      color: #6c7293;
    }
  }
`;
