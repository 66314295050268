import React, { useState } from 'react';
import { Fade } from '@material-ui/core';

import { TableContainer } from 'app/WMS/styles/styles';

import { Table } from 'components/Tools/Table';

import SearchIconDetails from 'assets/svg/searchIconDetails.svg';

import { useTranslation } from 'react-i18next';
import {
  FollowWaveData,
  FollowWaveTableProps,
} from 'features/FollowWave/types';
import ProgressBar from 'components/Tools/ProgressBar';
import TagStatus from 'components/Tools/TagStatus';
import CustomizedImgCell from 'features/Receivement/components/CustomizedImgCell';

const FollowWaveTable: React.FC<FollowWaveTableProps> = ({ followWave }) => {
  const [animate] = useState(true);

  const { t } = useTranslation();
  return (
    <>
      <Fade in={animate} timeout={1000}>
        <TableContainer>
          <Table<FollowWaveData>
            exportList
            rows={followWave}
            columns={[
              {
                title: String(t('screens.reverseOrder.wave')),
                orderable: true,
                type: 'string',
                props: [''],
                cssProps: {
                  width: '5%',
                },
                renderItem: row => (
                  <div className="code-item">
                    <CustomizedImgCell status={row.status} />
                    <p style={{ marginLeft: '10px' }}>{row.onda}</p>
                  </div>
                ),
              },
              {
                title: String(t('screens.followWave.waveDate')),
                props: ['data'],
                type: 'datetime',
                orderable: true,
              },
              {
                title: String(t('screens.followWave.amountOrder')),
                props: ['quantidadePedidos'],
                type: 'stringCenter',
                orderable: true,
              },
              {
                title: String(t('screens.followWave.amountProduct')),
                props: ['quantidadeProdutos'],
                type: 'stringCenter',
                orderable: true,
                display: 'notEmpty',
              },
              {
                title: String(t('screens.receivement.status')),
                type: 'string',
                orderable: true,
                props: ['status'],
                renderItem: row => (
                  <div className="statusContainer">
                    <TagStatus status={row.status} width="fit-content" />
                  </div>
                ),
              },
              {
                title: String(t('screens.receivement.progress')),
                orderable: false,
                type: 'number',
                props: ['porcentagem'],
                formatter: row => `${row.porcentagem}%`,
                cssProps: {
                  width: '10%',
                },
                renderItem: row => (
                  <div className="">
                    <ProgressBar
                      style={{
                        width: '100%',
                        display: 'inline-block',
                      }}
                      value={row.porcentagem || 0}
                    />
                  </div>
                ),
              },
              {
                title: String(t('screens.commom.user')),
                props: ['usuario'],
                type: 'stringCenter',
                orderable: true,
              },
              {
                title: String(t('screens.receivement.actions')),
                orderable: false,
                type: 'string',
                cssProps: {
                  width: '7%',
                },
                props: [''],
                renderItem: row => (
                  <div className="row-actions-button-detail">
                    <button
                      type="button"
                      className="action"
                      onClick={() => console.log(row)}
                    >
                      <img
                        src={SearchIconDetails}
                        alt="Detalhes"
                        style={{ width: '30px' }}
                      />
                      <p className="hover-item">Detalhe</p>
                    </button>
                  </div>
                ),
              },
            ]}
            defaultNumberOfRows={10}
          />
        </TableContainer>
      </Fade>
    </>
  );
};

export default FollowWaveTable;
