import React from 'react';

import userIcon from 'assets/svg/userIcon.svg';

export const Logo: React.FC = () => (
  <svg
    width="122"
    height="34"
    viewBox="0 0 122 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.5 2H13C16 2.33333 22 4.8 22 12C22 19.2 16 21.6667 13 22H3C2.72386 22 2.5 22.2239 2.5 22.5V32"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M38 2.5H29C28.7239 2.5 28.5 2.72386 28.5 3V31C28.5 31.2761 28.7239 31.5 29 31.5H38C42.5 31.3333 51.5 28.3 51.5 17.5C51.5 6.7 42.5 3 38 2.5Z"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M81 27.2632L68.9385 2.88622C68.7567 2.5188 68.2343 2.51425 68.0461 2.87845L53.3769 31.2705C53.205 31.6033 53.4465 32 53.8211 32H68"
      stroke="white"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M93 2C95.8499 2.44222 99.4095 3.18865 103 4.63644C111.588 8.09953 120.353 15.5755 120 32.5"
      stroke="#E12325"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <circle cx="91.5" cy="29.6" r="3" fill="#E12325" />
    <path
      d="M91 19.5C94.6667 19.8333 102 22.7 102 31.5"
      stroke="#E12325"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M92 10C98.4969 10.1705 111.391 14.8093 110.991 32"
      stroke="#E12325"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
  </svg>
);

export const ArrowLogout: React.FC = () => (
  <div className="arrow">
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 512 512"
      color="#D9214E"
      height="20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M312 372c-7.7 0-14 6.3-14 14 0 9.9-8.1 18-18 18H94c-9.9 0-18-8.1-18-18V126c0-9.9 8.1-18 18-18h186c9.9 0 18 8.1 18 18 0 7.7 6.3 14 14 14s14-6.3 14-14c0-25.4-20.6-46-46-46H94c-25.4 0-46 20.6-46 46v260c0 25.4 20.6 46 46 46h186c25.4 0 46-20.6 46-46 0-7.7-6.3-14-14-14z" />
      <path d="M372.9 158.1c-2.6-2.6-6.1-4.1-9.9-4.1-3.7 0-7.3 1.4-9.9 4.1-5.5 5.5-5.5 14.3 0 19.8l65.2 64.2H162c-7.7 0-14 6.3-14 14s6.3 14 14 14h256.6L355 334.2c-5.4 5.4-5.4 14.3 0 19.8l.1.1c2.7 2.5 6.2 3.9 9.8 3.9 3.8 0 7.3-1.4 9.9-4.1l82.6-82.4c4.3-4.3 6.5-9.3 6.5-14.7 0-5.3-2.3-10.3-6.5-14.5l-84.5-84.2z" />
    </svg>
  </div>
);

export const UserIcon: React.FC = () => (
  <div className="userBg">
    <img src={userIcon} alt="Usuário" />
  </div>
);
