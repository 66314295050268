/* eslint-disable no-case-declarations */
import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListSubheader,
} from '@material-ui/core';
import { LogDataProps } from 'features/ChangeLog';
import parse from 'html-react-parser';
import Icon from 'components/Tools/Icon';
import moment from 'moment';
import BackArrow from 'assets/svg/backArrow.svg';
import BulletPoint from 'assets/svg/bulletPoint.svg';

interface ChangeLogAccordionProps {
  logData: LogDataProps;
  expanded: boolean;
  handleExpand: () => void;
}

export const ChangeLogAccordion = ({
  logData,
  expanded,
  handleExpand,
}: ChangeLogAccordionProps) => {
  const formatDate = (rawDate: string) => {
    const formattedDate = moment(rawDate.slice(0, -1)).format('DD/MM/YYYY');
    return formattedDate;
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpand}
      className={expanded ? 'expanded' : ''}
    >
      <AccordionSummary>
        <Icon
          icon={BackArrow}
          rotate={expanded ? 'rotate(270deg)' : 'rotate(180deg)'}
          color={expanded ? '#0095E8' : '#959CB6'}
        />
        <p>
          <span>WMS | </span>
          <span>{`ver ${logData.version}`}</span>
          {` - ${formatDate(logData.released_at)}`}
        </p>
      </AccordionSummary>
      <AccordionDetails>
        {logData?.changes.filter((fix: any) => fix.type === 'NEW').length !==
          0 && (
          <List>
            <ListSubheader>Novo:</ListSubheader>
            {logData.changes.map((update: any) => {
              if (update.type === 'NEW') {
                return update.descriptions.map((item: any) => {
                  return (
                    <ListItem>
                      <img src={BulletPoint} alt="" style={{ width: '25px' }} />
                      {parse(item.description)}
                    </ListItem>
                  );
                });
              }
              return undefined;
            })}
          </List>
        )}
        {logData?.changes.filter((fix: any) => fix.type === 'UPDATE').length !==
          0 && (
          <List>
            <ListSubheader>Updates:</ListSubheader>
            {logData.changes.map((update: any) => {
              if (update.type === 'UPDATE') {
                return update.descriptions.map((item: any) => {
                  return (
                    <ListItem>
                      <img src={BulletPoint} alt="" style={{ width: '25px' }} />
                      {parse(item.description)}
                    </ListItem>
                  );
                });
              }
              return undefined;
            })}
          </List>
        )}
        {logData?.changes.filter((fix: any) => fix.type === 'FIX').length !==
          0 && (
          <List>
            <ListSubheader>Fixes:</ListSubheader>
            {logData.changes.map((fix: any) => {
              if (fix.type === 'FIX') {
                return fix.descriptions.map((item: any) => {
                  return (
                    <ListItem>
                      <img src={BulletPoint} alt="" style={{ width: '25px' }} />
                      {parse(item.description)}
                    </ListItem>
                  );
                });
              }
              return undefined;
            })}
          </List>
        )}
        {logData?.changes.filter((fix: any) => fix.type === 'OTHER').length !==
          0 && (
          <List>
            <ListSubheader>Outros:</ListSubheader>
            {logData.changes.map((other: any) => {
              if (other.type === 'OTHER') {
                return other.descriptions.map((item: any) => {
                  return (
                    <ListItem>
                      <img src={BulletPoint} alt="" style={{ width: '25px' }} />
                      {parse(item.description)}
                    </ListItem>
                  );
                });
              }
              return undefined;
            })}
          </List>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
