/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';

import { Grid, Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { Skeleton } from '@material-ui/lab';
import { AxiosError, AxiosResponse } from 'axios';
import { Table, SearchBox } from '@pdasolucoes/web';
import { getCookie } from 'utils/cookies';
import { PalletsData } from '../../../../../utils/interfaces/Log';
import FixedHeader from '../../../../../components/Tools/Breadcrumb';
import { Container, Search, TableContent } from '../../../styles/styles';
import api from '../../../../../services/apiData';
import { useAuth } from '../../../../../hooks/Auth';

const Pallet: React.FC = () => {
  const [pallets, setPallets] = React.useState([] as PalletsData[]);

  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  const [animate, setAnimate] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { token } = useAuth();
  const idCliente = getCookie('@pdamodules::codigoCliente');

  const idUser = getCookie('@pdamodules::id');
  const tokenNoBar = token?.replaceAll('\\', '');
  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  const date = new Date(Date.now() - tzoffset).toISOString().split('T')[0];
  const tokenData = tokenNoBar?.replace(/"/g, '');

  React.useEffect(() => {
    setAnimate(true);
  }, []);

  const handleSubmit = React.useCallback(
    async data => {
      try {
        setLoading(false);
        setVisible(true);

        const dataFilter = {
          CodigoCliente: Number(idCliente),
          user: Number(idUser),
          badRequest: null,
          // cliente: null,
          Palete: data.palete || null,
          Caixa: data.caixa || null,
          Produto: data.produto || null,
          Ean: data.ean || null,
          Usuario: data.usuario || null,
          dataInicio: data.dataInicio
            ? new Date(data.dataInicio).toISOString()
            : null,
          dataFim: data.dataFim ? new Date(data.dataFim).toISOString() : null,
        };

        await api
          .patch<PalletsData>(
            `Armazenagem/MontagemPalete`,
            { ...dataFilter },
            {
              headers: { Authorization: `Bearer ${tokenData}` },
            }
          )
          .then((palletData: AxiosResponse) => {
            if (palletData.data.length === 0) {
              setPallets([]);
              enqueueSnackbar('Não foi encontrado nenhum registro.', {
                variant: 'error',
              });
            } else if (palletData.data.length > 0) {
              enqueueSnackbar(
                `Foi encontrado ${palletData.data.length} registro(s)!`,
                {
                  variant: 'success',
                }
              );
              setPallets(palletData.data);
            }
          })
          .catch((palletDataError: AxiosError<any>) => {
            if (palletDataError.response!.status === 401 && tokenData) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              palletDataError.response!.data.erros.map((item: any) => {
                enqueueSnackbar(item.mensagem, { variant: 'error' });
              });
            }
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        const timer = setTimeout(() => {
          setLoading(true);
          setVisible(false);

          return () => {
            clearTimeout(timer);
          };
        }, 1000);
      }
    },
    [enqueueSnackbar, tokenData, idCliente, idUser]
  );

  return (
    <>
      <FixedHeader title="Relatórios" subTitle="Montagem Palete" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              handleSubmit={handleSubmit}
              inputs={[
                {
                  name: 'palete',
                  placeholder: 'Palete',
                  type: 'text',
                  label: 'Palete',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 4,
                  sm: 12,
                },
                {
                  name: 'caixa',
                  placeholder: 'Caixa',
                  type: 'text',
                  label: 'Caixa',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 4,
                  sm: 12,
                },
                {
                  name: 'dataInicio',
                  label: 'Data Inicial',
                  type: 'date',
                  defaultValue: date,
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 4,
                  sm: 6,
                },
                {
                  name: 'dataFim',
                  label: 'Data Final',
                  type: 'date',
                  defaultValue: date,
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 4,
                  sm: 6,
                },
              ]}
              advancedSearch
              hiddenInputs={[
                {
                  name: 'produto',
                  placeholder: 'Produto',
                  type: 'text',
                  label: 'Produto',
                  xl: 12,
                  lg: 12,
                  xs: 12,
                  md: 12,
                  sm: 12,
                },
                {
                  name: 'ean',
                  placeholder: 'EAN',
                  type: 'text',
                  label: 'EAN',
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'usuario',
                  placeholder: 'Usuário',
                  type: 'text',
                  label: 'Usuário',
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
            />
          </Search>
        </Fade>
        {visible && (
          <>
            {!loading && (
              <Fade in={animate} unmountOnExit timeout={1000}>
                <div className="wrapper" style={{ display: 'flex' }}>
                  <div className="wrap" style={{ height: 410, flexGrow: 1 }}>
                    <Skeleton
                      style={{ borderRadius: '5px' }}
                      variant="rect"
                      height={400}
                    />
                  </div>
                </div>
              </Fade>
            )}
          </>
        )}
        {loading && (
          <Grid container>
            <Fade in={animate} unmountOnExit timeout={1000}>
              <div className="wrapper" style={{ display: 'flex' }}>
                <div className="wrap" style={{ flexGrow: 1 }}>
                  <TableContent>
                    <Table<PalletsData>
                      exportList
                      columns={[
                        {
                          title: 'Endereço',
                          type: 'string',
                          orderable: true,
                          props: ['endereco'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Caixa',
                          type: 'string',
                          orderable: true,
                          props: ['caixa'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                            textAlign: 'left',
                          },
                        },
                        {
                          title: 'Paletes',
                          type: 'string',
                          orderable: true,
                          props: ['palete'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                            textAlign: 'left',
                          },
                        },
                        {
                          title: 'Produto',
                          type: 'string',
                          orderable: true,
                          props: ['produto'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                            textAlign: 'left',
                          },
                        },
                        {
                          title: 'Descrição',
                          type: 'string',
                          orderable: true,
                          props: ['descricaoProduto'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '10%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Quantidade',
                          type: 'number',
                          orderable: true,
                          props: ['quantidade'],
                          cssTitle: {
                            justifyContent: 'start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '2%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Usuário',
                          type: 'string',
                          orderable: true,
                          props: ['usuario'],
                          cssTitle: {
                            justifyContent: 'start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '3%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Montagem',
                          type: 'string',
                          orderable: true,
                          props: ['montagem'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                          renderItem: row => {
                            return (
                              <>
                                {row.montagem ? (
                                  <p className="render-string">
                                    {new Date(row.montagem).toLocaleString()}
                                  </p>
                                ) : (
                                  <p className="render-string">-</p>
                                )}
                              </>
                            );
                          },
                        },
                      ]}
                      rows={[
                        ...pallets.map(item => ({
                          ...item,
                        })),
                      ]}
                    />
                  </TableContent>
                </div>
              </div>
            </Fade>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default Pallet;
