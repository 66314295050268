/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';

import { Grid, Fade, IconButton } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

import { Skeleton } from '@material-ui/lab';
import { AxiosError, AxiosResponse } from 'axios';
import { Table, SearchBox } from '@pdasolucoes/web';
import { useParams, useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { ICompositionProduct } from '../../../../../utils/interfaces/Composition';
import getValidationErrors from '../../../../../utils/getValidationErrors';
import FixedHeader from '../../../../../components/Tools/Breadcrumb';
import {
  Container,
  Search,
  TableContainer,
  Actions,
} from '../../../styles/styles';
import api from '../../../../../services/apiData';
import { useAuth } from '../../../../../hooks/Auth';
import { IComponents } from '../../../../../utils/interfaces/Components';
import Modal from '../../../../../components/Tools/Modal';

const CreateComposition: React.FC = () => {
  const [createComposition, setCreateComposition] = React.useState(
    [] as IComponents[]
  );
  const [loading, setLoading] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const [animate, setAnimate] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { token } = useAuth();
  const idCliente = sessionStorage
    .getItem('@pdamodules::codigoCliente')
    ?.replace(/"/g, '');
  const location = useHistory();
  const tokenNoBar = token?.replaceAll('\\', '');
  const tokenData = tokenNoBar?.replace(/"/g, '');
  const { id } = useParams<Record<string, string | undefined>>();
  const [deleteModal, setDeleteModal] = React.useState(Boolean);
  const [deleteCodigo, setDeleteCodigo] = React.useState('');
  const [component, setComponent] = React.useState('');
  const inputsBox = React.useRef<FormHandles>(null);
  const [description, setDescription] = React.useState('');

  React.useEffect(() => {
    setAnimate(true);
  }, []);

  const getProduct = React.useCallback(
    async (code: any) => {
      if (code) {
        const dataFilter = {
          produto: code,
          codigoCliente: Number(idCliente),
        };
        try {
          await api
            .get(`Pack/Composicao/Produtos`, {
              params: dataFilter,
              headers: { Authorization: `Bearer ${tokenData}` },
            })
            .then((componenteData: AxiosResponse) => {
              if (Object.values(componenteData.data).length === 0) {
                console.error('Produto não existe');
              } else {
                const product: ICompositionProduct[] = componenteData.data;
                setDescription(product[0].descricaoProduto);
              }
            })
            .catch((estruturaError: AxiosError<any>) => {
              if (estruturaError.response!.status === 401 && tokenData) {
                enqueueSnackbar('Reautenticando, tente novamente.', {
                  variant: 'warning',
                });
              } else {
                estruturaError.response!.data.erros.map((item: any) => {
                  enqueueSnackbar(item.mensagem, { variant: 'error' });
                });
              }
            });
          await api
            .get(`Pack/Composicao`, {
              params: dataFilter,
              headers: { Authorization: `Bearer ${tokenData}` },
            })
            .then((componenteData: AxiosResponse) => {
              if (Object.values(componenteData.data).length > 0) {
                setCreateComposition([...componenteData.data]);
                setLoading(true);
                setVisible(false);
              } else {
                setCreateComposition([]);
              }
            })
            .catch((estruturaError: AxiosError<any>) => {
              if (estruturaError.response!.status === 401 && tokenData) {
                enqueueSnackbar('Reautenticando, tente novamente.', {
                  variant: 'warning',
                });
              } else {
                estruturaError.response!.data.erros.map((item: any) => {
                  enqueueSnackbar(item.mensagem, { variant: 'error' });
                });
              }
            });
        } catch (err) {
          console.error(err);
        }
      }
    },
    [enqueueSnackbar, idCliente, tokenData]
  );

  React.useLayoutEffect(() => {
    getProduct(id);
  }, [id, getProduct]);

  const handleSubmit = React.useCallback(
    async (data: any) => {
      try {
        const schema = Yup.object().shape({
          codigoComponente: Yup.string().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });
        const dataFilter = {
          CodigoCliente: Number(idCliente),
          codigoComponente: data.codigoComponente,
        };

        await api
          .get<IComponents>('Pack/Componente', {
            params: dataFilter,
            headers: { Authorization: `Bearer ${tokenData}` },
          })
          .then((componenteData: AxiosResponse) => {
            if (Object.values(componenteData.data).length === 0) {
              enqueueSnackbar('Nenhum registro foi encontrado.', {
                variant: 'error',
              });
            } else {
              const componentObject: IComponents[] = componenteData.data;

              enqueueSnackbar(
                `Foi encontrado ${componenteData.data.length} registro(s)!`,
                {
                  variant: 'success',
                }
              );
              inputsBox.current?.setFieldValue(
                'descricaoComponente',
                componentObject[0].descricaoComponente
              );
            }
          })
          .catch((estruturaError: AxiosError<any>) => {
            if (estruturaError.response!.status === 401 && tokenData) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              estruturaError.response!.data.erros.map((item: any) => {
                enqueueSnackbar(item.mensagem, { variant: 'error' });
              });
            }
          });
      } catch (err) {
        // eslint-disable-next-line no-console
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          inputsBox.current?.setErrors(errors);
        } else {
          console.error(err);
          const timer = setTimeout(() => {
            setLoading(true);
            setVisible(false);

            return () => {
              clearTimeout(timer);
            };
          }, 1000);
        }
      }
    },
    [idCliente, tokenData, enqueueSnackbar]
  );

  const deleteHas = React.useCallback(
    // eslint-disable-next-line prettier/prettier
    async (dataCodigo: string) => {
      if (dataCodigo) {
        setComponent(dataCodigo);
        setDeleteCodigo(dataCodigo);
        setDeleteModal(true);
      }
    },
    []
  );

  const handleDeleteAddress = React.useCallback(
    // eslint-disable-next-line prettier/prettier
    async () => {
      try {
        if (!deleteCodigo) {
          enqueueSnackbar('Código não inexistente', { variant: 'error' });
        }

        const inativeData = {
          codigoCliente: Number(idCliente),
          codigoComponente: deleteCodigo,
          produto: id,
        };

        const dataFilter = {
          produto: id,
          codigoCliente: Number(idCliente),
        };

        await api
          .delete(`Pack/Composicao`, {
            params: inativeData,
            headers: { Authorization: `Bearer ${tokenData}` },
          })
          .then(async (data: AxiosResponse) => {
            if (data.data!.erros) {
              enqueueSnackbar(
                data!.data.erros.map((item: any) => item.mensagem).join('\n'),
                {
                  variant: 'error',
                }
              );
            } else {
              enqueueSnackbar(`Registro deletado com sucesso`, {
                variant: 'success',
              });
              setDeleteCodigo('');
              setDeleteModal(false);
              await api
                .get('Pack/Composicao', {
                  params: dataFilter,
                  headers: { Authorization: `Bearer ${tokenData}` },
                })
                .then((componenteDataComp: AxiosResponse) => {
                  if (componenteDataComp.data.length === 0) {
                    enqueueSnackbar('Nenhum registro foi encontrado.', {
                      variant: 'error',
                    });
                    setCreateComposition([]);
                  } else {
                    setLoading(true);
                    setVisible(false);
                    setCreateComposition([...componenteDataComp.data]);
                  }
                })
                .catch((estruturaError: AxiosError<any>) => {
                  if (estruturaError.response!.status === 401 && tokenData) {
                    enqueueSnackbar('Reautenticando, tente novamente.', {
                      variant: 'warning',
                    });
                  } else {
                    estruturaError.response!.data.erros.map((item: any) => {
                      enqueueSnackbar(item.mensagem, { variant: 'error' });
                    });
                  }
                });
            }
          })
          .catch((error: AxiosError<any>) => {
            if (error.response!.status === 401 && tokenData) {
              enqueueSnackbar('Reautenticando, tente novamente.', {
                variant: 'warning',
              });
            } else {
              error.response!.data.erros.map((item: any) => {
                enqueueSnackbar(item.mensagem, { variant: 'error' });
              });
            }
          });
      } catch (err) {
        console.error(err);
      } finally {
        setDeleteCodigo('');
        setComponent('');
        const timer = setTimeout(async () => {
          return () => {
            clearTimeout(timer);
          };
        }, 1000);
      }
    },
    [deleteCodigo, idCliente, id, tokenData, enqueueSnackbar]
  );

  const handleCancelDelete = React.useCallback(async () => {
    setComponent('');
    setDeleteCodigo('');
    setDeleteModal(false);
  }, []);

  const handleSubmitCreate = React.useCallback(async () => {
    try {
      const addComponent = {
        codigoCliente: Number(idCliente),
        codigoComponente: inputsBox.current?.getFieldValue('codigoComponente'),
        quantidade: inputsBox.current?.getFieldValue('quantidade'),
        produto: id,
      };

      const dataFilter = {
        produto: id,
        codigoCliente: Number(idCliente),
      };

      await api
        .post<IComponents>(
          'Pack/Composicao',
          { ...addComponent },
          {
            headers: { Authorization: `Bearer ${tokenData}` },
          }
        )
        .then(async (componenteData: AxiosResponse) => {
          if (Object.values(componenteData.data).length === 0) {
            enqueueSnackbar('Nenhum registro foi encontrado.', {
              variant: 'error',
            });
          } else {
            inputsBox.current?.reset();
            await api
              .get('Pack/Composicao', {
                params: dataFilter,
                headers: { Authorization: `Bearer ${tokenData}` },
              })
              .then((componenteDataComp: AxiosResponse) => {
                console.log(componenteDataComp.data.length);
                if (componenteDataComp.data.length === 0) {
                  enqueueSnackbar('Nenhum registro foi encontrado.', {
                    variant: 'error',
                  });
                } else {
                  setLoading(true);
                  setVisible(false);
                  setCreateComposition([...componenteDataComp.data]);
                }
              })
              .catch((estruturaError: AxiosError<any>) => {
                if (estruturaError.response!.status === 401 && tokenData) {
                  enqueueSnackbar('Reautenticando, tente novamente.', {
                    variant: 'warning',
                  });
                } else {
                  estruturaError.response!.data.erros.map((item: any) => {
                    enqueueSnackbar(item.mensagem, { variant: 'error' });
                  });
                }
              });
          }
        })
        .catch((estruturaError: AxiosError<any>) => {
          if (estruturaError.response!.status === 401 && tokenData) {
            enqueueSnackbar('Reautenticando, tente novamente.', {
              variant: 'warning',
            });
          } else {
            estruturaError.response!.data.erros.map((item: any) => {
              enqueueSnackbar(item.mensagem, { variant: 'error' });
            });
          }
        });
    } catch (err) {
      console.error(err);
    }
  }, [enqueueSnackbar, id, idCliente, tokenData]);

  return (
    <>
      <Modal
        type="delete"
        title="Inativar"
        open={deleteModal}
        handleCancel={() => handleCancelDelete()}
        handleSubmit={handleDeleteAddress}
      >
        {`Você tem certeza que deseja excluir o componente ${component} da composição atual?`}
      </Modal>
      <FixedHeader title="Cadastros" subTitle="Composição" />
      <Container>
        <Fade in={animate} timeout={1000}>
          <Search>
            <SearchBox
              title={`- Produto ${id} - ${description}`}
              addButton
              returnButton
              clickOnReturn={() => location.push('/wms/composition/')}
              handleAdd={handleSubmitCreate}
              handleSubmit={handleSubmit}
              searchBoxRef={inputsBox}
              inputs={[
                {
                  name: 'codigoComponente',
                  placeholder: 'Código do Componente',
                  type: 'text',
                  isRequired: true,
                  label: 'Código do Componente',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 12,
                },
                {
                  name: 'descricaoComponente',
                  placeholder: 'Descrição do Componente',
                  disabled: true,
                  type: 'text',
                  label: 'Descrição do Componente',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 12,
                },
                {
                  name: 'quantidade',
                  placeholder: 'Quantidade',
                  type: 'text',
                  label: 'Quantidade',
                  xl: 4,
                  lg: 4,
                  xs: 12,
                  md: 4,
                  sm: 12,
                },
              ]}
            />
          </Search>
        </Fade>
        {visible && (
          <>
            {!loading && (
              <Fade in={animate} unmountOnExit timeout={1000}>
                <div className="wrapper" style={{ display: 'flex' }}>
                  <div className="wrap" style={{ height: 410, flexGrow: 1 }}>
                    <Skeleton
                      style={{ borderRadius: '5px' }}
                      variant="rect"
                      height={400}
                    />
                  </div>
                </div>
              </Fade>
            )}
          </>
        )}
        {loading && (
          <Grid container>
            <Fade in={animate} unmountOnExit timeout={1000}>
              <div className="wrapper" style={{ display: 'flex' }}>
                <div className="wrap" style={{ flexGrow: 1 }}>
                  <TableContainer>
                    <Table<IComponents>
                      exportList
                      columns={[
                        {
                          title: 'Componente',
                          type: 'string',
                          orderable: true,
                          props: ['codigoComponente'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                        },
                        {
                          title: 'Descrição',
                          type: 'string',
                          orderable: true,
                          props: ['descricaoComponente'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                            textAlign: 'left',
                          },
                        },
                        {
                          title: 'Quantidade',
                          type: 'string',
                          position: 'right',
                          orderable: true,
                          display: 'notEmpty',
                          props: ['quantidade'],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '5%',
                            paddingRight: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                            textAlign: 'left',
                          },
                        },
                        {
                          title: 'Ações',
                          type: undefined,
                          props: [''],
                          cssTitle: {
                            justifyContent: 'flex-start',
                            textAlign: 'left',
                          },
                          cssProps: {
                            width: '1%',
                            paddingRight: '8px',
                            paddingLeft: '8px',
                            fontFamily: 'sans-serif',
                          },
                          cssText: {
                            paddingLeft: '8px',
                          },
                          renderItem: actions => {
                            return (
                              <Actions>
                                <IconButton
                                  onClick={() => {
                                    deleteHas(actions.codigoComponente);
                                  }}
                                  aria-label="Apagar"
                                >
                                  <DeleteRoundedIcon color="disabled" />
                                </IconButton>
                              </Actions>
                            );
                          },
                        },
                      ]}
                      rows={[
                        ...createComposition.map(item => ({
                          ...item,
                        })),
                      ]}
                    />
                  </TableContainer>
                </div>
              </div>
            </Fade>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default CreateComposition;
