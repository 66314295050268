/* eslint-disable consistent-return */
import React, { useCallback, useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import sidebarArrow from 'assets/svg/sidebarArrow.svg';
import WMSLogo from 'assets/svg/WMSLogo.svg';
import { useSidebar } from 'hooks/Sidebar';
import { useMobile } from 'hooks/Mobile';
import api from 'services/api';
import { getCookie } from 'utils/cookies';
import { SideMenu } from './styles';
import ItemLink from './ItemLink';
import OptionsLanguage from '../OptionsLanguage';
import { version } from '../../../../package.json';

interface LinkProps {
  codigoSistemaMenu: number;
  codigoSistema: number;
  menu: string;
  pagina: string;
  ordem: number;
}

interface PagesProps {
  codigoSistemaMenu: number;
  codigoSistema: number;
  menu: string;
  menuEs: string;
  menuUs: string;
  menuCh: string;
  pagina: string;
  ordem: number;
  initialPage?: string;
  links: Array<LinkProps>;
  linksEs: Array<LinkProps>;
  linksUs: Array<LinkProps>;
  linksCh: Array<LinkProps>;
}

const Sidebar: React.FC = () => {
  const { pathname } = useLocation();
  const { fixSidebar, isFixed, setIsFixedState } = useSidebar();
  const { isMobileDevice } = useMobile();
  const [active, setActive] = useState(false);
  const [expanded, setExpanded] = useState(true);
  const [itemId, setItemId] = useState<number>();
  const isLanguageCurrent = String(localStorage.getItem('i18nextLng'));
  const [currentLanguage, setCurrentLanguage] =
    useState<string>(isLanguageCurrent);
  const [pages, setPages] = useState<PagesProps[]>([]);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const codigoCliente = getCookie('@pdamodules::codigoCliente');
  const codigoSistema = getCookie('@pdamodules::CodigoSistema');

  useEffect(() => {
    if (isMobileDevice) {
      setIsFixedState(true);
    }
  }, [isMobileDevice, setIsFixedState]);

  const openItem = (index: number | undefined) => setItemId(index);

  const handleExpand = useCallback(() => {
    fixSidebar();
    setActive(prevState => !prevState);
    if (isMobileDevice) setExpanded(true);
  }, [fixSidebar, isMobileDevice]);

  const getMenu = useCallback(async () => {
    await api
      .get<PagesProps[]>('SistemaMenu', {
        params: {
          codigoCliente,
          codigoSistema,
        },
      })
      .then(response => {
        setPages(response.data);
      });
  }, [codigoCliente, codigoSistema]);

  const handleReOpenSidebar = useCallback(() => {
    if (!expanded) setIsHovering(true);
  }, [expanded]);

  const handleHideSidebar = useCallback(() => {
    if (isMobileDevice) {
      setExpanded(false);
      setIsFixedState(false);
      return;
    }
    if (isHovering && isFixed) setIsHovering(false);
  }, [isHovering, isFixed, isMobileDevice, setIsFixedState]);

  const isSelectedCheck = useCallback(
    (page?: string) => {
      let finalCheck = false;

      if (page) {
        finalCheck = page?.split('/')[2] === pathname.split('/')[2];

        return finalCheck;
      }
      return false;
    },
    [pathname]
  );

  const hendleLanguageDefined = useCallback((props: string) => {
    setCurrentLanguage(props);
  }, []);

  const handleLanguageCurrent = useCallback(
    (pagesLink: PagesProps) => {
      switch (currentLanguage) {
        case 'pt-BR':
          return pagesLink.menu;
        case 'es':
          return pagesLink.menuEs;
        case 'en-US':
          return pagesLink.menuUs;
        case 'zh-CN':
          // return pagesLink.menuCh;
          return pagesLink.menuUs;
        default:
          return pagesLink.menu;
      }
    },
    [currentLanguage]
  );

  const handleLinkLanguage = useCallback(
    (link: PagesProps) => {
      switch (currentLanguage) {
        case 'pt-BR':
          return link.links;
        case 'es':
          return link.linksEs;
        case 'en-US':
          return link.linksUs;
        case 'zh-CN':
          // return link.linksCh;
          return link.linksUs;
        default:
          return link.links;
      }
    },
    [currentLanguage]
  );

  useEffect(() => {
    getMenu();
    isSelectedCheck();
  }, [getMenu, isSelectedCheck]);

  useEffect(() => {
    setExpanded(!!(isMobileDevice || isHovering));
  }, [isHovering, isMobileDevice]);

  useEffect(() => {
    setExpanded(isMobileDevice ? !!isFixed : !isFixed);
  }, [isFixed, isMobileDevice]);

  //

  const closeSidebarWhenClickItem = () => {
    if (isMobileDevice) {
      setExpanded(false);
      setActive(false);
      setIsHovering(true);
      setIsFixedState(true);
    }
  };

  return (
    <SideMenu
      active={active}
      expand={expanded}
      fixed={isFixed}
      onMouseOver={handleReOpenSidebar}
      onMouseLeave={handleHideSidebar}
      notDeskTop={window.innerWidth < 1640}
      mobile={isMobileDevice}
    >
      <div className="content">
        <div className="top">
          <div className="container">
            <Link
              className="logo"
              to="/wms/home/"
              onClick={closeSidebarWhenClickItem}
            >
              {!expanded ? (
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  className="minifyLogo"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 2C7.84987 2.44222 11.4095 3.18865 15 4.63644C23.5883 8.09953 32.3526 15.5755 32 32.5"
                    stroke="#E12325"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                  <circle cx="3.5" cy="29.6" r="3" fill="#E12325" />
                  <path
                    d="M3 19.5C6.66667 19.8333 14 22.7 14 31.5"
                    stroke="#E12325"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                  <path
                    d="M4 10C10.4969 10.1705 23.3907 14.8093 22.9909 32"
                    stroke="#E12325"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                  />
                </svg>
              ) : (
                <img className="fullLogo" alt="WMS logo" src={WMSLogo} />
              )}
            </Link>
          </div>
        </div>
        <div className="links-list">
          {pages.map((page, index) => (
            <ItemLink
              id={index}
              select={page.pagina}
              openItem={() => openItem(index)}
              openedState={itemId || 0}
              key={page.ordem}
              links={handleLinkLanguage(page)}
              closed={!expanded}
              menu={handleLanguageCurrent(page)}
              pagina={page.pagina}
              closeSidebarFunc={closeSidebarWhenClickItem}
            />
          ))}
        </div>
      </div>
      <div className="arrow-back" onClick={handleExpand}>
        <span className="arrow-back-span">
          <button
            type="button"
            className={`arrow-back-button ${!isFixed ? 'rotate' : ''}`}
          >
            <img src={sidebarArrow} alt="Expandir sidebar" />
          </button>
        </span>
      </div>

      <OptionsLanguage
        expanded={expanded}
        isCurrentLanguage={hendleLanguageDefined}
      />
      <Link to="/wms/changelog/">
        <span className="version">WMS | VER {version}</span>
      </Link>
    </SideMenu>
  );
};

export default Sidebar;
