import React from 'react';

const Conferencia: React.FC = () => {
  return (
    <svg
      width="23"
      height="17"
      viewBox="0 0 23 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.35208 6.31592L1.53846 8L1.39458 8.1336C1.18155 8.33142 1.18155 8.66858 1.39458 8.8664L8.7228 15.6712C8.9203 15.8546 9.22761 15.8484 9.41762 15.6573L21.6353 3.36689C21.8347 3.16631 21.8282 2.84043 21.6209 2.64799L20.1864 1.31592C19.9945 1.13779 19.6978 1.13779 19.5059 1.31592L9.41715 10.6841C9.22531 10.8622 8.92854 10.8622 8.7367 10.6841L4.03253 6.31592C3.84069 6.13779 3.54392 6.13779 3.35208 6.31592Z"
        fill="#FFFFFF"
        stroke="#FFFFFF"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Conferencia;
