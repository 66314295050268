import React, { useState, useLayoutEffect, useCallback, useRef } from 'react';
import i18n from 'i18next';
import { Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import FixedHeader from 'components/Tools/Breadcrumb';
import SearchBox from 'components/Tools/Search';
import { Container, Search } from 'app/WMS/styles/styles';
import Loader from 'components/Tools/Loader';
import { InventoryData } from 'utils/interfaces/Log';
import { getCookie } from 'utils/cookies';
import { searchBoxRequest } from 'features/Inventory/Bording/api/inventory.api';
import { InventoryDetailsModal } from 'features/Inventory/Bording/components';
import { FormHandles } from '@unform/core';
import BatchTable from './components/batchTable';

const ExitReport: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [isTableShowing, setIsTableShowing] = useState(false);

  const [inventoryStorage, setInventoryStorage] = useState<InventoryData[]>([]);
  const [modalData, setModalData] = useState<InventoryData>(
    {} as InventoryData
  );

  const { enqueueSnackbar } = useSnackbar();
  const searchRef = useRef<FormHandles>(null);
  const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  const dateDay = new Date(Date.now() - tzoffset).toISOString().split('T')[0];

  const clientId = getCookie('@pdamodules::codigoCliente');

  const userId = getCookie('@pdamodules::id');

  const { t } = useTranslation();

  const translationMessage = (
    firstMessage: string,
    lastMessage?: string
  ): string => {
    const translation = i18n.t;
    return lastMessage
      ? `${firstMessage} ${translation(lastMessage)}`
      : translation(firstMessage);
  };

  const getOptions = useCallback(async () => {
    setIsLoading(true);
    setIsLoading(false);
  }, []);
  const checkFields = useCallback(() => {
    if (
      searchRef.current?.getFieldValue('notaFiscal') !== '' ||
      searchRef.current?.getFieldValue('codigoPedido') !== ''
    ) {
      return false;
    }
    return true;
  }, []);

  const handleSubmit = useCallback(
    async (data: unknown) => {
      setIsLoading(true);
      setIsTableShowing(false);

      await searchBoxRequest(data, Number(clientId), Number(userId))
        .then(res => {
          if (!res.data.length) {
            enqueueSnackbar(
              translationMessage('screens.commom.resultsNotFound'),
              {
                variant: 'warning',
              }
            );

            setIsTableShowing(false);

            return;
          }

          enqueueSnackbar(
            translationMessage(
              String(res.data.length),
              'screens.commom.successFilter'
            ),
            {
              variant: 'success',
            }
          );

          setInventoryStorage(res.data);
          setIsTableShowing(true);
        })
        .catch(err => {
          enqueueSnackbar(`Erro: ${err.message}`, {
            variant: 'error',
          });

          setIsTableShowing(false);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [clientId, userId, enqueueSnackbar]
  );

  useLayoutEffect(() => {
    getOptions();
  }, [getOptions]);

  return (
    <>
      <FixedHeader
        title={t('screens.separation.reports')}
        subTitle={String(t('screens.manualOrder.exit'))}
      />
      <Fade in timeout={1000}>
        <Container>
          <Search>
            <SearchBox
              handleSubmit={data => handleSubmit(data)}
              inputs={[
                {
                  name: 'pedido',
                  placeholder: String(t('screens.commom.order')),
                  label: String(t('screens.commom.order')),
                  type: 'text',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'tipo',
                  placeholder: String(t('screens.structureAdresses.type')),
                  label: String(t('screens.structureAdresses.type')),
                  type: 'text',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'produto',
                  placeholder: String(t('screens.commom.product')),
                  label: String(t('screens.commom.product')),
                  type: 'text',
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'lote',
                  placeholder: String(
                    t('screens.pickingConference.bathModalTitle')
                  ),
                  label: String(t('screens.pickingConference.bathModalTitle')),
                  type: 'text',
                  isLoading,
                  isDisabled: isLoading,
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'serie',
                  placeholder: String(t('screens.receivement.series')),
                  label: String(t('screens.receivement.series')),
                  type: 'text',
                  isLoading,
                  isDisabled: isLoading,
                  xl: 3,
                  lg: 3,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
              hiddenInputs={[
                {
                  name: 'dataInicio',
                  label: String(t('screens.commom.initialDate')),
                  type: 'date',
                  isRequired: checkFields(),
                  defaultValue: dateDay,
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
                {
                  name: 'dataFim',
                  label: String(t('screens.commom.finalDate')),
                  type: 'date',
                  isRequired: checkFields(),
                  defaultValue: dateDay,
                  xl: 6,
                  lg: 6,
                  xs: 12,
                  md: 6,
                  sm: 6,
                },
              ]}
              searchDisabled={isLoading}
            />
          </Search>
          {isLoading && <Loader />}
          {!isLoading && isTableShowing && (
            <BatchTable
              rows={inventoryStorage}
              setDetailsModal={setDetailsModal}
              setModalData={setModalData}
            />
          )}
        </Container>
      </Fade>
      {detailsModal && (
        <InventoryDetailsModal
          data={modalData}
          isOpen={detailsModal}
          closeModal={() => setDetailsModal(false)}
        />
      )}
    </>
  );
};

export default ExitReport;
