import styled, { css } from 'styled-components';

interface ContainerProps {
  checked: boolean;
  isRow?: boolean;
}

export const Container = styled.label<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${props =>
    props.isRow &&
    css`
      flex-direction: row !important;
      gap: 10px;
      min-width: 100% !important;
      align-items: center !important;
      justify-content: flex-start;
      padding-left: 2rem;
      @media screen and (max-width: 980px) {
        padding-left: 0;
        justify-content: center;
      }
    `}

  .label {
    display: flex;
    flex-direction: row;
    margin-bottom: -2px;
    color: #555555;
    font-size: 12px !important;
  }

  .switchButtonContainer {
    width: 40px;
    height: 25px;
    background: ${({ checked, theme }) =>
      checked
        ? theme.colors.switchButtonMarkedBg
        : theme.colors.switchButtonUnmarkedBg};
    transition: background 0.5s;
    border-radius: 9px;
    position: relative;
    margin-top: 12px;
    cursor: pointer;
  }
  margin-bottom: 4px;
  margin-left: 12px;

  .circle {
    content: '';
    position: absolute;
    top: 50%;
    left: ${props => (props.checked ? 'calc(100% - 17px)' : '-5px')};
    transition: left 0.3s;
    transform-origin: center;
    transform: translate3d(0, -50%, 0);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: ${({ checked, theme }) =>
      checked
        ? theme.colors.switchButtonMarkedCircle
        : theme.colors.switchButtonUnmarkedCircle};

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      width: 55%;
      height: 55%;
      background-color: ${({ checked, theme }) =>
        checked
          ? theme.colors.switchButtonMarkedInnerCircle
          : theme.colors.switchButtonUnmarkedInnerCircle};
      border-radius: 50%;
    }
  }

  input {
    width: 0;
    height: 0;
    opacity: 0;
  }
`;
