import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 54px;
  background: ${props => props.theme.colors.header_background};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: nowrap !important;
  align-items: center;
  padding: 1rem 10px;
  border-top: 1px solid ${props => props.theme.colors.line};
  z-index: 5;

  > * {
    flex-shrink: 0;
  }

  .colorDescription {
    color: ${props =>
      props.theme.title === 'light' ? '#7a7a7a' : '#fff'} !important;
    fill: ${props => props.theme.colors.description} !important;
    stroke: ${props => props.theme.colors.description} !important;
    font-size: 12px !important;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    letter-spacing: 0.00938em;
    display: inline-flex;
    align-items: flex-end;
    flex-wrap: nowrap !important;
    justify-content: space-between;
    box-sizing: border-box !important;
    gap: 5px;
    justify-content: center;
    &.subtitle {
      color: ${props =>
        props.theme.title === 'light' ? '#7a7a7a' : '#fff'} !important;
    }
    &.mainTitle {
      font-size: 14px !important;
    }
  }
`;

export const InventoryInfoContainer = styled.div`
  height: 44px;
  font-size: 14px;
  width: 100% !important;
  display: grid;
  flex-wrap: nowrap !important;
  box-sizing: border-box;
  border: 1px solid #0000ff;
  border-radius: 60px;
  padding: 0.7rem 5px;
  align-items: center !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  box-sizing: border-box !important;
  overflow: hidden;

  > * {
    flex-shrink: 0;
  }

  .detailInventory {
    display: flex;
    padding: 0px 8px;

    color: ${props =>
      props.theme.title === 'light' ? '#7a7a7a' : '#fff'} !important;
    fill: ${props => props.theme.colors.description} !important;

    span {
      color: #0000ff;
      margin-right: 5px;
      margin-left: 5px;
    }

    .red-text {
      color: #ff0000;
      font-weight: 700 !important;
    }

    .green-text {
      color: #59de8f;
      font-weight: 700 !important;
    }

    p {
      flex: 1;
      margin: 0;
      flex-shrink: 0;
    }
  }
`;

export const InventoryModalContainer = styled.div`
  .titleMenuInventory {
    box-sizing: border-box;
    font-size: 12px !important;
    color: #0000ff !important;
    text-transform: none !important;
    display: flex !important;

    img {
      margin-right: 10px !important;
    }


    @keyframes slidein {
      75% {
        font-size: 300%;
        margin-left: 25%;
        width: 150%;
      }
    }
  }
  .inventoryModal {
    width: 250px !important;
    padding: 5px;
    background-color: ${props =>
      props.theme.title === 'dark' ? '#262639' : '#FFF'} !important;

    font-size: 12px !important;
    font-weight: 400 !important;
    width: 12rem !important;
    font-family: Poppins;
    line-height: 18px !important;
    color: ${props =>
      props.theme.title === 'dark' ? '#B5B5C3' : '#B5B5C3'} !important;
    width: 100% !important;

    span {
      color: #0000ff;
    }

    .red-text {
      color: #ff0000;
      font-weight: 600 !important;
    }

    &:hover {
      background-color: ${props =>
        props.theme.title === 'dark' ? '#212E48' : '#ECF8FF'} !important;
      color: #187de4 !important;
    }

    animation: outOpacityArrow 0.5s ease-in-out both;
    animation-name: slidein;

    @keyframes slidein {
      75% {
        font-size: 300%;
        margin-left: 25%;
        width: 150%;
      }
    }
  }

  .descInventory {
    font-size: 12px !important;
    color: #187de4 !important;
    text-transform: none !important;
    display: flex !important;

    animation: outOpacityArrow 0.5s ease-in-out both
    animation-name: slidein;

    @keyframes slidein {
      75% {
        font-size: 300%;
        margin-left: 25%;
        width: 150%;
      }
    }
  }
`;
