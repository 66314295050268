/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-alert */
import React, { Suspense, useLayoutEffect } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';

import Loader from 'components/Tools/Loader';
import { getCookie, setCookie } from 'utils/cookies';
import Header from '../components/Layout/Header';

import { useAuth } from '../hooks/Auth';

import SignedLayout from '../components/Layout/Base';
import Copy from '../components/Layout/Copy';
import api from '../services/api';
// eslint-disable-next-line spaced-comment
//@ts-ignore
interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  isInside?: boolean;
  component: React.ComponentType;
}

interface Authentication {
  authenticated: boolean;
  created: string;
  expiration: string;
  accessToken: string;
  refreshToken: string;
  codigoUsuario: number;
  login: string;
  nome: string;
  email: string;
  ativo: boolean;
  alterarSenha: false;
  codigoPerfil: number;
  descricaoPerfil: string;
  lider: boolean;
  clientes: {
    descricaoCliente: string;
  }[];
  usuInc: number;
  datInc: string;
  usuAlt: number;
  datAlt: string;
}

const Routes: React.FC<RouteProps> = ({
  isPrivate = false,
  isInside = false,
  component: Component,
  ...rest
}) => {
  const { user, signOut } = useAuth();
  const date = new Date().toString();

  const [isAuthenticated, setIsAuthenticated] = React.useState(true);

  const reauthenticate = React.useCallback(async () => {
    const login = user?.data.login;
    const refreshToken = getCookie('@pdamodules::refreshtoken');

    try {
      const response = await api.post<Authentication>(
        `/Autenticacao/Refresh-Token`,
        { login, refreshToken }
      );

      if (response.data.authenticated) {
        setCookie('@pdamodules::token', response.data.accessToken, 5000);
        setCookie('@pdamodules::exception', response.data.expiration, 5000);
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    } catch (err) {
      console.error(err);
      signOut();
    }
  }, [user?.data.login, signOut]);

  useLayoutEffect(() => {
    const exception = sessionStorage
      .getItem('@pdamodules::exception')
      ?.replace(/"/g, '');
    const isExpired =
      parseInt(String(exception), 10) - 1000000 <= Date.parse(date);
    // exportData();
    if (isExpired && user) {
      // reauthenticate();
    }
  }, [reauthenticate, date, isAuthenticated, user]);

  const SuspenseComponent: React.FC = () => (
    <Suspense fallback={<Loader height="calc(100vh - 70px)" />}>
      {/* @ts-ignore */}
      <Component />
    </Suspense>
  );

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        if (isPrivate) {
          if (user) {
            if (isAuthenticated) {
              if (isInside) {
                return (
                  <SignedLayout>
                    <SuspenseComponent />
                  </SignedLayout>
                );
              }
              return (
                <>
                  <Header />
                  <SuspenseComponent />
                  <Copy />
                </>
              );
            }
            signOut();
            return (
              <>
                {window.confirm('Sua sessão expirou, faça login novamente.')
                  ? window.location.replace(
                      `${
                        process.env.REACT_APP_ENV === 'dev'
                          ? process.env.REACT_APP_LOGOUT_DEV_URL
                          : process.env.REACT_APP_LOGOUT_PROD_URL
                      }`
                    )
                  : window.location.replace(
                      `${
                        process.env.REACT_APP_ENV === 'dev'
                          ? process.env.REACT_APP_LOGOUT_DEV_URL
                          : process.env.REACT_APP_LOGOUT_PROD_URL
                      }`
                    )}
              </>
            );
          }
          // return <Redirect to={{ pathname: '/' }} />;
        }
        return (
          <>
            <Header />
            <SuspenseComponent />
            <Copy />
          </>
        );
      }}
    />
  );
};

export default Routes;
