import api from 'services/api';
import { IDataFilterProps, SelectItem } from '../types';

export const handleDataFilter = (dataFilter: IDataFilterProps) =>
  api.patch(`Picking/Estornar`, { ...dataFilter });

export const handleParamiters = (idCliente: Number) =>
  api.get<SelectItem>(
    `Common/StatusPicking?codigoCliente=${Number(idCliente)}`
  );

export const handleEdit = (
  idCliente: Number,
  codigoDelete: String,
  code: string
) =>
  api.delete(
    `Picking/Estornar?codigoCliente=${Number(idCliente)}&codigoPedido=${String(
      codigoDelete
    )}&code=${code}`
  );
