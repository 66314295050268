import styled from 'styled-components';

export const CustomizedImgCellContainer = styled.div`
  max-width: 100%;
  img {
    max-width: 100%;
    display: block;
    object-fit: cover;
  }
`;
