import { DefaultTheme } from 'styled-components';

const dark: DefaultTheme = {
  title: 'dark',
  colors: {
    primary: '#45BCFF',
    secundary: '#28CE6B',
    default: '#c8c8c8',
    body: '#151521',
    modules: '#FFFFFF',
    secoundary_body: '#ececec',
    background: '#262639',
    container_background: '#262639',
    container_title: '#EFF2F5',
    container_header_border: '#1B1B28',
    header_background: '#262639',
    dashboard_card_bg: '#262639',
    sidebar: '#262639',
    sidebarLinear: '#141414',
    sidebarPerfil: '#222222',
    iconFill: '#FFFFFF',
    thead: '#F2F2F2',
    tbodyZebra: '#FBFBFB',
    backgroundIcon: '#f3f3f3',
    text: '#FFFFFF',
    secoundary_text: '#a5a5a5',
    title: '#f0f0f0',
    hyperLink: '#12AAFF',
    textTable: '#B5B5C3',
    table_row_line: '#4C4C66',
    table_column_title: '#4C4C66',
    table_bg: '#262639',
    description: '#A8a8a8',
    sidebarLink: '#adadad',
    sidebarSelected: '#3B4146',
    sidebarSelectedLink: '#DDDDDD',
    input: '#262639',
    borderInput: '#2B2B40',
    placeholder: '#B0B0B0',
    placeholderSelected: '#555555',
    details: '#B5B5C3',
    backgroundModule_1: '#f3f7fa',
    backgroundModule_2: '#eef3f7',
    backgroundModule_3: '#e5ecf2',
    titleModule: '#5E5F62',
    descriptionModule: '#99aac4',
    backgroundLogoutButton: '#f1f1f1',
    textLogoutButton: '#606060',
    iconLogoutButton: '#828282',
    logo: '#262626',
    line: '#1B1B28',
    success: '#DEF8E9',
    successText: '#59DE8F',
    error: '#F8DEDE',
    errorText: '#DE5959',
    lineTable: '#f6f6f6',
    total: '#FFFFFF',
    select_text: '#606060',
    arrow: '#4f4f4f',
    besni: '#ee2e2a',
    actions: '#f2f2f2',
    disabledInput: '#f2f2f2',
    disabledText: '#B5B5C3',
    progress_background: '#dddddd',
    counter_background: '#f2f2f2',
    counter_color: '#606060',
    value_color: '#999999',
    environmentTypes: {
      dev: {
        bg: '#3A2434',
        color: '#D9214E',
      },
      homol: {
        bg: '#392F28',
        color: '#F1BC00',
      },
      prod: {
        bg: '#1C3238',
        color: '#46BE7D',
      },
    },
    // dashboardCard: 0,
    dashboardCard1: '#3A86FF',
    dashboardCard2: '#064AB8',
    dashboardCard3: '#56DE02',
    dashboardCard4: '#58E600',
    dashboardCard5: '#FA2878',
    dashboardCard6: '#ffbd00',
    dashboardCard7: '#ff0054',
    danger: '#ff3b4c',
    switchButtonMarkedBg: '#1C3238',
    switchButtonMarkedCircle: '#50CD89',
    switchButtonMarkedInnerCircle: '#FFFFFF',
    switchButtonUnmarkedBg: '#323248',
    switchButtonUnmarkedCircle: '#6D6D80',
    switchButtonUnmarkedInnerCircle: '#FFFFFF',
    tagStatusColors: {
      em_andamento_or_inativo: {
        color: '#F1BC00',
        bg: '#392F28',
      },
      cancelado: {
        color: '#3F4254',
        bg: '#15151E',
      },
      finalizado_divergente_or_bloqueado: {
        color: '#D9214E',
        bg: '#3A2434',
      },
      finalizado_or_ativo: {
        color: '#46BE7D',
        bg: '#1C3238',
      },
      pendente: {
        color: '#B5B5C3',
        bg: '#4C4C66',
      },
      aguardando_faturamento_or_integracao_or_expedicao: {
        color: '#F69B11',
        bg: '#39272C',
      },
      andamento: {
        color: '#F1BC00',
        bg: '#392F28',
      },
      ressuprimento: {
        color: '#663259',
        bg: '#2E2237',
      },
      expedicao_finalizada_or_aguardando_conferencia: {
        color: '#4A7DFF',
        bg: '#212E48',
      },
      pedido_cancelado: {
        color: '#3F4254',
        bg: '#15151E',
      },
      criado: {
        color: '#B5B5C3',
        bg: '#6D6D80',
      },
    },
    tagTextColors: [
      {
        name: 'ativo',
        value: '#59DE8F',
        customized: false,
      },
      {
        name: 'inativo',
        value: '#DE5959',
        customized: false,
      },
      {
        name: 'Aguardando',
        value: '#03a9f4',
        customized: false,
      },
      {
        name: 'Divergente',
        value: '#ef5350',
        customized: false,
      },
      {
        name: 'Estoque Insuficiente',
        value: '#ef5350',
        customized: false,
      },
      {
        name: 'Em andamento',
        value: '#ff9800',
        customized: false,
      },
      {
        name: 'Andamento',
        value: '#ff9800',
        customized: false,
      },
      {
        name: 'Ressuprimento',
        value: '#ff9800',
        customized: false,
      },
      {
        name: 'Integrado Ok',
        value: '#2e9d33',
        customized: false,
      },
      {
        name: 'Integrado divergente',
        value: '#5000edbd',
        customized: false,
      },
      {
        name: 'Aguardando Conferência',
        value: '#5000edbd',
        customized: false,
      },
      {
        name: 'Aguardando Expedição',
        value: '#5000edbd',
        customized: false,
      },
      {
        name: 'Finalizado divergente',
        value: '#ffb100',
        customized: false,
      },
      {
        name: 'Finalizado c/divergência',
        value: '#ffb100',
        customized: false,
      },
      {
        name: 'Pendente',
        value: '#03a9f4',
        customized: false,
      },
      {
        name: 'Planejado',
        value: '#03a9f4',
        customized: false,
      },
      {
        name: 'Ok',
        value: '#4caf50',
        customized: false,
      },
      {
        name: 'Ativo',
        value: '#4caf50',
        customized: false,
      },
      {
        name: 'Criado',
        value: '#4caf50',
        customized: false,
      },
      {
        name: 'Finalizado',
        value: '#4caf50',
        customized: false,
      },
      {
        name: 'Expedição Finalizada',
        value: '#4caf50',
        customized: false,
      },
    ],
    tagBackgroundColors: [
      {
        name: 'ativo',
        value: '#DEF8E9',
        customized: false,
      },
      {
        name: 'inativo',
        value: '#F8DEDE',
        customized: false,
      },
      {
        name: 'Aguardando',
        value: '#03a9f440',
        customized: false,
      },
      {
        name: 'Divergente',
        value: '#ef535040',
        customized: false,
      },
      {
        name: 'Estoque Insuficiente',
        value: '#ef535040',
        customized: false,
      },
      {
        name: 'Em andamento',
        value: '#ff980040',
        customized: false,
      },
      {
        name: 'Andamento',
        value: '#ff980040',
        customized: false,
      },
      {
        name: 'Ressuprimento',
        value: '#ff980040',
        customized: false,
      },
      {
        name: 'Integrado Ok',
        value: '#329a1840',
        customized: false,
      },
      {
        name: 'Integrado divergente',
        value: '#5223c638',
        customized: false,
      },
      {
        name: 'Aguardando Conferência',
        value: '#5223c638',
        customized: false,
      },
      {
        name: 'Aguardando Expedição',
        value: '#5223c638',
        customized: false,
      },
      {
        name: 'Finalizado divergente',
        value: '#ffb10040',
        customized: false,
      },
      {
        name: 'Finalizado c/divergência',
        value: '#ffb10040',
        customized: false,
      },
      {
        name: 'Pendente',
        value: '#03a9f440',
        customized: false,
      },
      {
        name: 'Planejado',
        value: '#03a9f440',
        customized: false,
      },
      {
        name: 'Ok',
        value: '#4caf5040',
        customized: false,
      },
      {
        name: 'Criado',
        value: '#4caf5040',
        customized: false,
      },
      {
        name: 'Finalizado',
        value: '#4caf5040',
        customized: false,
      },
      {
        name: 'Expedição Finalizada',
        value: '#4caf5040',
        customized: false,
      },
    ],
  },
};

export default dark;
