import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import thunk from 'redux-thunk';

import agentSlice from './slices/agentPrinter/agentPrint.redux';

const persistedAgent = persistReducer(
  {
    key: 'agent',
    storage,
    version: 1,
    blacklist: ['client'],
  },
  agentSlice
);

const rootReducer = combineReducers({
  agent: persistedAgent,
});

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    version: 1,
  },
  rootReducer
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
