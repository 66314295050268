import React, { createContext, useCallback, useContext, useState } from 'react';

export interface INotification {
  id?: number;
  message: string;
  type: 'success' | 'warning' | 'error';
}

export interface INotifications {
  notifications: INotification[];
  pushNotification(notification: INotification): void;
  deleteNotification(index: number): void;
}

const NotificationContext = createContext<INotifications>({} as INotifications);

export const NotificationProvider: React.FC = ({ children }) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);

  const pushNotification = useCallback(
    (notfication: INotification) => {
      if (notifications.length >= 3) {
        const state = notifications.reverse().slice(1);

        setNotifications(() => [
          { ...notfication, id: Date.now() },
          ...state.reverse(),
        ]);

        return;
      }
      setNotifications(prevState => [
        { ...notfication, id: Date.now() },
        ...prevState,
      ]);
    },
    [notifications]
  );

  const deleteNotification = useCallback((index: number) => {
    setNotifications(prevState =>
      prevState.filter(n => Number(n.id) !== index)
    );
  }, []);

  return (
    <NotificationContext.Provider
      value={{ notifications, pushNotification, deleteNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export function useNotification(): INotifications {
  const context = useContext(NotificationContext);

  if (!context)
    throw new Error(
      'useNotification deve ser usadi dentro do NotificationProvider'
    );

  return context;
}
