import React, { SVGAttributes } from 'react';
import { Svg } from './styles';

interface SvgProps extends SVGAttributes<HTMLOrSVGElement> {
  as?: string;
}

const IconGrid: React.FC<SvgProps> = ({ as, ...rest }) => {
  return (
    <Svg {...rest} id={as} key={as}>
      <rect width="5" height="4" rx="0.5" fill="#FFFFFF" />
      <rect x="7" width="5" height="4" rx="0.5" fill="#FFFFFF" />
      <rect x="14" width="5" height="4" rx="0.5" fill="#FFFFFF" />
      <rect x="14" y="6" width="5" height="4" rx="0.5" fill="#FFFFFF" />
      <rect x="7" y="6" width="5" height="4" rx="0.5" fill="#FFFFFF" />
      <rect y="6" width="5" height="4" rx="0.5" fill="#FFFFFF" />
      <rect y="12" width="5" height="4" rx="0.5" fill="#FFFFFF" />
      <rect x="7" y="12" width="5" height="4" rx="0.5" fill="#FFFFFF" />
      <rect x="14" y="12" width="5" height="4" rx="0.5" fill="#FFFFFF" />
    </Svg>
  );
};

export default IconGrid;
