/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import {
  Box,
  ClickAwayListener,
  Popper,
  Fade,
  Button,
} from '@material-ui/core';

import { INotification, useNotification } from 'hooks/Notification';
import { BoxOptions, NotificationInnerButtonContainer } from '../styles';

interface INotificationMenuProps {
  anchor: HTMLButtonElement | null;
  isOpen: boolean;
  handleClose(): void;
  notifications: INotification[];
}

const NotificationMenu: React.FC<INotificationMenuProps> = ({
  anchor,
  isOpen,
  handleClose,
  notifications,
}) => {
  const { deleteNotification } = useNotification();

  return (
    <>
      {/* @ts-ignore */}
      <Box className="popper-box">
        <Popper
          open={isOpen}
          anchorEl={anchor}
          className="popper"
          style={{ zIndex: 999999 }}
          placement="bottom-end"
        >
          <ClickAwayListener onClickAway={handleClose}>
            <Fade in timeout={1000}>
              <BoxOptions>
                {notifications.map((n, i) => (
                  <NotificationInnerButtonContainer type={n.type} key={i}>
                    <Button
                      className="logoutBtn"
                      onClick={() => deleteNotification(Number(n.id))}
                    >
                      <p>{n.message}</p>
                    </Button>
                  </NotificationInnerButtonContainer>
                ))}
              </BoxOptions>
            </Fade>
          </ClickAwayListener>
        </Popper>
      </Box>
    </>
  );
};

export default NotificationMenu;
