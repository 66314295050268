import React from 'react';

const Dashboard: React.FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="3.66669"
        y="3.66602"
        width="5.5"
        height="5.5"
        rx="1"
        fill="#DDDDDD"
        stroke="#DDDDDD"
        strokeLinejoin="round"
      />
      <rect
        x="3.66669"
        y="12.166"
        width="5.5"
        height="5.5"
        rx="1"
        fill="#DDDDDD"
        stroke="#DDDDDD"
        strokeLinejoin="round"
      />
      <rect
        x="12.1667"
        y="12.166"
        width="5.5"
        height="5.5"
        rx="1"
        fill="#DDDDDD"
        stroke="#DDDDDD"
        strokeLinejoin="round"
      />
      <rect
        x="12.1667"
        y="3.66602"
        width="5.5"
        height="5.5"
        rx="1"
        fill="#DDDDDD"
        stroke="#DDDDDD"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Dashboard;
