/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, createContext, useContext } from 'react';
import { io, Socket } from 'socket.io-client';
import https from 'https';

import { baseURL } from 'services/api';
import { getCookie } from 'utils/cookies';

interface SocketContextProps {
  adapter: Socket | undefined;
  connect: () => Socket;
}

export const SocketContext = createContext({} as SocketContextProps);

export const SocketContextProvider: React.FC = ({ children }) => {
  const [adapter, setAdapter] = useState<Socket>();

  const connect = () => {
    if (adapter) return adapter;
    const user = getCookie('@pdamodules::user');
    const client = io(`${baseURL}gateways`, {
      reconnection: true,
      extraHeaders: {
        Identifier: `Web::${user.data.codigoUsuario}`,
      },

      // @ts-ignore
      agent: new https.Agent({
        rejectUnauthorized: false,
      }),
      upgrade: true,
    });
    setAdapter(client);
    return client;
  };

  return (
    // @ts-ignore
    <SocketContext.Provider
      value={{
        adapter,
        connect,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export function useSocket(): SocketContextProps {
  const context = useContext(SocketContext);

  if (!context)
    throw new Error('useSocket deve ser usado dentro do SocketProvider');

  return context;
}
