/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef, useEffect, useState, useCallback } from 'react';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
  ValueType,
} from 'react-select';
import { useField } from '@unform/core';
import { useTheme } from 'styled-components';

import Icon from 'components/Tools/Icon';
import selectArrowIcon from 'assets/svg/selectArrowIcon.svg';
import { Container } from './styles';

interface inputErrorProps {
  inputName: string;
  message: string;
}

interface Props extends SelectProps<OptionTypeBase> {
  label?: string;
  name: string;
  multiSelect?: boolean | undefined;
  hasChanged?(value: number | null): void;
  handleInputError?(data: inputErrorProps): void;
  messageErrorOnBlur?: string;
  onChangeEvent?(value: number | null): void;
  isApiSearch?: boolean;
  handleInputChange?: (newValue: string, actionMeta: string) => void;
}

const Select: React.FC<Props> = ({
  name,
  handleInputError,
  onChangeEvent,
  messageErrorOnBlur,
  multiSelect = false,
  isApiSearch = false,
  label,
  handleInputChange,
  ...rest
}) => {
  const { title } = useTheme();

  const themedDarkColor = title === 'dark' ? '#4C4C66' : '#B5B5C3';

  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error, clearError } =
    useField(name);

  const SelectIcon = () =>
    !isApiSearch ? (
      <Icon
        icon={selectArrowIcon}
        color={isFocused ? '#0095E8' : themedDarkColor}
      />
    ) : null;

  const handleBlur = useCallback(
    event => {
      setIsFocused(false);
      if (!event.target.value) {
        if (!!handleInputError && !!messageErrorOnBlur) {
          handleInputError({
            inputName: name,
            message: messageErrorOnBlur,
          });
        }
      }
      setIsMenuOpen(false);
    },
    [handleInputError, messageErrorOnBlur, name]
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: undefined,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value);
        }
        if (!ref.state.value) {
          return '';
        }
        return ref.state.value.value;
      },
      setValue: (ref, value) => {
        ref.select.setValue(value);
        clearError();
      },
    });
  }, [fieldName, registerField, rest.isMulti, clearError, handleBlur]);

  return (
    <Container error={!!error} hasLabel={!!label}>
      <label className="label" htmlFor={`${name}Id`}>
        {label}
      </label>
      <ReactSelect
        // @ts-ignore
        isMulti={multiSelect}
        defaultValue={defaultValue}
        ref={selectRef}
        blurInputOnSelect
        classNamePrefix="sel"
        onBlur={handleBlur}
        id={`${name}Id`}
        isClearable
        components={{
          DropdownIndicator: SelectIcon,
        }}
        onInputChange={(query, { action }) => {
          if (isApiSearch) {
            if (action === 'input-change' && query !== '') {
              setIsMenuOpen(true);
            } else {
              setIsMenuOpen(false);
            }
          }
          if (handleInputChange) {
            handleInputChange(query, action);
          }
        }}
        onFocus={() => {
          clearError();
          setIsFocused(true);
          if (!isApiSearch) {
            setIsMenuOpen(true);
          }
        }}
        onChange={(event: ValueType<OptionTypeBase, false>) => {
          if (onChangeEvent) {
            clearError();
            // @ts-ignore
            onChangeEvent(event?.value || event);
          }
          setIsFocused(false);
          setIsMenuOpen(false);
        }}
        menuIsOpen={isMenuOpen}
        {...rest}
      />
    </Container>
  );
};

export default Select;
