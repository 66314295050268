import styled from 'styled-components';

export const Container = styled.footer`
  width: 100%;
  height: 4em;

  padding: 1rem;

  background: ${props => props.theme.colors.background};

  display: flex;
  align-items: center;

  .content {
    max-width: 1260px;
    width: 100%;

    margin: 0 auto;

    display: flex;
    p {
      color: ${props => props.theme.colors.description};
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
    }
  }

  @media screen and (max-width: 998px) {
    .content {
      justify-content: center;
    }
  }
`;
