import React from 'react';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import ProgressBar from 'components/Tools/ProgressBar';

import { formatDate } from 'utils/formatDate';

import { useMobile } from 'hooks/Mobile';
import { useInventorySelected } from 'hooks/InventoryContext';

import breadCrumbIcon from 'assets/svg/breadCrumbIcon.svg';
import { Container, InventoryInfoContainer } from './styles';
import Icon from '../Icon';
import TagStatus from '../TagStatus';

import DetailInventory from './IntagrationModal';

interface FixedHeaderProps {
  title: string;
  subTitle?: string;
}

const FixedHeader: React.FC<FixedHeaderProps> = ({ title, subTitle }) => {
  const location = useLocation();
  const theme = useTheme();
  const { currentWidth } = useMobile();
  const { isInventoryActive } = useInventorySelected();

  return (
    <Container>
      <Breadcrumbs
        separator={
          <NavigateNextIcon className="colorDescription" fontSize="small" />
        }
        aria-label="breadcrumb"
      >
        {(!isInventoryActive?.codigoInventario || currentWidth <= 1334) && (
          <Link
            className="colorDescription mainTitle"
            href={`${
              location.pathname.split('/')[1] === 'wms' ? '/wms/home/' : null
            }`}
          >
            {location.pathname.split('/')[1] === 'wms' ? 'WMS' : null}
            <Icon
              icon={breadCrumbIcon}
              color={theme.title === 'light' ? '#959CB6' : '#EEF0F8'}
            />
          </Link>
        )}
        {(!isInventoryActive?.codigoInventario || currentWidth <= 1334) && (
          <Typography
            style={{ lineHeight: 'none' }}
            className="colorDescription subtitle"
          >
            {title}
          </Typography>
        )}
        {(!isInventoryActive?.codigoInventario || currentWidth <= 1334) &&
          !!subTitle && (
            <Typography
              style={{ lineHeight: 'none' }}
              className="colorDescription subtitle"
            >
              {subTitle}
            </Typography>
          )}

        {isInventoryActive?.codigoInventario && currentWidth > 1334 && (
          <InventoryInfoContainer>
            <p className="detailInventory">
              {isInventoryActive.enderecosDuplicados &&
                isInventoryActive.enderecosDuplicados > 0 && (
                  <>
                    <span className="red-text">ENDEREÇOS DUPLICADOS:</span>
                    <span className="red-text">
                      {isInventoryActive.enderecosDuplicados || 0}
                    </span>
                  </>
                )}
              {isInventoryActive.ajustado && (
                <>
                  <span className="green-text">Estoque WMS Atualizado</span>
                </>
              )}
              <span className="blue-text">Id:</span>{' '}
              {isInventoryActive.codigoInventario}
              <span className="blue-text">Autorização:</span>{' '}
              {isInventoryActive.codigoAutorizacao}
              <span>Tipo:</span> {isInventoryActive.tipo}
              <span className="blue-text">Filial:</span>{' '}
              {isInventoryActive.filial}
              <span className="blue-text">Status:</span>{' '}
              <TagStatus
                status={isInventoryActive.status}
                width="fit-content"
              />
              <span>Data:</span> {formatDate(isInventoryActive.dataInventario)}
              <span className="progreess blue-text">Progresso:</span>
              <div style={{ width: '100% !important', marginRight: '80px' }}>
                <ProgressBar
                  style={{
                    width: '300%',
                    display: 'inline-block',
                  }}
                  value={isInventoryActive.progresso || 0}
                />
              </div>
            </p>
          </InventoryInfoContainer>
        )}
        {isInventoryActive && currentWidth <= 1334 && (
          <DetailInventory isInventoryActive={isInventoryActive} />
        )}
      </Breadcrumbs>
    </Container>
  );
};

export default FixedHeader;
