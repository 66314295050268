import React, { useCallback, useEffect, useState } from 'react';
import { Divider } from '@material-ui/core';
import TagStatus from 'components/Tools/TagStatus';
import Loader from 'components/Tools/Loader';
import { Container } from './styles';
import { ChangeLogAccordion } from './components/ChangeLogAccordion';
import { getLogs } from './api/changelog.api';
import { version } from '../../../package.json';

type Descriptions = {
  description: string;
  id: number;
};

export type ChangesProps = {
  type: 'FIX' | 'UPDATE' | 'NEW' | 'OTHER';
  descriptions: Descriptions[];
};

export interface LogDataProps {
  version: string;
  released_at: string;
  changes: ChangesProps[];
}

export const ChangeLog = () => {
  const [logData, setLogData] = useState<LogDataProps[]>();
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  const [initialLogVersion, setInitialLogVersion] = useState<String>('');
  const [expandLog, setExpandLog] = useState(initialLogVersion);

  const getChangeLogData = useCallback(async () => {
    setIsLoading(true);
    try {
      await getLogs()
        .then(({ data }) => {
          const slicedData = data.slice(0, 12);
          setLogData(slicedData);
          if (slicedData.length > 0) {
            setInitialLogVersion(slicedData[0].version);
            setExpandLog(slicedData[0].version);
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getChangeLogData();
  }, [getChangeLogData]);

  return (
    <Container>
      <div className="changeLogWrapper">
        <div className="changeLogHeader">
          <p>Changelog</p>
          <TagStatus
            status={`VER ${version}`}
            color="#fff"
            background="#46BE7D"
            customPadding
            paddingX={1}
            width="50px"
            height="17px"
            fontSize={10}
          />
        </div>
        <Divider />
        {isLoading && (
          <div className="changeLogContent">
            <Loader />
          </div>
        )}
        {!isLoading && (
          <div className="changeLogContent">
            {logData?.map((log: LogDataProps) => {
              return (
                <ChangeLogAccordion
                  logData={log}
                  expanded={expandLog === log.version}
                  handleExpand={() => setExpandLog(log.version)}
                />
              );
            })}
          </div>
        )}
      </div>
    </Container>
  );
};
