/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Routes from './routes';
import GlobalStyles from './styles/global';
import Hooks from './hooks';

const App: React.FC = () => (
  <Hooks>
    <GlobalStyles />
    <Routes />
    {/* @ts-ignore */}
    <ToastContainer />
  </Hooks>
);

export default App;
