/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, {
  useEffect,
  useState,
  useRef,
  InputHTMLAttributes,
  useCallback,
} from 'react';
import { useField } from '@unform/core';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  action?: () => any;
  onChangeAction?: (event?: any) => any;
  defaultValueChecked?: boolean;
  label?: string;
  isRow?: boolean;
}

const SwitchButton: React.FC<InputProps> = ({
  name,
  action = () => {},
  onChangeAction = (e?: any) => {},
  defaultValueChecked,
  label,
  isRow,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField } = useField(name);
  const [checked, setChecked] = useState(false);

  const onClickAction = () => {
    action();
    onChangeAction(!checked);
    setChecked(prevState => !prevState);
  };

  const onChangeValue = (e: any) => {
    action();
    setChecked(prevState => !prevState);
    onChangeAction(checked);
  };

  const handleDefaultValue = useCallback(() => {
    setChecked(!!defaultValueChecked);
  }, [defaultValueChecked]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'checked',
      setValue: (ref, value) => {
        if (value) {
          setChecked(!!value);
        }
      },
    });
    handleDefaultValue();
  }, [fieldName, handleDefaultValue, registerField]);

  return (
    <Container
      onClick={onClickAction}
      checked={checked}
      title={checked ? 'Marcado' : 'Desmarcado'}
      isRow={isRow}
    >
      {label ? <div className="label">{label}</div> : null}
      <div className="switchButtonContainer">
        <div className="circle" />
        <div className="switch-content">
          {/* @ts-ignore */}
          <input
            type="checkbox"
            checked={checked}
            onChange={onChangeValue}
            name={name}
            ref={inputRef}
            defaultValue={defaultValue}
            {...rest}
          />
        </div>
      </div>
    </Container>
  );
};

export default SwitchButton;
